import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Router, Route, Switch } from "react-router-dom";
import routes from "./components/route/routes";
import { appHistory } from "./app-history";
import { Home } from "./components/home/home";
import { QuizContainer } from "./components/quiz/quiz-container";
import { QuizResultContainer } from "./components/quiz-result/quiz-result-container";
import { TermsAndCondition } from "./components/terms-and-condition/terms-and-condition";
import { QuizResultPdf } from "./components/quiz-result-pdf/quiz-result-pdf";
import { ErrorPage} from "./components/common/error/error";
import { getLocationFromIP } from "./helper/location-provider";
import { setClientInfo } from "./redux/actions";
import { connect } from "react-redux";

const history = appHistory();

interface RoutingProps extends WrappedComponentProps {
  setClientInfo(clientInfo: any): void;
}

class Routing extends React.Component<RoutingProps> {

  async componentDidMount() {
    await getLocationFromIP().then((resp: any) => {
      if (resp) {
        this.props.setClientInfo({
          ip: resp.ip,
          isp: resp.isp,
          country: resp.country,
          latitude: resp.latitude,
          longitude: resp.longitude
        });
      }
    });
  }

  public render() {
    return (
      <Router history={history}>
        <Switch>          
          <Route exact path={routes.quiz} component={QuizContainer} />
          <Route exact path={routes.quiz+'/:quizId'} component={QuizContainer} />
          <Route exact path={routes.result + '/:quizId'} component={QuizResultContainer} />
          <Route exact path={routes.resultPDF} component={QuizResultPdf} />
          <Route exact path={routes.termsAndConditions+'/:countryCode'} component={TermsAndCondition} />
          <Route exact path={routes.home} component={Home} />          
          <Route component={ErrorPage}/>
        </Switch>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setClientInfo : (clientInfo: any) => dispatch(setClientInfo(clientInfo))
  };
};

export default injectIntl(connect(null, mapDispatchToProps)(Routing));
