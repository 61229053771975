export const ActionType =  {
    GET_SPACE_QUIZ_QUESTIONS : 'GET_SPACE_QUIZ_QUESTIONS',
    GET_SPACE_QUIZ_QUESTIONS_SUCCESS : 'GET_SPACE_QUIZ_QUESTIONS_SUCCESS',
    GET_SPACE_QUIZ_QUESTIONS_FAILURE : 'GET_SPACE_QUIZ_QUESTIONS_FAILURE',

    GET_INDUSTRIAL_QUESTIONS : 'GET_INDUSTRIAL_QUESTIONS',
    GET_INDUSTRIAL_QUESTIONS_SUCCESS : 'GET_INDUSTRIAL_QUESTIONS_SUCCESS',
    GET_INDUSTRIAL_QUESTIONS_FAILURE : 'GET_INDUSTRIAL_QUESTIONS_FAILURE',

    GET_LOCATION_QUESTIONS : 'GET_LOCATION_QUESTIONS',
    GET_LOCATION_QUESTIONS_SUCCESS : 'GET_LOCATION_QUESTIONS_SUCCESS',
    GET_LOCATION_QUESTIONS_FAILURE : 'GET_LOCATION_QUESTIONS_FAILURE',

    GET_DESIGN_QUIZ_QUESTIONS : 'GET_DESIGN_QUIZ_QUESTIONS',
    GET_DESIGN_QUIZ_QUESTIONS_SUCCESS : 'GET_DESIGN_QUIZ_QUESTIONS_SUCCESS',
    GET_DESIGN_QUIZ_QUESTIONS_FAILURE : 'GET_DESIGN_QUIZ_QUESTIONS_FAILURE',

    SAVE_SPACE_QUIZ_ANSWERS : 'SAVE_SPACE_QUIZ_ANSWERS',
    SAVE_SPACE_QUIZ_ANSWERS_SUCCESS : 'SAVE_SPACE_QUIZ_ANSWERS_SUCCESS',
    SAVE_SPACE_QUIZ_ANSWERS_FAILURE : 'SAVE_SPACE_QUIZ_ANSWERS_FAILURE',

    SAVE_QUIZ_ANSWERS : 'SAVE_QUIZ_ANSWERS',
    SAVE_QUIZ_ANSWERS_SUCCESS : 'SAVE_QUIZ_ANSWERS_SUCCESS',
    SAVE_QUIZ_ANSWERS_FAILURE : 'SAVE_QUIZ_ANSWERS_FAILURE',

    GET_QUIZ_ANSWERS : 'GET_QUIZ_ANSWERS',
    GET_QUIZ_ANSWERS_SUCCESS : 'GET_QUIZ_ANSWERS_SUCCESS',
    GET_QUIZ_ANSWERS_FAILURE : 'GET_QUIZ_ANSWERS_FAILURE',

    GET_QUIZ_RESULT_DATA : 'GET_QUIZ_RESULT_DATA',
    GET_QUIZ_RESULT_DATA_SUCCESS : 'GET_QUIZ_RESULT_DATA_SUCCESS',
    GET_QUIZ_RESULT_DATA_FAILURE : 'GET_QUIZ_RESULT_DATA_FAILURE',

    QUIZ_START : 'QUIZ_START',
    SET_COUNTRY: 'SET_COUNTRY',

    GET_PROFILE_DATA : 'GET_PROFILE_DATA',
    GET_PROFILE_DATA_SUCCESS : 'GET_PROFILE_DATA_SUCCESS',
    GET_PROFILE_DATA_FAILURE : 'GET_PROFILE_DATA_FAILURE',

    SAVE_CONTACT_DESIGNER_DATA : 'SAVE_CONTACT_DESIGNER_DATA',
    SAVE_CONTACT_DESIGNER_DATA_SUCCESS : 'SAVE_CONTACT_DESIGNER_DATA_SUCCESS',
    SAVE_CONTACT_DESIGNNER_DATA_FAILURE : 'SAVE_CONTACT_DESIGNNER_DATA_FAILURE',

    GET_COST_ESTIMATE : 'GET_COST_ESTIMATE',
    GET_COST_ESTIMATE_SUCCESS : 'GGET_COST_ESTIMATE_SUCCESS',
    GET_COST_ESTIMATE_FAILURE : 'GET_COST_ESTIMATE_FAILURE',
    
    SAVE_QUIZ_RESULT_DATA : 'SAVE_QUIZ_RESULT_DATA',
    SAVE_QUIZ_RESULT_DATA_SUCCESS : 'SAVE_QUIZ_RESULT_DATA_SUCCESS',
    SAVE_QUIZ_RESULT_DATA_FAILURE : 'SAVE_QUIZ_RESULT_DATA_FAILURE',

    SET_CLIENTINFO : 'SET_CLIENTINFO'

}