import React from 'react';
import Routing from "./routing";
import './app.css';
import ConnectedIntlProvider from './redux/connectedIntlProvider';


export const App = () => {
  return (
    <ConnectedIntlProvider>
      <Routing />
    </ConnectedIntlProvider>
  );
}