import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { AppState } from "../../store/state";
import { QuizComponent } from "./quiz";
import { getSpaceQuizQuestions, getDesignQuizQuestions, postSpaceQuizAnswers, postQuizAnswers, getQuizAnswersData, setCountry } from "../../redux/actions";

const mapStateToProps = (state: AppState) => {
  return {
    countryCode : state.quiz.countryCode,
    languageCode: state.quiz.languageCode,
    spaceQuizData : state.quiz.spaceQuizData,
    designQuizData : state.quiz.designQuizData,
    quizAnswersData : state.quiz.quizAnswersData,
    quizId : state.quiz.quizId,
    errorType : state.quiz.errorType
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSpaceQuizQuestions: (countryCode: string) => dispatch(getSpaceQuizQuestions(countryCode)),
    getDesignQuizQuestions: (countryCode: string) => dispatch(getDesignQuizQuestions(countryCode)),
    postSpaceQuizAnswers : (quizAnswers:any) => dispatch(postSpaceQuizAnswers(quizAnswers)),
    postQuizAnswers : (quizID:string, quizAnswers:any) => dispatch(postQuizAnswers(quizID, quizAnswers)),
    getQuizAnswersData: (quizID:string) => dispatch(getQuizAnswersData(quizID)),
    setCountry : (countryCode: string, languageCode: string) => dispatch(setCountry(countryCode, languageCode)),
  };
   
};

export const QuizContainer = injectIntl(connect(mapStateToProps, mapDispatchToProps)(QuizComponent));