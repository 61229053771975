import * as React from "react";
import "./modal-popup.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import { mailformat, validateText } from "../../../helper/helper";
import appConfig from "../../../helper/app-config";
import routes from "../../route/routes";

const config = appConfig();

interface user {
  fname: '';
  lname: '';
  email: ''
}

interface calculatedResult {
  designProfile: '';
  headCount: '';
  industryCode: '';
  officeLocation: '';
  role: '';
  spaceProfile: '';
  title: '',
  daysInOffice: '',
  officeDensity: ''
}

interface ModalProps extends WrappedComponentProps {
  onPopupClose(): void;
  onQuizExit(): void;
  user: user;
  quizId: '';
  calculatedResultData: calculatedResult;
  headCount: number;
  postContactDesignerData(contactDesignerData: any): void;
  cbreProfessionalEmail:string,
  leadCreation: boolean  
}

export class ModalPopUpComponent extends React.PureComponent<ModalProps> {

  state = {
    firstName: '',
    lastName: '',
    email: '',
    emailDesigner: '',
    link: config.REACT_APP_BASE_APP_URL,
    description: '',
    validEmail: false,
    validEmailCBREDesigner: false,
    errorMessageEmail: '',
    errorMessageCBREDesignerEmail: '',
    validName: '',
    errorMessageName: '',
    validLastName: '',
    errorMessageLastName: '',
    cbreProfessionalEmail:'',
  }

  node = React.createRef<HTMLDivElement>();
  public constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }
  
  handleClick = (evnt: any) => {
    if(this.node.current?.contains(evnt.target)){
      return;
    }
    this.onPopupClose();
  }

  componentDidMount() {
    this.setState({
      firstName: this.props.user.fname,
      lastName: this.props.user.lname,
      email: this.props.user.email,
      emailDesigner : this.props.cbreProfessionalEmail!==null? this.props.cbreProfessionalEmail.split('@')[0]:this.props.intl.formatMessage(messages["global.supportEmail3"])
    });
  }

  onPopupClose = () => {
    if (this.props.onPopupClose !== null && this.props.onPopupClose !== undefined) {
      this.props.onPopupClose();
    }
  }

  onSend = () => {
    this.validate()

    if (this.validate()) {
      const contactDesignerPayload = {
        resultId: this.props.quizId,
        user: {
          email: this.state.email,
          fname: this.state.firstName,
          lname: this.state.lastName
        },
        leadCreation: this.props.leadCreation !== undefined ? this.props.leadCreation : false,
        cbreProfessionalEmail: this.props.cbreProfessionalEmail !== null && this.props.cbreProfessionalEmail !== undefined ? this.props.cbreProfessionalEmail : 'NA',     
        reviewerEmail:this.state.emailDesigner+'@cbre.com',
        programData: {
          spaceProfile: this.props.calculatedResultData.spaceProfile,
          designProfile: this.props.calculatedResultData.designProfile,
          role: this.props.calculatedResultData.role,
          title: this.props.calculatedResultData.title,
          industryCode: this.props.calculatedResultData.industryCode,
          officeLocation: this.props.calculatedResultData.officeLocation,
          headCount: this.props.headCount,
          daysInOffice: this.props.calculatedResultData.daysInOffice,
          officeDensity: this.props.calculatedResultData.officeDensity
        },
        reviewNotes: this.state.description
      };

      this.props.postContactDesignerData(contactDesignerPayload);
      if (this.props.onPopupClose !== null && this.props.onPopupClose !== undefined) {
        this.props.onPopupClose();
      }
    }
  }

  setFirstName = (event: any) => {
    this.setState({ firstName: event?.target.value });
  }

  setLastName = (event: any) => {
    this.setState({ lastName: event?.target.value });
  }

  setEmail = (event: any) => {
    this.setState({ email: event?.target.value });
    this.validate()
  }

  setEmailDesigner = (event: any) => {
    this.setState({ emailDesigner: event?.target.value });
    this.validate()
  }

  setDescription = (event: any) => {
    this.setState({ description: event?.target.value });
  }

  validate = () => {
    let isValid = true;
    let CBREDesignerEmail=this.state.emailDesigner+'@cbre.com';
    if (this.state.firstName == '') {
      isValid = false;
    }
    if (this.state.lastName == '') {
      isValid = false;
    }
    if (this.state.email.match(mailformat)) {
      this.setState({ validEmail: true, errorMessageEmail: '' });
    } else {
      this.setState({ validEmail: false, errorMessageEmail: this.props.intl.formatMessage(messages["global.validEmailAddressErrorMessage"])});
      isValid = false;
    }
    if (CBREDesignerEmail.match(mailformat)) {
      this.setState({ validEmailCBREDesigner: true, errorMessageCBREDesignerEmail: '' });
    } else {
      this.setState({ validEmailCBREDesigner: false, errorMessageCBREDesignerEmail: this.props.intl.formatMessage(messages["global.validCBREEmailAddressErrorMessage"])});
      isValid = false;
    }
    if (this.state.description == '') {
      isValid = false
    }
    return isValid;
  }

  public render() {
    this.validate()
    return (
      <div className="modalpopup">
        <div className="modal-content" ref={this.node}>
          <div className="close" onClick={this.onPopupClose} data-test="close_icon">&times;</div>
          <div className="popup-heading">
            {this.props.intl.formatMessage(messages["popupmodal.heading"])}
          </div>
          <div className="popup-desc spacing"> {this.props.intl.formatMessage(messages["popupmodal.subheading"])}</div>
          <div className="modal-detail-row">
            <label>{this.props.intl.formatMessage(messages["thankyoudetail.firstName"])}<span>*</span></label>
            <input className="input" type="text" name="firstName" value={this.state.firstName} onChange={this.setFirstName} data-test="fname" disabled/>
          </div>
          <div className="modal-detail-row">
            <label>{this.props.intl.formatMessage(messages["thankyoudetail.lastName"])}<span>*</span></label>
            <input className="input" type="text" name="lastName" value={this.state.lastName} onChange={this.setLastName} disabled/>
          </div>
          <div className="modal-detail-row">
            <label>{this.props.intl.formatMessage(messages["thankyoudetail.yourEmail"])}: <span>*</span></label>
            <input className={`input ${!this.state.validEmail ? 'invalid' : ''}`} type="email" name="email" value={this.state.email} onChange={this.setEmail} disabled />
            {!this.state.validEmail && <div className="errorMsg">{this.state.errorMessageEmail}</div>}

          </div>
          <div className="modal-detail-row">
            <label>{this.props.intl.formatMessage(messages["quizResult.reviewcbredesigner.linkToResult"])}: <span></span></label>
            <input className="inputLink input" type="text" value={this.state.link + routes.result + '/' + this.props.quizId} name="link" disabled />
          </div>
          <div className="modal-detail-row">
            <label>{this.props.intl.formatMessage(messages["thankyoudetail.cbreProfessionalEmail"])} <span>*</span></label>          
            <input className={`input ${!this.state.validEmailCBREDesigner ? 'invalid' : ''}`} type="email" name="emailDesigner" value={this.state.emailDesigner} onChange={this.setEmailDesigner} />
            <span className="domain">@cbre.com</span>
            {!this.state.validEmailCBREDesigner && <div className="errorMsg">{this.state.errorMessageCBREDesignerEmail}</div>}
          </div>
          <div className="modal-detail-row">
            <label> {this.props.intl.formatMessage(messages["quizResult.reviewcbredesigner.descriptiontext"])}:<span>*</span></label>
            <textarea className="textarea" name="description" onChange={this.setDescription} onKeyDown={validateText} onPaste={(e)=>{e.preventDefault()}} />
          </div>
          <div className="popup-btn-container">
            <button className={`primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded btn-quit ${this.validate() ? "" : "disabled"}`} onClick={this.onSend}>
              {this.props.intl.formatMessage(messages["popupmodal.sendbutton"])}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export const ModalpopUp = injectIntl(ModalPopUpComponent);

