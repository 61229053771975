/* istanbul ignore file */

import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from "./store/store";
import './styles/index.scss';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
