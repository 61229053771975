import * as React from "react";
import { injectIntl, WrappedComponentProps} from "react-intl";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import selected from "../../../assets/icon/selected.png";
import successBadge from "../../../assets/icon/success_badge.png";
import selectedTnc from "../../../assets/icon/selectedtnc.png";
import unselectedTnc from "../../../assets/icon/unselected_check.png";
import './confirmation.scss';
import messages from "../../../translations/messages";
import { Link } from "react-router-dom";
import routes from "../../route/routes";

interface ConfirmationComponentProps extends WrappedComponentProps {
    activateSubmit(active:boolean):void;
    activateThankYou(active:boolean, email:string, isLeadCreation: boolean):void;
    displayContinue(active:boolean):void;
    userEmail:string; 
    optionFirstSelected: boolean;
    countryCode:string;
  }

class ConfirmationComponent extends React.PureComponent<ConfirmationComponentProps> {
    state={
        previousEmail:'',
        inputEmail:'',
        optionFirst:this.props.intl.formatMessage(messages["confirmation.option1Text"]),
        optionSecond:this.props.intl.formatMessage(messages["confirmation.option2Text"]),
        firstCheck:false,
        secondCheck: false,
        radioSelected:'',
        errorMessage:'',
        valid: false,
    }

    emailField = React.createRef<HTMLInputElement>();

    public constructor(props: ConfirmationComponentProps) {
        super(props);
        this.state.previousEmail = props.userEmail;
        this.state.inputEmail = props.userEmail;
    }
    activateSubmit = () => {
        if (this.state.radioSelected == this.state.optionSecond) {
            this.props.displayContinue(false);
            if (this.state.valid) {
                this.props.activateThankYou(true, this.state.inputEmail, this.state.secondCheck);
            }
        } else {
            this.setState({ errorMessage: '', valid: false, secondCheck: false });
            this.props.activateThankYou(false, this.state.previousEmail, false);
            this.props.displayContinue(true);
        }
        this.props.activateSubmit(this.state.radioSelected == this.state.optionFirst ||
            (this.state.firstCheck && this.state.radioSelected == this.state.optionSecond && this.state.valid)
        );
    }

    handleTNCCheckbox =() =>{
        this.setState({ firstCheck : !this.state.firstCheck},()=>{
           this.activateSubmit();
        });
    }

    handleConnectionCheckbox =() =>{
        this.setState({ secondCheck : !this.state.secondCheck},()=>{
            this.activateSubmit();
         });
    }

    selectedOption = (event: any) =>{
        this.setState({ radioSelected: event?.target.value},()=>{
            this.activateSubmit();
        });    
    }

    inputFocus = (event: any) =>{
        if (this.emailField.current?.disabled) {
          this.emailField.current.disabled = false;
        }
        if (this.emailField.current !== null && this.emailField.current !== undefined) {
          this.emailField.current.focus();
        }
    }

    validateEmail = (event:any) => {
        const mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!event.target.value.match(mailformat)){
            this.setState({errorMessage:this.props.intl.formatMessage(messages["global.validEmailAddressErrorMessage"]), valid: false,inputEmail: event.target.value},()=>{
                this.activateSubmit();
            });            
        }else {
            this.setState({errorMessage:'',valid:true, inputEmail: event.target.value},()=>{
               this.activateSubmit();
            });
        }
    }
    public render() {       
        if(this.state.radioSelected!==this.state.optionSecond && this.props.optionFirstSelected){
            this.setState({radioSelected:this.state.optionFirst});
            this.activateSubmit();
        }
        return (
            <>
                <div className="title-confirmation">
                    <img className="img-check-confrimation" src={successBadge} />
                    <span className="my-heading">{this.props.intl.formatMessage(messages["confirmation.heading"])}</span>
                </div>
                <div className="description">{this.props.intl.formatMessage(messages["confirmation.descriptionLine1"])}</div>
                <div className="description desc-margin">{this.props.intl.formatMessage(messages["confirmation.descriptionLine2"])}</div>

                <div className="ques-sec">
                    <div className={this.state.radioSelected ? 'sentence-act': 'adjust'}>
                        {this.props.intl.formatMessage(messages["confirmation.iWantTo"])} 
                    </div>
                    <div className="option-container">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="quiz" name="quiz" value={this.state.radioSelected || ''} 
                                onChange={(event) => this.selectedOption(event)}>
                                <FormControlLabel value={this.state.optionFirst} 
                                    control={this.state.optionFirst == this.state.radioSelected ?<img style={{width:'18px'}} className="selected-img" src={selected} alt="" /> : <Radio />} label={this.state.optionFirst}
                                    className={this.state.optionFirst == this.state.radioSelected ? "label-confirmation-active" : "label-confirmation-inactive"}
                                />
                                <FormControlLabel value={this.state.optionSecond} 
                                    control={this.state.optionSecond == this.state.radioSelected ?<img style={{width:'18px'}} alt="" className="selected-img" src={selected} /> : <Radio />} label={this.state.optionSecond}
                                    className={this.state.optionSecond == this.state.radioSelected ? "label-confirmation-active" : "label-confirmation-inactive"}
                                    onClick={(event) => this.inputFocus(event)}
                                />
                            </RadioGroup>
                        </FormControl>
                        <div className="share-data">
                            <div className={this.state.optionSecond == this.state.radioSelected ? "share-desc" : "share"}>
                                {this.props.intl.formatMessage(messages["confirmation.shareURLToEmail"])}</div>
                            <div className ="confirm">
                                <div className="input-sec">
                                    <div className="input-section"><input ref={this.emailField} className={this.state.optionSecond == this.state.radioSelected && this.state.errorMessage != '' ? "email-box":"email-box-active"} type="email" placeholder={this.props.intl.formatMessage(messages["global.enterEmailMessage"])} 
                                     onFocus={(event) => this.validateEmail(event)}  onChange={(event) => this.validateEmail(event)} disabled={this.state.optionSecond != this.state.radioSelected} 
                                      value={this.state.inputEmail}/>
                                        <div className={this.state.optionSecond == this.state.radioSelected ? "line-dot-active" : "line-dot"}>.</div>
                                    </div>
                                    { this.state.optionSecond == this.state.radioSelected &&
                                         <div className="warn">{this.state.errorMessage}</div>
                                    }
                                </div>
                            </div>
                        </div>
                        
                       { this.state.radioSelected == this.state.optionSecond && (<>
                       <div className="main-tnc tnc-margin">
                            <div className="tnc-icon"><img onClick={this.handleTNCCheckbox} src={this.state.firstCheck ? selectedTnc : unselectedTnc} className="icons" /></div>
                            <div className="tnc-container">
                                <div className="tnc-first">
                                    {this.props.intl.formatMessage(messages["confirmation.iAcceptThe"])}
                                    <Link to={routes.termsAndConditions + '/' + this.props.countryCode} className="link-item link hyper-links" target="_blank" rel="noopener noreferrer">
                                        {this.props.intl.formatMessage(messages["global.termsAndCondition"])}
                                    </Link>
                                </div>
                                <div className="tnc-sec">
                                    {this.props.intl.formatMessage(messages["confirmation.forMoreInformation"])}
                                    <a  href={this.props.intl.formatMessage(messages["footer.privacyPolicy"])} target="_blank" rel="noopener noreferrer" className="hyper-links">
                                        {this.props.intl.formatMessage(messages["confirmation.cbrePrivacyPolicy"])}
                                    </a>
                                </div>
                                <div className="tnc-third">
                                    {this.props.intl.formatMessage(messages["confirmation.noSpamPromise"])}
                                </div>
                            </div>
                        </div>
                        <div className="main-tnc">
                            <div className="tnc-icon"><img onClick={this.handleConnectionCheckbox} src={this.state.secondCheck ? selectedTnc : unselectedTnc} className="icons" /></div>
                            <div className="tnc-container">
                                <div className="tnc-forth">{this.props.intl.formatMessage(messages["confirmation.connectPersonalAdvisor"])}</div>
                            </div>
                        </div>
                        </>
                       )}
                    </div>
                </div>
            </>
        )
    }

}

export const Confirmation = injectIntl(ConfirmationComponent);

