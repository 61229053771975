import * as React from "react";
import { Link } from "react-router-dom";
import routes from "../route/routes";
import './terms-and-condition.scss';
import { Footer } from "../common/footer/footer";
import messages from "../../translations/messages";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { connect } from "react-redux";
import { setCountry } from "../../redux/actions";
import { countries } from "../../models/enums";

interface TermsAndConditionComponentProps extends WrappedComponentProps {
  setCountry(countryCode: string, languageCode: string): void;
  match: any;
}
export class TermsAndConditionComponent extends React.Component<TermsAndConditionComponentProps> {
  
  componentDidMount() {
    const countryCode = this.props.match.params.countryCode;
    if(countryCode !== undefined && countryCode !== '') {
      const _country = countries.find((item) => { return item.countryCode === countryCode });
      if(_country !== undefined) {
        const _lang = _country.languages.find((language : any) => { return language.default });
        this.props.setCountry(_country.countryCode, _lang.languageCode);
      }
    }
  }

  public render() {
    const countryCode = this.props.match.params.countryCode;
    return (
      <>
        <div className="header">
          <div className="header-logo">
            <Link className="cbre_home_logo" to={routes.home}>
            <strong>{this.props.intl.formatMessage(messages["global.CBRE"])}</strong> {this.props.intl.formatMessage(messages["global.officeReady"])}
            </Link>
          </div>
        </div>
        <div className="content-body">
          <h1 className="heading">{this.props.intl.formatMessage(messages["termsAndConditions.heading"])}</h1>
          {countryCode !== "UK" ?
            <section>
              <div className="paragraph">{this.props.intl.formatMessage(messages["termsAndConditions.para1"])}</div>
              {countryCode === "FR" &&   
                <>
                  <p className="paragraph">{this.props.intl.formatMessage(messages["termsAndConditions.para2"])}</p>
                  <p className="paragraph">{this.props.intl.formatMessage(messages["termsAndConditions.para3"])}</p>
                </>
              }
              <ol className="main-list">
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.limitationsTitle"])}
                  <ol type="a">
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.limitationslist1"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.limitationslist2"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.limitationslist3"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.limitationslist4"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.limitationslist5"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.limitationslist6"])}</li>
                  </ol>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.ownership"])}
                  <div className="paragraph">
                    {this.props.intl.formatMessage(messages["termsAndConditions.ownershipDesc"])}
                  </div>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.termination"])}
                  <ol type="a">
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.terminationList1"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.terminationList2"])}</li>
                  </ol>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.disclaimer"])}
                  <ol type="a">
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.disclaimerList1"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.disclaimerList2"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.disclaimerList3"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.disclaimerList4"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.disclaimerList5"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.disclaimerList6"])}</li>
                  </ol>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.userIndemnification"])}
                  <div className="paragraph">{this.props.intl.formatMessage(messages["termsAndConditions.userIndemnificationDesc"])} </div>
                  {countryCode === "FR" &&   
                   <ol type="a">
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.userIndemnificationList1"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.userIndemnificationList2"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.userIndemnificationList3"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.userIndemnificationList4"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.userIndemnificationList5"])}</li>
                    <li>{this.props.intl.formatMessage(messages["termsAndConditions.userIndemnificationList6"])}</li>
                  </ol>
                  }
                </li>
                {countryCode === "FR" && 
                  <li>
                    {this.props.intl.formatMessage(messages["termsAndConditions.protectionOfPersonalData"])}                 
                    <ol type="a">
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.protectionOfPersonalDataList1"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.protectionOfPersonalDataList2"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.protectionOfPersonalDataList3"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.protectionOfPersonalDataList4"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.protectionOfPersonalDataList5"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.protectionOfPersonalDataList6"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.protectionOfPersonalDataList7"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.protectionOfPersonalDataList8"])}</li>
                    </ol>                 
                  </li>
                }
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.general"])}
                  <ol type="a">
                    <li><span className="para-heading">{this.props.intl.formatMessage(messages["termsAndConditions.generalList1"])}</span> {this.props.intl.formatMessage(messages["termsAndConditions.generalList1_2"])}</li>
                    <li><span className="para-heading">{this.props.intl.formatMessage(messages["termsAndConditions.generalList2"])}</span> {this.props.intl.formatMessage(messages["termsAndConditions.generalList2_2"])}</li>
                    <li><span className="para-heading">{this.props.intl.formatMessage(messages["termsAndConditions.generalList3"])}</span> {this.props.intl.formatMessage(messages["termsAndConditions.generalList3_2"])}</li>
                    <li><span className="para-heading">{this.props.intl.formatMessage(messages["termsAndConditions.generalList4"])}</span> {this.props.intl.formatMessage(messages["termsAndConditions.generalList4_2"])}</li>
                    <li><span className="para-heading">{this.props.intl.formatMessage(messages["termsAndConditions.generalList5"])}</span> {this.props.intl.formatMessage(messages["termsAndConditions.generalList5_2"])}</li>
                    <li><span className="para-heading">{this.props.intl.formatMessage(messages["termsAndConditions.generalList6"])}</span> {this.props.intl.formatMessage(messages["termsAndConditions.generalList6_2"])}</li>
                    <li><span className="para-heading">{this.props.intl.formatMessage(messages["termsAndConditions.generalList7"])}</span> {this.props.intl.formatMessage(messages["termsAndConditions.generalList7_2"])}</li>
                    <li><span className="para-heading">{this.props.intl.formatMessage(messages["termsAndConditions.generalList8"])}</span> {this.props.intl.formatMessage(messages["termsAndConditions.generalList8_2"])}</li>
                  </ol>
                </li>
              </ol>
            </section>
            :
            <section>
              <div className="date_section">
                <p>{this.props.intl.formatMessage(messages["termsAndConditions.dateCopyright"])} <br />
                  {this.props.intl.formatMessage(messages["termsAndConditions.dateEffective"])}</p>
              </div>
              <div className="paragraph">
                <p ><strong>{this.props.intl.formatMessage(messages["termsAndConditions.readCarefully"])}</strong></p>
                <p>{this.props.intl.formatMessage(messages["termsAndConditions.readCarefullyP1"])}</p>
                <p>{this.props.intl.formatMessage(messages["termsAndConditions.readCarefullyP2"])}</p>
                <p>{this.props.intl.formatMessage(messages["termsAndConditions.readCarefullyP3"])}</p>
                <p>{this.props.intl.formatMessage(messages["termsAndConditions.readCarefullyP4"])}</p>
              </div>
              <ol className="main-list">
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.access"])}
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.accessP1"])}</p>
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.accessP2"])}</p>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.license"])}
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.licenseP"])}</p>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.intellectualProperty"])}
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.intellectualPropertyP1"])}</p>
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.intellectualPropertyP2"])}</p>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.restrictions"])}
                  <div>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP1"])}
                    <ol type="i">
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP1L1"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP1L2"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP1L3"])}</li>
                    </ol>
                  </div>
                  <div>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP2"])}
                    <ol type="a">
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP2L1"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP2L2"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP2L3"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP2L4"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.restrictionsP2L5"])}</li>
                    </ol>
                  </div>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.privacyPolicy"])}
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.privacyPolicyP"])}</p>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.disclaimer"])}
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.disclaimerList1"])}</p>
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.disclaimerList2"])}</p>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.liability"])}
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.liabilityP1"])}</p>                  
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.liabilityP2"])}</p>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.indemnity"])}
                  <div>{this.props.intl.formatMessage(messages["termsAndConditions.indemnityP"])}
                    <ol type="i">
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.indemnityPL1"])}</li>
                      <li>{this.props.intl.formatMessage(messages["termsAndConditions.indemnityPL2"])}</li>
                    </ol>
                  </div>
                </li>
                <li>
                  {this.props.intl.formatMessage(messages["termsAndConditions.general"])}
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.generalList1"])}</p>                  
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.generalList2"])}</p>                  
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.generalList3"])}</p>                  
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.generalList4"])}</p>                  
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.generalList5"])}</p>                  
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.generalList6"])}</p>                  
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.generalList7"])}</p>                  
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.generalList8"])}</p>                  
                  <p>{this.props.intl.formatMessage(messages["termsAndConditions.generalList9"])}</p>
                </li>
              </ol>
            </section>

          }
        </div>
        <Footer isDisabled={true} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCountry : (countryCode: string, languageCode: string) => dispatch(setCountry(countryCode, languageCode)),
  };

};

export const TermsAndCondition = injectIntl(connect(null, mapDispatchToProps)(TermsAndConditionComponent));
