import React from "react";
import { connect } from "react-redux";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { Header } from "../common/header/header";
import { Footer } from "../common/footer/footer";
import "./home.scss";
import messages from "../../translations/messages";
import spaceQuizImg from "../../assets/space-quiz-img.png";
import designQuizImg from "../../assets/design-quiz-img.png";
import getReadySg from "../../assets/get_ready_sg.png";
import resultVideoImg from "../../assets/result-video-img.png";
import resultVideoImgSg from "../../assets/result-video-img-sg.png";
import nextSg from "../../assets/singapore_preferences.png";
import designPallet from "../../assets/style_pallet.png";
import routes from "../route/routes";
import { getSpaceQuizQuestions, quizStart, setCountry } from "../../redux/actions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appConfig from "../../helper/app-config";
import { CountryModal } from "../common/country-modal/country-modal";
import { getLocationFromIP } from "../../helper/location-provider";
import { countries } from "../../models/enums";

const config = appConfig();

interface HomeComponentProps extends WrappedComponentProps {
  countryCode: string;
  languageCode: string;
  getSpaceQuizQuestions(countryCode: string): void;
  quizStart(): void;
  history: any;
  setCountry(countryCode: string, languageCode: string): void;
}
export class HomeComponent extends React.Component<HomeComponentProps> {
  notify = () => toast.success(this.props.intl.formatMessage(messages["home.toast.message"]));
  state = {
    selectedImageId: 0,
    showPopup: false,
    isCountrySelected: false
  }

  public constructor(props: HomeComponentProps) {
    super(props);
    document.documentElement.lang = props.languageCode;
    document.body.classList.add("lang_" + props.languageCode);
  }

  navigateToQuiz = () => {
    this.props.quizStart();
    this.props.history.push(routes.quiz);
  };

  navigateToQuizAfterSelectImg = () => {
    this.props.quizStart();
    this.props.history.push(routes.quiz);
  };
  selectCountryNotification = () => {
    const _country = localStorage.getItem("countryCode")
    if (_country !== null) {
      this.notify();
    }
  }
  selectCountry = () => {
    const _country = localStorage.getItem("countryCode")
    if (_country !== null) {
      this.setState({ isCountrySelected: true });
    }
    else {
      getLocationFromIP().then((resp: any) => {
        if (resp && resp.country_code) {
          this.setSelectedCountry(resp.country_code, '', true);
          this.setState({ isCountrySelected: true });
        }
      });
    }
  }

  componentDidMount() {
    this.selectCountry();
    this.selectCountryNotification();
    localStorage.removeItem('allowDesignPalette');   
    document.addEventListener('click', () => toast.dismiss());
    this.props.getSpaceQuizQuestions(this.props.countryCode);
  }

  componentDidUpdate(prevProps: any) {
    if(this.props.countryCode !== prevProps.countryCode){
      this.props.getSpaceQuizQuestions(this.props.countryCode);
    }
  }

  componentWillUnmount(){    
    document.removeEventListener('click', () => toast.dismiss());
  }
  setSelectedCountry = (countryCode: string, langCode: string, updateLocalStorage: boolean) => {
    const _selCountry = countries.find((item) => { return item.countryCode === countryCode });
    if (_selCountry !== undefined) {
      const lang = _selCountry.languages.find((language: any) => { return langCode === '' ? language.default : language.languageCode === langCode });
      this.setState({ countryCode: countryCode, languageCode: lang?.languageCode });
      this.updateCountryAndLanguage(countryCode, lang?.languageCode, updateLocalStorage);
    }
    else {
      this.setState({ showPopup: true });
    }
  }
  updateCountryAndLanguage = (countryCode: string, languageCode: string, updateLocalStorage: boolean) => {
    if (updateLocalStorage) {
      localStorage.setItem('countryCode', countryCode);
      localStorage.setItem('languageCode', languageCode);
      this.props.setCountry(countryCode, languageCode);
    }
    document.body.className = '';
    document.documentElement.lang = languageCode;
    document.body.classList.add("lang_" + languageCode);
    this.selectCountryNotification();
  }
  onModalClose = () => {
    this.setState({ showPopup: false });
    this.setState({ isCountrySelected: true });
    setTimeout(() => { this.selectCountryNotification() }, 2000)
  }
  selectImage = (imgId: number) => {
    this.setState({ selectedImageId: imgId });
  }

  public render() {
    return (
      <>
        {this.state.isCountrySelected &&
          <>
            <Header countryCode={this.props.countryCode} />
            <div className="home">
              <div className="top-content">
                <div className="img-container">
                  <div className="text-content">
                    <div className="div-text">
                      <div className="text">
                        {this.props.intl.formatMessage(messages["home.main.heading1"])}
                      </div>
                      <div className="text">
                        {this.props.intl.formatMessage(messages["home.main.heading2"])}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="brand-info">
                <div className="heading">{this.props.intl.formatMessage(messages["global.officeReady"])}</div>
                <div className="desc">{this.props.intl.formatMessage(messages["global.byCBRE"])}</div>
              </div>
              <div className="main-content">
                <div className="section bgwhite">
                  <div className="div-content">
                    <div className="div-text-center">
                      <div className="heading">
                        {this.props.intl.formatMessage(messages["home.sectionTop.heading"])}
                      </div>
                      <div className="text mtop20">
                        {this.props.intl.formatMessage(messages["home.sectionTop.desc1"])}
                      </div>
                      <div className="text mtop20">
                        {this.props.intl.formatMessage(messages["home.sectionTop.desc2"])}
                      </div>
                      <button className="primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded mtop40" onClick={this.navigateToQuiz} id="btnGetStartedTop">
                        {this.props.intl.formatMessage(messages["home.button.getStarted"])}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="section bgEmrald">
                  <div className="div-content">
                    <div className="div-img">
                      <img src={spaceQuizImg} alt="" />
                    </div>
                    <div className="div-text">
                      <div className="heading">
                        {this.props.intl.formatMessage(messages["home.section1.heading"])}
                      </div>
                      <div className="text">
                        {this.props.intl.formatMessage(messages["home.section1.desc"])}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section bgwhite">
                  <div className="div-content">
                    <div className="div-text">
                      <div className="heading">
                        {this.props.intl.formatMessage(messages["home.section2.heading"])}
                      </div>
                      <div className="text">
                        {this.props.intl.formatMessage(messages["home.section2.desc"])}
                      </div>
                    </div>
                    <div className="div-img">
                      <img src={this.props.countryCode === 'SG' ? designPallet : designQuizImg} alt="" />
                    </div>
                  </div>
                </div>
                <div className="section bgEmrald">
                  <div className="div-content">
                    <div className="div-img">
                      <img src={this.props.countryCode === 'SG' ? resultVideoImgSg : resultVideoImg} alt="" />
                    </div>
                    <div className="div-text">
                      <div className="heading">
                        {this.props.intl.formatMessage(messages["home.section3.heading"])}
                      </div>
                      <div className="text">
                        {this.props.intl.formatMessage(messages["home.section3.desc"])}
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.countryCode === 'SG' &&
                  <div className="section bgWhite">
                    <div className="div-content">
                      <div className="div-text">
                        <div className="heading">
                          {this.props.intl.formatMessage(messages["home.section4.heading"])}
                        </div>
                        <div className="text">
                          {this.props.intl.formatMessage(messages["home.section4.desc"])}
                        </div>
                      </div>
                      <div className="div-img">
                        <img src={getReadySg} alt="" />
                      </div>
                    </div>
                  </div>
                }
                {this.props.countryCode !== 'SG' ?
                  <>
                    <div className="section bgwhite">
                      <div className="div-content ">
                        <div className="div-text-center">
                          <div className="heading mtop10">
                            {this.props.intl.formatMessage(messages["home.sectionBottom.heading"])}
                          </div>
                          <div className="text mtop20">
                            {this.props.intl.formatMessage(messages["home.sectionBottom.desc"])}
                          </div>
                          <div className="text">
                            {this.props.intl.formatMessage(messages["home.sectionBottom.desc1"])}
                          </div>
                        </div>
                        <div className="div-imgs" >
                          <div onClick={() => { this.selectImage(1) }} className={this.state.selectedImageId === 1 ? 'img selected' : 'img'}>
                            <img src={config.REACT_APP_S3 + this.props.intl.formatMessage(messages["home.sectionBottom.image1"])} alt="" />
                          </div>
                          <div onClick={(e) => { this.selectImage(2) }} className={this.state.selectedImageId === 2 ? 'img selected' : 'img'}>
                            <img src={config.REACT_APP_S3 + this.props.intl.formatMessage(messages["home.sectionBottom.image2"])} alt="" />
                          </div>
                          <div onClick={(e) => { this.selectImage(3) }} className={this.state.selectedImageId === 3 ? 'img selected' : 'img'}>
                            <img src={config.REACT_APP_S3 + this.props.intl.formatMessage(messages["home.sectionBottom.image3"])} alt="" />
                          </div>
                          <div onClick={(e) => { this.selectImage(4) }} className={this.state.selectedImageId === 4 ? 'img selected' : 'img'}>
                            <img src={config.REACT_APP_S3 + this.props.intl.formatMessage(messages["home.sectionBottom.image4"])} alt="" />
                          </div>
                          <div onClick={(e) => { this.selectImage(5) }} className={this.state.selectedImageId === 5 ? 'img selected' : 'img'}>
                            <img src={config.REACT_APP_S3 + this.props.intl.formatMessage(messages["home.sectionBottom.image5"])} alt="" />
                          </div>
                          <div onClick={(e) => { this.selectImage(6) }} className={this.state.selectedImageId === 6 ? 'img selected' : 'img'}>
                            <img src={config.REACT_APP_S3 + this.props.intl.formatMessage(messages["home.sectionBottom.image6"])} alt="" />
                          </div>
                        </div>
                        <div className="text-center mtop40">
                          <button className="primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded plr40px" id="btnGetStartedBottom" onClick={this.navigateToQuizAfterSelectImg} disabled={this.state.selectedImageId === 0}>
                            {this.props.intl.formatMessage(messages["home.button.getStarted"])}
                          </button>
                        </div>                        
                      </div>
                    </div>
                  </> :''
                }
              </div>
            </div>
            <Footer />
          </>
        }
        {this.state.showPopup && <CountryModal onModalClose={this.onModalClose} countryCode={this.props.countryCode} languageCode={this.props.languageCode} />}
        <ToastContainer autoClose={5000} hideProgressBar />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSpaceQuizQuestions: (countryCode: string) => dispatch(getSpaceQuizQuestions(countryCode)),
    setCountry: (countryCode: string, languageCode: string) => dispatch(setCountry(countryCode, languageCode)),
    quizStart: () => dispatch(quizStart())
  };
};

const mapStateToProps = (state: any) => {
  return {
    countryCode: state.quiz.countryCode,
    languageCode: state.quiz.languageCode
  }
}

export const Home = injectIntl(connect(mapStateToProps, mapDispatchToProps)(HomeComponent));
