import messagesEnUs from "./locales/en-US.json";
import messagesEsEs from "./locales/es-ES.json";
import messagesEnSg from "./locales/en-SG.json";
import messagesEnUk from "./locales/en-UK.json";
import messagesFrFr from "./locales/fr-FR.json";

export interface Language{
  key: string;
  display: string;
  data: any;
}

export const languages: Array<Language> = [  
  {
    key: "en-US",
    display: "English",
    data: messagesEnUs,
  },
  {
    key: "fr-FR",
    display: "French",
    data: messagesFrFr,
  },
  {
    key: "es-ES",
    display: "Spanish",
    data: messagesEsEs,
  },
  {
    key: "en-SG",
    display: "Singapore",
    data: messagesEnSg,
  },
  {
    key: "en-UK",
    display: "UK",
    data: messagesEnUk,
  }
].sort((a, b) => a.display.localeCompare(b.display));