import * as React from "react";
import { injectIntl} from "react-intl";
import { QuestionComponentProps } from "../../../models/question-component-props";
import selected from "../../../assets/icon/spectrum_selected.png";
import unselected from "../../../assets/icon/spectrum_unselected.png";

class SinglelineComponent extends React.PureComponent<QuestionComponentProps> {

    public constructor(props: QuestionComponentProps) {
        super(props);
    }

    public render() {
        const orderedOptions = this.props.question.options?.sort((a: any, b:any) => {return a.sequence-b.sequence});
        return (
            <>
                <div className="question-title">{this.props.question.text}</div>
                    <div className="radio-options">
                        {orderedOptions?.map((option: any) => {
                            const isSelected = option?.optionId == this.props.answerSelected?.optionId;
                            return (
                                <div key={option.text} className="radio-option" onClick={() => this.props.selectOption(option.optionId, this.props.question.questionId, this.props.question.questionType)}>
                                    <span className="radio-img">
                                        <img className="img" src={ isSelected ? selected : unselected} />
                                    </span>
                                   <span className={isSelected ? "radio-text active" : "radio-text"}>{option.text}</span>
                                </div>
                            );
                        })}
                    </div>
            </>
        )
    }

}

export const Singleline = injectIntl(SinglelineComponent);

