import * as React from "react";
import { injectIntl } from "react-intl";
import { QuestionComponentProps } from "../../../models/question-component-props";
import "./design-palette.scss";
import appConfig from "../../../helper/app-config";
import { PageModal } from "../page-modal/pageModal";

const config = appConfig();

class DesignPaletteComponent extends React.PureComponent<QuestionComponentProps> {

    state = {
        selectedOptionId : '',
        isOpenModal:false,
        palette:'',
        pageName:'',
    };
    public constructor(props: QuestionComponentProps) {
        super(props);
        this.state.selectedOptionId = this.props.answerSelected?.optionId || '';
    }

    selectImage = (optionId: string) => {
        this.setState({ selectedOptionId : optionId });
        this.props.selectOption(optionId , this.props.question.questionId, this.props.question.questionType);   
    }
    componentDidMount() {
        localStorage.removeItem('allowDesignPalette');
    }
    handProfileClick =(profileName:string) =>{
        this.setState({pageName:profileName.toUpperCase()});
        this.handleModalOpen();
    }
    handleModalOpen = () => 
    {
        this.setState({isOpenModal:true});
        //this.setState({palette:'wellnessPage'});
       window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
      };
      handleModalClose = () => {
       this.setState({isOpenModal:false})
      };
    public render() {
        const orderedOptions = this.props.question.options?.sort((a: any, b:any) => {return a.sequence-b.sequence});
        return (
            <>
                <div className="image-question-title">{this.props.question.text}</div>
                { this.props.question?.sentence && 
                    <div className="image-ques-sec">
                        { `${this.props.question?.sentence}:` } 
                    </div>
                }
                <div className="palette-grid">
                    {
                        orderedOptions?.map((option: any, index: number) => {
                            return (
                               <> <div className={`palette-tile`} key={index}>
                                    <div className="palette-row">
                                        <img src={config.REACT_APP_S3 + atob(option.imgData)} alt="" className="img" />
                                    </div>
                                    <div className={`palette-hover`}>
                                        <a className="cp" onClick={()=>{this.handProfileClick(option.profiles)}}>
                                            <img src={config.REACT_APP_S3 + atob(option.additionalImgData)} alt="" className="img" />
                                        </a>
                                    </div>
                                    <button className={`primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded btn ${this.state.selectedOptionId == option.optionId ? 'active disabled' : ''}`}
                                    onClick={()=> {this.selectImage(option.optionId.toString())}}>{'Choose Palette'}</button>
                               
                                </div>                                
                               </>
                            )
                        })
                    }                    
                </div>
                {this.state.isOpenModal && <PageModal pageName={this.state.pageName} handleModalClose={this.handleModalClose} isOpenModal={this.state.isOpenModal} handleModalOpen={this.handleModalOpen} palette={this.state.palette} />}
                
            </>
        )
    }

}

export const DesignPalette = injectIntl(DesignPaletteComponent);

