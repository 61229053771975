import * as React from "react";
import "./header.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import { CountryLanguage } from "../country-language-settings/country-language-settings";

interface HeaderProps extends WrappedComponentProps {
  countryCode: string;
}

class HeaderComponent extends React.PureComponent<HeaderProps> {

  public constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div className="header"> 
        <span className="cbre_home_logo">
          <strong>{this.props.intl.formatMessage(messages["global.CBRE"])}</strong> {this.props.intl.formatMessage(messages["global.officeReady"])}
        </span>
        <div className="header-links">
          <div className="link-item link">
            <CountryLanguage />
          </div>
            <a href={this.props.intl.formatMessage(messages["footer.aboutOfficeready"])} target="_blank" rel="noopener noreferrer" className="link-item link" >
              {this.props.intl.formatMessage(messages["global.about"])}
            </a>
        </div>
      </div>
    )
  }
}

export const Header = injectIntl(HeaderComponent);

