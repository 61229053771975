import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import messages from '../../translations/messages';
import successBadge from "../../assets/icon/success_badge.png";
import selectedTnc from "../../assets/icon/selectedtnc.png";
import unselectedTnc from "../../assets/icon/unselected_check.png";
import './thank-you.scss';
import { Link } from 'react-router-dom';
import routes from "../route/routes";
import { validateText } from "../../helper/helper";

interface ThankyouComponentProps extends WrappedComponentProps {
    countryCode: string;
    designStarted: boolean;
    userEmail? : string;
    activateViewResult(active:boolean, thankyouData: any):void;   
}

class ThankYouComponent extends React.PureComponent<ThankyouComponentProps> {

    mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    state = {
        firstName: '',
        lastName: '',
        email: '',
        cbreProfEmail: '',
        tncSelected: false,
        copyCBREProfSelected: false,
        validEmail: false,
        errorMessageEmail: '',
        validCBREEmail: false,
        errorMessageCBREEmail: '',
        cbreEmail:'',
    }

    public constructor(props: ThankyouComponentProps) {
        super(props);
    }
    
    componentDidMount() {
        if(this.props.userEmail) {
            this.setState({email: this.props.userEmail}, () => {this.activateSubmit(); });
        }        
    }

    handleTNCCheckbox = () => {
        this.setState({ tncSelected: !this.state.tncSelected }, () => {this.activateSubmit(); });
    }

    handleCBREProfCheckbox = () => {
        this.setState({ copyCBREProfSelected: !this.state.copyCBREProfSelected }, () => {this.activateSubmit(); });
    }

    activateSubmit = () => { 
        if(this.validate() && this.state.tncSelected) {
            const _thankYouData = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                cbreProfEmail: this.state.cbreProfEmail!=='' ? this.state.cbreProfEmail + '@cbre.com' : '',
                copyCBREProfSelected: this.props.countryCode === 'ES' && this.state.cbreProfEmail !== '' ? true : this.state.copyCBREProfSelected
            };
            this.props.activateViewResult(true,_thankYouData);
        } else {
            this.props.activateViewResult(false, {});
        }
    }

    setFirstName = (event: any) => {
        this.setState({firstName: event?.target.value}, () => {this.activateSubmit(); });
    }
    setLastName = (event: any) => {
        this.setState({lastName: event?.target.value}, () => {this.activateSubmit(); });
    }
    setEmail = (event: any) => {
        this.setState({email: event?.target.value}, () => {this.activateSubmit(); });
    }
    setCBREEmail = (event: any) => {
        this.setState({cbreProfEmail: event?.target.value}, () => {this.activateSubmit(); });
    }
    validate = () => {
        let isValid = true;
        this.state.cbreEmail=this.state.cbreProfEmail+'@cbre.com';
        if(this.state.firstName == ''){
            isValid = false;
        } 
        if(this.state.lastName == '') {
            isValid = false;
        } 
        if(this.state.email.match(this.mailformat)) {
            this.setState({ validEmail: true,errorMessageEmail: ''});            
        } else {
            this.setState({ validEmail: false, errorMessageEmail: this.props.intl.formatMessage(messages["global.validEmailAddressErrorMessage"])});
            isValid = false; 
        }
        if(this.state.cbreProfEmail !== '') {
            if(this.state.cbreEmail.match(this.mailformat)) {
                this.setState({ validCBREEmail: true, errorMessageCBREEmail: ''});            
            } else {
                this.setState({ validCBREEmail: false, errorMessageCBREEmail: this.props.intl.formatMessage(messages["global.validCBREEmailAddressErrorMessage"])});
                isValid = false;
            }
        }
        return isValid;
    }

    public render() {
        return (
            <>
                <div className="thank-you-title">
                    <img className="img-check" src={successBadge} alt="" />
                    {this.props.designStarted && <span>{this.props.intl.formatMessage(messages["thankyou.title"])}</span>}
                    {this.props.designStarted ? <span className='description-title'>{this.props.intl.formatMessage(messages["thankyoudetail.title"])}</span> : <span>{this.props.intl.formatMessage(messages["thankyou.title"])}</span>}
                </div>
                {
                    !this.props.designStarted
                        ?
                        <>
                            <div className="thank-you-description">{this.props.intl.formatMessage(messages["thankyou.message1"])}</div>
                            <div className="thank-you-description">{this.props.intl.formatMessage(messages["thankyou.message2"])}</div>
                        </>
                        :
                        <>
                            <div className="thank-you-description">{this.props.intl.formatMessage(messages["thankyoudetail.message"])}:</div>
                            <div className="thankyou-detail thankyou-detail-row">
                                <label>{this.props.intl.formatMessage(messages["thankyoudetail.firstName"])}<span>*</span></label>
                                <input className="input" type="text" name="firstName" onChange={this.setFirstName} onKeyDown={validateText} onPaste={(e)=>{e.preventDefault()}} data-test="firstName"/>
                            </div>
                            <div className="thankyou-detail-row">
                                <label>{this.props.intl.formatMessage(messages["thankyoudetail.lastName"])}<span>*</span></label>
                                <input className="input" type="text" name="lastName" onChange={this.setLastName}  onKeyDown={validateText}  onPaste={(e)=>{e.preventDefault()}} data-test="lastName"/>
                            </div>
                            <div className="thankyou-detail-row">
                                <label>{this.props.intl.formatMessage(messages["thankyoudetail.yourEmail"])}: <span>*</span></label>
                                <input className={`input ${this.state.email != '' && !this.state.validEmail ? 'invalid' : ''}`} type="email" name="email" 
                                value={this.state.email} onChange={this.setEmail} data-test="email" disabled={this.props.userEmail !== undefined && this.props.userEmail !== ''}/>
                                { this.state.email != '' && <div className="errorMsg">{this.state.errorMessageEmail}</div> }
                            </div>
                            <div className="thankyou-detail-row">
                                <label>{this.props.intl.formatMessage(messages["thankyoudetail.cbreProfessionalEmail"])}: </label>
                                <input className={`input ${this.state.cbreProfEmail != '' && !this.state.validCBREEmail ? 'invalid' : ''}`} type="email" name="cbreProfEmail" onChange={this.setCBREEmail} data-test="CBREemail"/>
                                <span className="domain">@cbre.com</span>
                                { this.state.cbreProfEmail != '' && <div className="errorMsg">{this.state.errorMessageCBREEmail}</div> }
                            </div>
                            { this.state.cbreProfEmail != '' && this.props.countryCode !== 'ES' && 
                                <div className="main-tnc mtop20">
                                    <div className="tnc-icon"><img onClick={this.handleCBREProfCheckbox} src={this.state.copyCBREProfSelected ? selectedTnc : unselectedTnc} className="icons" /></div>
                                    <div className="tnc-container">
                                        <div className="tnc-forth">{this.props.intl.formatMessage(messages["thankyoudetail.checkCBREProfessionalEmail"])}</div>
                                    </div>
                                </div>
                            } 
                            <div className="main-tnc mtop20">
                                <div className="tnc-icon"><img onClick={this.handleTNCCheckbox} src={this.state.tncSelected ? selectedTnc : unselectedTnc} className="icons" /></div>
                                <div className="tnc-container">
                                    <div className="tnc-first">
                                        {this.props.intl.formatMessage(messages["confirmation.iAcceptThe"])}                                         
                                        <Link to={routes.termsAndConditions + '/' + this.props.countryCode} className="link-item link hyper-links" target="_blank" rel="noopener noreferrer">
                                        {this.props.intl.formatMessage(messages["global.termsAndCondition"])}
                                    </Link>
                                    </div>
                                    <div className="tnc-sec">
                                        {this.props.intl.formatMessage(messages["confirmation.forMoreInformation"])}
                                        <a href={this.props.intl.formatMessage(messages["footer.privacyPolicy"])} target="_blank" rel="noopener noreferrer" className="hyper-links">
                                            {this.props.intl.formatMessage(messages["confirmation.cbrePrivacyPolicy"])}
                                        </a>
                                    </div>
                                    <div className="tnc-third">
                                        {this.props.intl.formatMessage(messages["confirmation.noSpamPromise"])}
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </>
        );
    }
}

export const ThankYou = injectIntl(ThankYouComponent);