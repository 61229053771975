import * as React from "react";
import "./word-select.scss";
import { injectIntl } from "react-intl";
import { QuestionComponentProps } from "../../models/question-component-props";
import messages from "../../translations/messages";

class WordSelectComponent extends React.PureComponent<QuestionComponentProps> {

    state = {
        selectedOptions : ''
    };
    public constructor(props: QuestionComponentProps) {
        super(props);
        this.state.selectedOptions = this.props.answerSelected?.optionId || '';
        this.selectOption = this.selectOption.bind(this);
    }

    isActive = (optionId: any) => {
        const optionIds = this.state.selectedOptions?.split('|');
        const _result = optionIds?.filter((as: any) => { return as == optionId });
        return _result && _result.length > 0;
    }

    selectOption = (event: any) => {
        const optionId = event.currentTarget.dataset.option_id;
        const optionIds = this.state.selectedOptions?.split('|')?.filter(r => r !== '');
        const isSelected = optionIds?.find((x)=> { return x== optionId});
        let selOptions;
        if(isSelected){
            if(optionIds?.length > 1) {
                selOptions = optionIds.filter((x)=> {return x != optionId});
            }
        } else if (optionIds?.length < this.props.question?.allowedChoice) {
            selOptions  = [...optionIds ,optionId ];
        }
        
        if(selOptions) {
            this.setState({ selectedOptions : selOptions.join('|') });
            this.props.selectOption(selOptions.join('|') , this.props.question.questionId, this.props.question.questionType);            
        }
    }

    public render() {
        const orderedOptions = this.props.question.options?.sort((a: any, b:any) => {return a.sequence-b.sequence});
        return (
            <>
                <div className="word-question-title">{this.props.question.text}</div>
                <div className="word-ques-sec">{this.props.question.sentence}: <span>{ this.props.question?.allowedChoice > 1 && (this.props.intl.formatMessage(messages["global.chooseupto"]) + ' ' + this.props.question?.allowedChoice)}</span> </div>
                <div className="word-grid">
                    {
                        orderedOptions?.map((option: any, index: number) => {
                            return (
                                <div data-option_id={option?.optionId} onClick={this.selectOption} key={index}
                                    className={`word-tile ${this.isActive(option?.optionId) && 'active'}`}>
                                    { option?.text }
                                </div>                                
                            )
                        })
                    }                    
                </div>
            </>
        )
    }

}

export const WordSelect = injectIntl(WordSelectComponent);

