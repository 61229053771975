export enum QuestionType {
    SingleLine = "singleline",
    MultiLine = "multiline",
    Dropdown = "dropdown",
    Headcount = "headcount",
    DaysInOffice = "daysinoffice",
    ImageSelect = "imageselect",
    WordSelect = "wordselect",
    Autosuggest = "autosuggest",
    LocationDropdown = "locationdropdown",
    DesignPalette = "designpalette"
}