import { defineMessages } from 'react-intl';

export default defineMessages({
  'global.officeReady': {
    id: 'global.officeReady',
    defaultMessage: 'Office Ready',
  },
  'global.byCBRE': {
    id: 'global.byCBRE',
    defaultMessage: 'by CBRE',
  },
  'global.previous': {
    id: 'global.previous',
    defaultMessage: 'Previous',
  },
  'global.next': {
    id: 'global.next',
    defaultMessage: 'Next',
  },
  'global.submit': {
    id: 'global.submit',
    defaultMessage: 'Submit',
  },
  'global.continue': {
    id: 'global.continue',
    defaultMessage: 'Continue',
  },
  'global.viewResult': {
    id: 'global.viewResult',
    defaultMessage: 'View Results',
  },
  'global.questions': {
    id: 'global.questions',
    defaultMessage: 'Questions?',
  },
  'global.resources': {
    id: 'global.resources',
    defaultMessage: 'Resources',
  },
  'global.more': {
    id: 'global.more',
    defaultMessage: 'More',
  },
  'global.validEmailAddressErrorMessage': {
    id: 'global.validEmailAddressErrorMessage',
    defaultMessage: 'Please enter a valid email address.',
  },
  'global.validCBREEmailAddressErrorMessage': {
    id: 'global.validCBREEmailAddressErrorMessage',
    defaultMessage: 'Please enter a valid CBRE email address.',
  },
  'global.enterEmailMessage': {
    id: 'global.enterEmailMessage',
    defaultMessage: 'Enter an email',
  },
  'global.supportEmail1': {
    id: 'global.supportEmail1',
    defaultMessage: 'OfficeReadyDesignTeam@cbre.com',
  },
  'global.supportEmail3': {
    id: 'global.supportEmail3',
    defaultMessage: 'OfficeReadyDesignTeam',
  },
  'global.contactGeneralEnquiries': {
    id: 'global.contactGeneralEnquiries',
    defaultMessage: 'Contact for general inquiries',
  },
  'global.supportEmail2': {
    id: 'global.supportEmail2',
    defaultMessage: 'OfficeReadyITSupport@cbre.com',
  },
  'global.contactITSupport': {
    id: 'global.contactITSupport',
    defaultMessage: 'Contact for IT support',
  },
  'global.contact': {
    id: 'global.contact',
    defaultMessage: 'Contact Us',
  },
  'global.backtohome': {
    id: 'global.backtohome',
    defaultMessage: 'Head back to Office Ready Home',
  },
  'global.about': {
    id: 'global.about',
    defaultMessage: 'About',
  },
  'global.aboutOfficeReady': {
    id: 'global.aboutOfficeReady',
    defaultMessage: 'About Office Ready',
  },
  'global.insights': {
    id: 'global.insights',
    defaultMessage: 'Insights',
  },
  'global.whoIsCBREDesign': {
    id: 'global.whoIsCBREDesign',
    defaultMessage: 'Who is CBRE Design?',
  },
  'global.termsAndCondition': {
    id: 'global.termsAndCondition',
    defaultMessage: 'Terms & Conditions',
  },
  'global.privacyPolicy': {
    id: 'global.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  'global.the': {
    id: 'global.the',
    defaultMessage: 'The',
  },
  'global.disclaimer': {
    id: 'global.disclaimer',
    defaultMessage: 'Disclaimer',
  },
  'global.other': {
    id: 'global.other',
    defaultMessage: 'Other',
  },
  'global.two': {
    id: 'global.two',
    defaultMessage: 'Two',
  },
  'global.three': {
    id: 'global.three',
    defaultMessage: 'three',
  },
  'global.chooseupto': {
    id: 'global.chooseupto',
    defaultMessage: 'Choose up to',
  },
  'global.by': {
    id: 'global.by',
    defaultMessage: 'by',
  },
  'global.CBRE': {
    id: 'global.CBRE',
    defaultMessage: 'CBRE',
  },
  'global.seat': {
    id: 'global.seat',
    defaultMessage: 'seat',
  },
  'global.seats': {
    id: 'global.seats',
    defaultMessage: 'seats',
  },
  'global.room': {
    id: 'global.room',
    defaultMessage: 'room',
  },
  'global.rooms': {
    id: 'global.rooms',
    defaultMessage: 'rooms',
  },
  'global.area': {
    id: 'global.area',
    defaultMessage: 'area',
  },
  'global.singapore': {
    id: 'global.singapore',
    defaultMessage: 'singapore',
  },
  'global.notes': {
    id: 'global.notes',
    defaultMessage: 'Notes',
  },
  'global.proceed':{
    id:'global.proceed',
    defaultMessage:'Proceed'
  },
  'global.cbreofficeready':{
    id:'global.cbreofficeready',
    defaultMessage:'CBRE Office Ready'
  },
  'home.main.heading1': {
    id: 'home.main.heading1',
    defaultMessage: 'Office Fit-Outs Are Complex',
  },
  'home.main.heading2': {
    id: 'home.main.heading2',
    defaultMessage: 'We Make It Easy',
  },
  'home.button.getStarted': {
    id: 'home.button.getStarted',
    defaultMessage: 'Get Started',
  },
  'home.sectionTop.heading': {
    id: 'home.sectionTop.heading',
    defaultMessage: 'Fast, Easy, & Cost Effective',
  },
  'home.sectionTop.desc1': {
    id: 'home.sectionTop.desc1',
    defaultMessage:
      'We take the complexity out of office build-outs so you can focus on your business, not your real estate.',
  },
  'home.sectionTop.desc2': {
    id: 'home.sectionTop.desc2',
    defaultMessage: 'Plan, design and estimate your office in minutes.',
  },
  'home.section1.heading': {
    id: 'home.section1.heading',
    defaultMessage: '01 Tell us about your company',
  },
  'home.section1.desc': {
    id: 'home.section1.desc',
    defaultMessage:
      'Build your program profile based on recommendations specific to your goals, requirements, and company characteristics.',
  },
  'home.section2.heading': {
    id: 'home.section2.heading',
    defaultMessage: '02 Tell us about your style',
  },
  'home.section2.desc': {
    id: 'home.section2.desc',
    defaultMessage:
      'Take the style assessment to determine your office style and palette options. Are you classic, fresh, industrial, or organic?',
  },
  'home.section3.heading': {
    id: 'home.section3.heading',
    defaultMessage: '03 Visualize your results',
  },
  'home.section3.desc': {
    id: 'home.section3.desc',
    defaultMessage:'Meet our Singapore Design team to finalise your design and costing to deliver your Office Ready.',
  },
  'home.section4.heading': {
    id: 'home.section4.heading',
    defaultMessage: '04 Get your Office Ready!',
  },
  'home.section4.desc': {
    id: 'home.section4.desc',
    defaultMessage:
      'See your Office Ready results immediately with a 3D fly-through visualization, program, style profile, and cost estimate. Save your results for later, or contact our team to bring your office to life.',
  },
  'home.sectionBottom.heading': {
    id: 'home.sectionBottom.heading',
    defaultMessage: 'Tell us a little bit about your preferences.',
  },
  'home.sectionBottom.desc': {
    id: 'home.sectionBottom.desc',
    defaultMessage:
      'We will estimate your program, costs, and design aesthetic.',
  },
  'home.sectionBottom.desc1': {
    id: 'home.sectionBottom.desc1',
    defaultMessage: 'Select an image that you find most inspiring.',
  },
  'home.sectionBottom.image1': {
    id: 'home.sectionBottom.image1',
    defaultMessage: 'Q1/Classic1.png',
  },
  'home.sectionBottom.image2': {
    id: 'home.sectionBottom.image2',
    defaultMessage: 'Q1/Classic2.png',
  },
  'home.sectionBottom.image3': {
    id: 'home.sectionBottom.image3',
    defaultMessage: 'Q1/Fresh1.png',
  },
  'home.sectionBottom.image4': {
    id: 'home.sectionBottom.image4',
    defaultMessage: 'Q1/Fresh2.png',
  },
  'home.sectionBottom.image5': {
    id: 'home.sectionBottom.image5',
    defaultMessage: 'Q1/Industrial1.png',
  },
  'home.sectionBottom.image6': {
    id: 'home.sectionBottom.image6',
    defaultMessage: 'Q1/Industrial2.png',
  },
  'quiz.spaceHeading': {
    id: 'quiz.spaceHeading',
    defaultMessage: "Let's define your ideal office space.",
  },
  'quiz.designHeading': {
    id: 'quiz.designHeading',
    defaultMessage: 'Let’s identify your design style!',
  },
  'quiz.welcomeMsg': {
    id: 'quiz.welcomeMsg',
    defaultMessage:
      'Welcome! Please answer the following questions with your future office in mind.',
  },
  'quiz.headcount.placeholderMessage': {
    id: 'quiz.headcount.placeholderMessage',
    defaultMessage: 'Enter number value',
  },
  'modal.button.leftBtn': {
    id: 'modal.button.leftBtn',
    defaultMessage: 'Continue quiz',
  },
  'modal.button.rightBtn': {
    id: 'modal.button.rightBtn',
    defaultMessage: 'I understand, exit quiz',
  },
  'modal.heading': {
    id: 'modal.heading',
    defaultMessage: 'Exit without finding your office style?',
  },
  'modal.description.first': {
    id: 'modal.description.first',
    defaultMessage: 'Are you sure you want to exit the quiz?',
  },
  'modal.description.second': {
    id: 'modal.description.second',
    defaultMessage:
      'Incomplete quiz information is not saved. You would need to retake the quiz for results.',
  },
  'progressBar.progressText': {
    id: 'progressBar.progressText',
    defaultMessage: 'complete',
  },
  'progressBar.spaceProgram': {
    id: 'progressBar.spaceProgram',
    defaultMessage: 'Space Program',
  },
  'progressBar.designStyle': {
    id: 'progressBar.designStyle',
    defaultMessage: 'Design Style',
  },
  'progressBar.result': {
    id: 'progressBar.result',
    defaultMessage: 'Results',
  },
  'confirmation.heading': {
    id: 'confirmation.heading',
    defaultMessage: 'Your Office Ready profile is really coming together.',
  },
  'confirmation.descriptionLine1': {
    id: 'confirmation.descriptionLine1',
    defaultMessage:
      'You can proceed to the design quiz, or you can pause and come back later. ',
  },
  'confirmation.descriptionLine2': {
    id: 'confirmation.descriptionLine2',
    defaultMessage:
      'Choose your option below, and click on ‘Submit’ to confirm.',
  },
  'confirmation.option1Text': {
    id: 'confirmation.option1Text',
    defaultMessage: 'Proceed to design quiz. ',
  },
  'confirmation.option2Text': {
    id: 'confirmation.option2Text',
    defaultMessage: 'Come back later to complete the design quiz.',
  },
  'confirmation.iWantTo': {
    id: 'confirmation.iWantTo',
    defaultMessage: 'I want to:',
  },
  'confirmation.shareURLToEmail': {
    id: 'confirmation.shareURLToEmail',
    defaultMessage: 'Share the URL to continue, on my email ID',
  },
  'confirmation.iAcceptThe': {
    id: 'confirmation.iAcceptThe',
    defaultMessage: 'I accept the',
  },
  'confirmation.termsAndConditions': {
    id: 'confirmation.termsAndConditions',
    defaultMessage: 'Terms and Conditions.',
  },
  'confirmation.forMoreInformation': {
    id: 'confirmation.forMoreInformation',
    defaultMessage:
      'For more information on how your data will be used, please refer to the',
  },
  'confirmation.cbrePrivacyPolicy': {
    id: 'confirmation.cbrePrivacyPolicy',
    defaultMessage: 'CBRE Privacy Policy.',
  },
  'confirmation.noSpamPromise': {
    id: 'confirmation.noSpamPromise',
    defaultMessage:
      '(The Office Ready team might contact you to help you get the most out of your program. No email newsletters or spam, we promise!)',
  },
  'confirmation.connectPersonalAdvisor': {
    id: 'confirmation.connectPersonalAdvisor',
    defaultMessage:
      'Connect me with a personal real estate advisor. No email newsletters or spam, we promise!',
  },
  'thankyou.title': {
    id: 'thankyou.title',
    defaultMessage: 'Thank you!',
  },
  'thankyou.message1': {
    id: 'thankyou.message1',
    defaultMessage: 'We have saved your profile and emailed a link to you.',
  },
  'thankyou.message2': {
    id: 'thankyou.message2',
    defaultMessage: 'Feel free to come back and continue at your convenience.',
  },
  'thankyoudetail.title': {
    id: 'thankyoudetail.title',
    defaultMessage: 'We are searching the best options for you.',
  },
  'thankyoudetail.message': {
    id: 'thankyoudetail.message',
    defaultMessage: 'Please share your details to continue',
  },
  'thankyoudetail.firstName': {
    id: 'thankyoudetail.firstName',
    defaultMessage: 'First name',
  },
  'thankyoudetail.lastName': {
    id: 'thankyoudetail.lastName',
    defaultMessage: 'Last name',
  },
  'thankyoudetail.yourEmail': {
    id: 'thankyoudetail.yourEmail',
    defaultMessage: 'Your email',
  },
  'thankyoudetail.cbreProfessionalEmail': {
    id: 'thankyoudetail.cbreProfessionalEmail',
    defaultMessage: "Email of the CBRE Professional you're working with",
  },
  'thankyoudetail.checkCBREProfessionalEmail': {
    id: 'thankyoudetail.checkCBREProfessionalEmail',
    defaultMessage:
      'Send a copy of my results to the above mentioned CBRE professional',
  },
  'location.placeholder': {
    id: 'location.placeholder',
    defaultMessage: 'Start typing a city',
  },
  'quizResult.header.downloadPDF': {
    id: 'quizResult.header.downloadPDF',
    defaultMessage: 'Download PDF',
  },
  'quizResult.header.sendResult': {
    id: 'quizResult.header.sendResult',
    defaultMessage: 'Share',
  },
  'quizResult.header.reviewWithCBRE': {
    id: 'quizResult.header.reviewWithCBRE',
    defaultMessage: 'Review with a CBRE designer',
  },
  'quizResult.header.retakeQuiz': {
    id: 'quizResult.header.retakeQuiz',
    defaultMessage: '< Retake the quiz',
  },
  'quizResult.heading': {
    id: 'quizResult.heading',
    defaultMessage: ', the results are in!',
  },
  'quizResult.spaceProfile.heading': {
    id: 'quizResult.spaceProfile.heading',
    defaultMessage: 'Your planning style is ',
  },
  'quizResult.designProfile.videoDescription': {
    id: 'quizResult.designProfile.videoDescription',
    defaultMessage: 'The profiles, metrics and pricing below are indicative. The next step would be to engage with our CBRE Singapore Design team for an in-depth review of your needs.',
  },
  'quizResult.designProfile.heading': {
    id: 'quizResult.designProfile.heading',
    defaultMessage: 'Your design style is ',
  },
  'quizResult.actionLink.yourProgram': {
    id: 'quizResult.actionLink.yourProgram',
    defaultMessage: 'Your Program',
  },
  'quizResult.actionLink.yourProgramDetails': {
    id: 'quizResult.actionLink.yourProgramDetails',
    defaultMessage: 'Your program details',
  },
  'quizResult.actionLink.yourPalette': {
    id: 'quizResult.actionLink.yourPalette',
    defaultMessage: 'Your Palette',
  },
  'quizResult.actionLink.yourCostEstimate': {
    id: 'quizResult.actionLink.yourCostEstimate',
    defaultMessage: 'Your Cost Estimate /SF',
  },
  'quizResult.actionLink.additionalResources': {
    id: 'quizResult.actionLink.additionalResources',
    defaultMessage: 'Additional Resources',
  },
  'quizResult.actionLink.styles': {
    id: 'quizResult.actionLink.styles',
    defaultMessage: 'Styles',
  },
  'quizResult.actionLink.nextStep': {
    id: 'quizResult.actionLink.nextStep',
    defaultMessage: 'Next Step: Let’s Finalise your Office Ready',
  },
  'quizResult.nextStep.desc1': {
    id: 'quizResult.nextStep.desc1',
    defaultMessage: 'Now that we got to know you better and developed indicative results for your organisation. It is time to finalise your Office Ready!',
  },
  'quizResult.nextStep.desc2': {
    id: 'quizResult.nextStep.desc2',
    defaultMessage: 'The next step is a consultancy process which involves our CBRE Singapore Design, Project Management and Financing team, for us to further develop your space budget, design, project timelines and delivery methodology as well as financing options.',
  },
  'popupmodal.heading': {
    id: 'popupmodal.heading',
    defaultMessage: 'Review with a CBRE designer',
  },
  'popupmodal.subheading': {
    id: 'popupmodal.subheading',
    defaultMessage: 'Please share your details to continue:',
  },
  'popupmodal.sendbutton': {
    id: 'popupmodal.sendbutton',
    defaultMessage: 'Send',
  },
  'popupmodal.Copytoclipboardbutton': {
    id: 'popupmodal.Copytoclipboardbutton',
    defaultMessage: 'Copy',
  },
  'popupmodal.Copytoclipboardmessage': {
    id: 'popupmodal.Copytoclipboardmessage',
    defaultMessage: 'Copied',
  },
  'quizResult.yourProgram.individualSeats': {
    id: 'quizResult.yourProgram.individualSeats',
    defaultMessage: 'Individual Seats',
  },
  'quizResult.yourProgram.collaboration': {
    id: 'quizResult.yourProgram.collaboration',
    defaultMessage: 'Collaboration',
  },
  'quizResult.yourProgram.enclosedGroupSeats': {
    id: 'quizResult.yourProgram.enclosedGroupSeats',
    defaultMessage: 'Enclosed Group Seats',
  },
  'quizResult.yourProgram.seatsPerPerson': {
    id: 'quizResult.yourProgram.seatsPerPerson',
    defaultMessage: 'Seats per person',
  },
  'quizResult.yourProgram.persons': {
    id: 'quizResult.yourProgram.persons',
    defaultMessage: 'Persons',
  },
  'quizResult.yourProgram.density': {
    id: 'quizResult.yourProgram.density',
    defaultMessage: 'Density',
  },
  'quizResult.yourProgram.densityDescription': {
    id: 'quizResult.yourProgram.densityDescription',
    defaultMessage: 'Usable square feet per individual seat',
  },
  'quizResult.yourProgram.densityDescriptionUSF': {
    id: 'quizResult.yourProgram.densityDescriptionUSF',
    defaultMessage: 'Usable square feet per individual seat',
  },
  'quizResult.yourProgram.densityDescriptionSM': {
    id: 'quizResult.yourProgram.densityDescriptionSM',
    defaultMessage: 'Usable square meter per individual seat',
  },
  'quizResult.yourProgram.densityDescriptionSY': {
    id: 'quizResult.yourProgram.densityDescriptionSY',
    defaultMessage: 'Usable square yard per individual seat',
  },
  'quizResult.yourProgram.workstations': {
    id: 'quizResult.yourProgram.workstations',
    defaultMessage: 'Workstations',
  },
  'quizResult.yourProgram.offices': {
    id: 'quizResult.yourProgram.offices',
    defaultMessage: 'Offices',
  },
  'quizResult.yourProgram.customizeYourProgram': {
    id: 'quizResult.yourProgram.customizeYourProgram',
    defaultMessage: 'Customize your program',
  },
  'quizResult.yourProgram.customizeYPDescription': {
    id: 'quizResult.yourProgram.customizeYPDescription',
    defaultMessage: 'Adjust the fields below to fine-tune your space needs',
  },
  'quizResult.yourProgram.customizeYPDescriptionMobile': {
    id: 'quizResult.yourProgram.customizeYPDescriptionMobile',
    defaultMessage: 'Adjust headcount to fine-tune program :',
  },
  'quizResult.yourProgram.headcount': {
    id: 'quizResult.yourProgram.headcount',
    defaultMessage: 'Headcount',
  },
  'quizResult.yourProgram.recommendedOfficeSize': {
    id: 'quizResult.yourProgram.recommendedOfficeSize',
    defaultMessage: 'Recommended office size',
  },
  'quizResult.yourProgram.profile': {
    id: 'quizResult.yourProgram.profile',
    defaultMessage: 'Profile',
  },
  'quizResult.yourProgram.usf': {
    id: 'quizResult.yourProgram.usf',
    defaultMessage: 'USF',
  },
  'quizResult.yourProgram.nsm': {
    id: 'quizResult.yourProgram.nsm',
    defaultMessage: 'Net Area',
  },
  'quizResult.yourProgram.nsy': {
    id: 'quizResult.yourProgram.nsy',
    defaultMessage: 'NSY',
  },
  'quizResult.yourProgram.Sqft': {
    id: 'quizResult.yourProgram.Sqft',
    defaultMessage: 'Sq ft',
  },
  'quizResult.yourProgram.Sqmt': {
    id: 'quizResult.yourProgram.Sqmt',
    defaultMessage: 'Sq mt',
  },
  'quizResult.yourProgram.Sqyd': {
    id: 'quizResult.yourProgram.Sqyd',
    defaultMessage: 'Sq yd',
  },
  'quizResult.yourProgram.usfTitle': {
    id: 'quizResult.yourProgram.usfTitle',
    defaultMessage: 'Usable Square Feet',
  },
  'quizResult.yourProgram.nsmTitle': {
    id: 'quizResult.yourProgram.nsmTitle',
    defaultMessage: 'Net Square Meter',
  },
  'quizResult.yourProgram.nsyTitle': {
    id: 'quizResult.yourProgram.nsyTitle',
    defaultMessage: 'Net Square Yard',
  },
  'quizResult.yourProgram.reset': {
    id: 'quizResult.yourProgram.reset',
    defaultMessage: 'Reset',
  },
  'quizResult.yourProgram.saveChanges': {
    id: 'quizResult.yourProgram.saveChanges',
    defaultMessage: 'Save changes',
  },
  'quizResult.yourProgram.save': {
    id: 'quizResult.yourProgram.save',
    defaultMessage: 'Save',
  },
  'quizResult.yourProgram.individualSpace': {
    id: 'quizResult.yourProgram.individualSpace',
    defaultMessage: 'Individual Space',
  },
  'quizResult.yourProgram.groupSpace': {
    id: 'quizResult.yourProgram.groupSpace',
    defaultMessage: 'Group Space',
  },
  'quizResult.yourProgram.amenitySpace': {
    id: 'quizResult.yourProgram.amenitySpace',
    defaultMessage: 'Amenity Space',
  },
  'quizResult.yourProgram.spaceGroupDesc.text': {
    id: 'quizResult.yourProgram.spaceGroupDesc.text',
    defaultMessage: 'Select a space type to review',
  },
  'quizResult.yourProgram.spaceGroupDesc.heading': {
    id: 'quizResult.yourProgram.spaceGroupDesc.heading',
    defaultMessage: 'Review',
  },
  'quizResult.yourProgram.disclaimer': {
    id: 'quizResult.yourProgram.disclaimer',
    defaultMessage: 'The above program is a high-level estimate based on a generic floor plan. CBRE can work with you to refine the program based on your specific floor plate.',
  },
  'quizResult.yourProgram.SF': {
    id: 'quizResult.yourProgram.SF',
    defaultMessage: 'SF'
  },
  'quizResult.yourProgram.SM': {
    id: 'quizResult.yourProgram.SM',
    defaultMessage: 'SM'
  },
  'quizResult.yourProgram.SY': {
    id: 'quizResult.yourProgram.SY',
    defaultMessage: 'SY'
  },
  'quizResult.yourProgram.grossAreaNote': {
    id: 'quizResult.yourProgram.grossAreaNote',
    defaultMessage: 'Gross Area Note'
  },
  'quizResult.yourCostEstimate.Description': {
    id: 'quizResult.yourCostEstimate.Description',
    defaultMessage: '*MEP cost may vary significantly pending on the base condition of the tenants building and any landlord obligations. This cost model demonstrates a high level of MEP work being done.',
  },
  'quizResult.yourCostEstimate.TypicalDescription': {
    id: 'quizResult.yourCostEstimate.TypicalDescription',
    defaultMessage: '*Prices are for typical office projects in Singapore, between 5,000 to 30,000 sq ft.',
  },
  'quizResult.yourCostEstimate.GradeDescription': {
    id: 'quizResult.yourCostEstimate.GradeDescription',
    defaultMessage: '*Prices are for projects between 5,000 to 30,000 sq ft in Grade-A office buildings.',
  },
  'quizResult.yourCostEstimate.typically': {
    id: 'quizResult.yourCostEstimate.typically',
    defaultMessage: 'These are typically:',
  },
  'quizResult.yourCostEstimate.GradeDescriptionList1': {
    id: 'quizResult.yourCostEstimate.GradeDescriptionList1',
    defaultMessage: '- New premium office buildings',
  },
  'quizResult.yourCostEstimate.GradeDescriptionList2': {
    id: 'quizResult.yourCostEstimate.GradeDescriptionList2',
    defaultMessage: '- With Green Mark/LEED compliance requirements for tenant for fit-out to follow(power budget, lighting budget, harmonic filter, BMS system integration)',
  },
  'quizResult.yourCostEstimate.GradeDescriptionList3': {
    id: 'quizResult.yourCostEstimate.GradeDescriptionList3',
    defaultMessage: '- Base building consultant Vetting',
  },
  'quizResult.yourCostEstimate.GradeDescriptionList4': {
    id: 'quizResult.yourCostEstimate.GradeDescriptionList4',
    defaultMessage: '- Requirements to engage base building nominated contractors to uphold new building warranty',
  },
  'quizResult.yourCostEstimate.Total': {
    id: 'quizResult.yourCostEstimate.Total',
    defaultMessage: 'TOTAL',
  },
  'quizResult.yourCostEstimate.Title': {
    id: 'quizResult.yourCostEstimate.Title',
    defaultMessage: 'TRADITIONAL',
  },
  'quizResult.yourCostEstimate.SubTitile': {
    id: 'quizResult.yourCostEstimate.SubTitile',
    defaultMessage: 'Office Ready',
  },
  'quizResult.yourCostEstimate.CostPerSF': {
    id: 'quizResult.yourCostEstimate.CostPerSF',
    defaultMessage: '(cost per SF)',
  },
  'quizResult.yourCostEstimate.disclaimer': {
    id: 'quizResult.yourCostEstimate.disclaimer',
    defaultMessage: 'The above cost estimate is a high-level estimate based on a generic floor plan. CBRE can work with you to refine the cost estimates based on your specific floor plate.',
  },
  'quizResult.yourCostEstimate.costThresoldMessage': {
    id: 'quizResult.yourCostEstimate.costThresoldMessage',
    defaultMessage: 'Please get in touch with a CBRE Designer to estimate the cost',
  },
  'quizResult.yourCostEstimate.notes': {
    id: 'quizResult.yourCostEstimate.notes',
    defaultMessage: 'All rates are exclusive of VAT. Professional fees are excluded. Local authority and construction tax will be approximately 5% of the construction costs.',
  },
  'quizResult.yourCostEstimate.repaymentNotesHeading': {
    id: 'quizResult.yourCostEstimate.repaymentNotesHeading',
    defaultMessage: 'Repayment Plan:',
  },
  'quizResult.yourCostEstimate.repaymentNotes': {
    id: 'quizResult.yourCostEstimate.repaymentNotes',
    defaultMessage: 'Pricing as guide and subject to final review. Fit-out ownership remains with CBRE funding platform until end of repayment and thereafter transfers to customer.',
  },  
  'quizResult.yourCostEstimate.baseRate1':{
    id: 'quizResult.yourCostEstimate.baseRate1',
    defaultMessage:'*- Exchange rate has been fixed on 1 GBP to '
  },  
  'quizResult.yourCostEstimate.baseRate2':{
    id: 'quizResult.yourCostEstimate.baseRate2',
    defaultMessage:' EUR, any variations to exchange rate will require an adjustment to the EUR cost estimate.'
  },
  'quizResult.popupstyle.Readmore': {
    id: 'quizResult.popupstyle.Readmore',
    defaultMessage: 'Read more about',
  },
  'quizResult.popupstyle.Style': {
    id: 'quizResult.popupstyle.Style',
    defaultMessage: 'style',
  },
  'quizResult.popupstyle.lookingStyle': {
    id: 'quizResult.popupstyle.lookingStyle',
    defaultMessage: 'Looking for a different style?',
  },
  'quizResult.popupstyle.ProfileText': {
    id: 'quizResult.popupstyle.ProfileText',
    defaultMessage: 'design style',
  },
  'quizResult.reviewcbredesigner.descriptiontext': {
    id: 'quizResult.reviewcbredesigner.descriptiontext',
    defaultMessage:
      'Please share your current space situation and why CBRE’s Office Ready solution is appealing',
  },
  'quizResult.reviewcbredesigner.linkToResult': {
    id: 'quizResult.reviewcbredesigner.linkToResult',
    defaultMessage: 'Link to result',
  },
  'quiz.questions.headcount': {
    id: 'quiz.questions.headcount',
    defaultMessage: 'Employee headcount should not be more than 500.',
  },
  'sector.placeHolderMsg': {
    id: 'sector.placeHolderMsg',
    defaultMessage: 'Select from list',
  },
  'quizResult.pdf.viewCustomOfficeText': {
    id: 'quizResult.pdf.viewCustomOfficeText',
    defaultMessage: 'View your custom Office Ready recommendations:',
  },
  'quizResult.pdf.yourResultHeading': {
    id: 'quizResult.pdf.yourResultHeading',
    defaultMessage: ', your results for CBRE’s Office Ready',
  },
  'quizResult.pdf.areaUnit': {
    id: 'quizResult.pdf.areaUnit',
    defaultMessage: 'USF',
  },
  'resultPopup.heading': {
    id: "resultPopup.heading",
    defaultMessage: 'Anyone with the link can view and edit results'
  },
  'footer.aboutOfficeready': {
    id: 'footer.aboutOfficeready',
    defaultMessage: 'https://www.cbre.com/services/design-and-build/design/office-ready'
  },
  'footer.insights': {
    id: 'footer.insights',
    defaultMessage: 'https://www.cbre.com/insights/future-of-work'
  },
  'footer.cbreDesign': {
    id: 'footer.cbreDesign',
    defaultMessage: 'https://www.cbre.com/services/design-and-build/design'
  },
  'footer.privacyPolicy': {
    id: 'footer.privacyPolicy',
    defaultMessage: 'https://www.cbre.com/about/privacy-policy'
  },
  'termsAndConditions.heading': {
    id: 'termsAndConditions.heading',
    defaultMessage: 'Terms of Service'
  },
  'termsAndConditions.para1': {
    id: 'termsAndConditions.para1',
    defaultMessage: 'This is a legal agreement between you and CBRE, Inc. (“CBRE”) regarding access to and use of the application known as Office Ready (“Office Ready”). By using or accessing any of the information or services on this web-based application, you acknowledge that you have read, understood and agree to the terms and conditions set forth in this Terms of Service (the “Agreement”). The terms “you”, “your” or “User” refers, collectively, to the person and any entity on whose behalf such person may be acting.'
  },
  'termsAndConditions.para2': {
    id: 'termsAndConditions.para2',
    defaultMessage: '....'
  },
  'termsAndConditions.para3': {
    id: 'termsAndConditions.para3',
    defaultMessage: '....'
  },
  'termsAndConditions.limitationsTitle': {
    id: 'termsAndConditions.limitationsTitle',
    defaultMessage: 'limitations.',
  },
  'termsAndConditions.limitationslist1': {
    id: 'termsAndConditions.limitationslist1',
    defaultMessage: 'User understands and agrees that User’s access to Office Ready is non-exclusive, non-sublicensable, non-transferable, freely revocable and can be suspended or terminated at any time for any reason by CBRE.',
  },
  'termsAndConditions.limitationslist2': {
    id: 'termsAndConditions.limitationslist2',
    defaultMessage: 'User further understands and agrees that the grant of access to Office Ready shall not confer in User any rights of ownership in Office Ready or any related documentation (including in any new releases, modifications or enhancements), all of which shall be and remain the exclusive property of CBRE, including without limitation, the copyrights thereto, associated patents, design patents, trademarks, trade dress, trade secrets and other proprietary rights arising under applicable law or international conventions (collectively, “Proprietary Rights”). See Section 2 (Ownership) below. CBRE hereby expressly reserves all rights in Office Ready which are not expressly granted to User hereunder.',
  },
  'termsAndConditions.limitationslist3': {
    id: 'termsAndConditions.limitationslist3',
    defaultMessage: 'Except as provided herein or as expressly authorized by CBRE to User in writing, User will not: (i) modify, translate, reverse engineer, decompile, disassemble, attempt to reconstruct, identify, or discover any source code, underlying ideas, underlying user interface techniques or algorithms, or create derivative works of or copy Office Ready; (ii) remove, alter, or cover any copyright or trademark notices or other proprietary rights notices contained in Office Ready; (iii) assign, sublicense, or otherwise transfer its access, use or other rights or delegate its obligations under this Agreement; or (iv) without limiting any of the foregoing, use Office Ready for the purpose of building a competitive product or service, or for any purpose other than legitimate business purposes relating to User’s business.',
  },
  'termsAndConditions.limitationslist4': {
    id: 'termsAndConditions.limitationslist4',
    defaultMessage: 'User agrees to notify CBRE promptly in writing if any unauthorized use of Office Ready, or any possible infringement of Proprietary Rights, comes to its attention.',
  },
  'termsAndConditions.limitationslist5': {
    id: 'termsAndConditions.limitationslist5',
    defaultMessage: 'At no time during or after the term of this Agreement shall User apply for any registration of any copyright, trademark or other designation which would affect the ownership of Proprietary Rights nor file any documents with any governmental authority to take any action which would affect the ownership of Proprietary Rights, including CBRE’s ownership of Office Ready, the mark Office Ready, and other application components. Office Ready is a trademark and service mark of CBRE.',
  },
  'termsAndConditions.limitationslist6': {
    id: 'termsAndConditions.limitationslist6',
    defaultMessage: 'User represents and agrees that User has the right to provide all materials posted on, or information used in connection with, Office Ready, and hereby grants to CBRE a non-exclusive, royalty-free, worldwide, irrevocable, perpetual right and license to use, reproduce, modify, distribute, create derivative works of, and display such materials and information in CBRE’s discretion. User acknowledges that User is responsible for whatever material and information is submitted by User, including its legality, reliability, appropriateness, originality, and copyright. The license grant by User to CBRE hereunder will survive any termination of User’s use of Office Ready. Any material and information submitted by User will be considered non-confidential and non-proprietary, and treated as such by CBRE, and may be used by CBRE in accordance with this Agreement without notice to User and without any liability to CBRE.',
  },
  'termsAndConditions.ownership': {
    id: 'termsAndConditions.ownership',
    defaultMessage:'Ownership'
  },
  'termsAndConditions.ownershipDesc': {
    id: 'termsAndConditions.ownershipDesc',
    defaultMessage:'Office Ready, the programming language, software and documentation and all other material or content made available through Office Ready or any improvements, modifications, derivative works, enhancements or changes to Office Ready and all copies thereof are proprietary to CBRE and title thereto remains solely in CBRE under all circumstances, including without limitation all rights to patents, copyrights, trademarks and trade secrets in Office Ready and the improvements, modifications, derivative works, and changes thereto. User understands that the foregoing includes trade secrets and information that is confidential and proprietary to CBRE and agrees to take all necessary actions to protect the confidentiality of such information. User shall not sell, transfer, publish, disclose, display or otherwise make available Office Ready or create or make available any improvements, modifications, enhancements, derivative works, or changes thereto or copies thereof to others. Termination of this Agreement shall be in addition to and not in lieu of any remedies available to CBRE both in law and equity. The obligations hereunder shall survive the termination of this Agreement.'
  },
  'termsAndConditions.termination': {
    id: 'termsAndConditions.termination',
    defaultMessage:'Termination; Effect of Termination; Remedies.'
  },
  'termsAndConditions.terminationList1': {
    id: 'termsAndConditions.terminationList1',
    defaultMessage:'Without limiting any other remedies, CBRE may modify, limit, suspend, discontinue or terminate this Agreement and/or User’s use of all or any part of Office Ready, with immediate effect, automatically, with or without notice and without recourse to the courts or other tribunals, for any reason or for no reason. User agrees that CBRE is under no obligation to provide Office Ready and that CBRE shall not be liable to User or to any other party for any limitation, suspension, discontinuance, termination or modification of Office Ready.'
  },
  'termsAndConditions.terminationList2': {
    id: 'termsAndConditions.terminationList2',
    defaultMessage:'User acknowledges that the obligations made hereunder to CBRE are of a unique and irreplaceable nature, the loss of which shall irreparably harm CBRE and which cannot be replaced by monetary damages alone so that CBRE shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by User. User irrevocably waives all rights to seek injunctive or other equitable relief.'
  },
  'termsAndConditions.disclaimer': {
    id: 'termsAndConditions.disclaimer',
    defaultMessage:'Disclaimers and Limitation of Liability.'
  },
  'termsAndConditions.disclaimerList1': {
    id: 'termsAndConditions.disclaimerList1',
    defaultMessage:'Office Ready is provided to User “AS IS” and “AS AVAILABLE” with no warranties. CBRE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. CBRE DOES NOT WARRANT IN ANY WAY THAT OFFICE READY WILL MEET USER’S REQUIREMENTS OR ANY PARTICULAR STANDARD. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO USER. In such event the liability of CBRE will be limited to the maximum extent possible under applicable law.'
  },
  'termsAndConditions.disclaimerList2': {
    id: 'termsAndConditions.disclaimerList2',
    defaultMessage:'NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY CBRE, ITS AGENTS OR EMPLOYEES, SHALL CREATE A WARRANTY IN ANY WAY WHATSOEVER.'
  },
  'termsAndConditions.disclaimerList3': {
    id: 'termsAndConditions.disclaimerList3',
    defaultMessage:'NO WARRANTY IS GIVEN THAT OFFICE READY OR ANY INFORMATION PROVIDED TO USER ARE ERROR FREE OR THAT THE USE OF OFFICE READY WILL BE UNINTERRUPTED, UP-TO-DATE, COMPLETE OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, AND NO WARRANTY IS GIVEN THAT ANY OF THE FOREGOING WILL BE CORRECTED. CBRE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OF, OR THE RESULTS FROM THE USE OF, OFFICE READY.'
  },
  'termsAndConditions.disclaimerList4': {
    id: 'termsAndConditions.disclaimerList4',
    defaultMessage:'CBRE SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LOST REVENUE, LOST SAVINGS, COMPUTER INTERRUPTION, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES), WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF CBRE OR ITS REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.'
  },
  'termsAndConditions.disclaimerList5': {
    id: 'termsAndConditions.disclaimerList5',
    defaultMessage:'CBRE’S TOTAL LIABILITY FOR DAMAGES FOR ANY CAUSE WHATSOEVER SHALL NOT EXCEED THE FEE PAID BY USER FOR OFFICE READY DURING THE TWELVE (12) MONTH PERIOD PRIOR TO THE EVENT THAT ALLEGEDLY CAUSED SUCH DAMAGE. USER AGREES THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO Office Ready OR OTHERWISE RELATED TO THIS AGREEMENT MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.'
  },
  'termsAndConditions.disclaimerList6': {
    id: 'termsAndConditions.disclaimerList6',
    defaultMessage:'SOME JURISDICTIONS MAY NOT ALLOW THE LIMITATION ON LIABILITY OR EXCLUSIONS OF LIABILITY SET FORTH ABOVE, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO USER. In such event the liability of CBRE will be limited to the maximum extent possible under applicable law. The waiver of warranty, exclusive remedies and limited liability set forth above are fundamental elements of the basis for this Agreement between CBRE and User. CBRE would not be able to provide Office Ready on an economic basis, and would not have entered into this Agreement, without such limitations. These limitations shall survive any termination of this Agreement.'
  },
  'termsAndConditions.userIndemnification': {
    id: 'termsAndConditions.userIndemnification',
    defaultMessage:'User Indemnification.'
  },
  'termsAndConditions.userIndemnificationDesc': {
    id: 'termsAndConditions.userIndemnificationDesc',
    defaultMessage:'At CBRE’s option and request, User shall, at its own expense, indemnify and hold harmless CBRE and its subsidiaries, affiliates, officers, employees, agents, co-branders, associations, and other partners harmless from and against any losses, costs, damages, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees), arising out of or related to any third-party claim, action or allegation related to or arising from: (a) any transaction or dispute between User and any third party; (b) facts or alleged facts that would constitute a breach of any of User’s representations, warranties, or covenants under this Agreement or of any applicable law, rule or regulation, whether or not referenced herein; (c) any User materials, information or content submitted in or on Office Ready; (d) User’s violation of any rights of any third party, (e) User’s use or misuse of Office Ready; or (f) the negligence or willful misconduct of User. CBRE shall have the right to exclusively direct and control its defense and hire counsel of its choice, at User’s expense.'
  },
  'termsAndConditions.userIndemnificationList1': {
    id: 'termsAndConditions.userIndemnificationList1',
    defaultMessage:'At CBRE’s option and request, User shall, at its own expense, indemnify and hold harmless CBRE and its subsidiaries, affiliates, officers, employees, agents, co-branders, associations, and other partners harmless from and against any losses, costs, damages, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees), arising out of or related to any third-party claim, action or allegation related to or arising from: (a) any transaction or dispute between User and any third party; (b) facts or alleged facts that would constitute a breach of any of User’s representations, warranties, or covenants under this Agreement or of any applicable law, rule or regulation, whether or not referenced herein; (c) any User materials, information or content submitted in or on Office Ready; (d) User’s violation of any rights of any third party, (e) User’s use or misuse of Office Ready; or (f) the negligence or willful misconduct of User. CBRE shall have the right to exclusively direct and control its defense and hire counsel of its choice, at User’s expense.'
  },
  'termsAndConditions.userIndemnificationList2': {
    id: 'termsAndConditions.userIndemnificationList2',
    defaultMessage:'At CBRE’s option and request, User shall, at its own expense, indemnify and hold harmless CBRE and its subsidiaries, affiliates, officers, employees, agents, co-branders, associations, and other partners harmless from and against any losses, costs, damages, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees), arising out of or related to any third-party claim, action or allegation related to or arising from: (a) any transaction or dispute between User and any third party; (b) facts or alleged facts that would constitute a breach of any of User’s representations, warranties, or covenants under this Agreement or of any applicable law, rule or regulation, whether or not referenced herein; (c) any User materials, information or content submitted in or on Office Ready; (d) User’s violation of any rights of any third party, (e) User’s use or misuse of Office Ready; or (f) the negligence or willful misconduct of User. CBRE shall have the right to exclusively direct and control its defense and hire counsel of its choice, at User’s expense.'
  },
  'termsAndConditions.userIndemnificationList3': {
    id: 'termsAndConditions.userIndemnificationList3',
    defaultMessage:'At CBRE’s option and request, User shall, at its own expense, indemnify and hold harmless CBRE and its subsidiaries, affiliates, officers, employees, agents, co-branders, associations, and other partners harmless from and against any losses, costs, damages, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees), arising out of or related to any third-party claim, action or allegation related to or arising from: (a) any transaction or dispute between User and any third party; (b) facts or alleged facts that would constitute a breach of any of User’s representations, warranties, or covenants under this Agreement or of any applicable law, rule or regulation, whether or not referenced herein; (c) any User materials, information or content submitted in or on Office Ready; (d) User’s violation of any rights of any third party, (e) User’s use or misuse of Office Ready; or (f) the negligence or willful misconduct of User. CBRE shall have the right to exclusively direct and control its defense and hire counsel of its choice, at User’s expense.'
  },
  'termsAndConditions.userIndemnificationList4': {
    id: 'termsAndConditions.userIndemnificationList4',
    defaultMessage:'At CBRE’s option and request, User shall, at its own expense, indemnify and hold harmless CBRE and its subsidiaries, affiliates, officers, employees, agents, co-branders, associations, and other partners harmless from and against any losses, costs, damages, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees), arising out of or related to any third-party claim, action or allegation related to or arising from: (a) any transaction or dispute between User and any third party; (b) facts or alleged facts that would constitute a breach of any of User’s representations, warranties, or covenants under this Agreement or of any applicable law, rule or regulation, whether or not referenced herein; (c) any User materials, information or content submitted in or on Office Ready; (d) User’s violation of any rights of any third party, (e) User’s use or misuse of Office Ready; or (f) the negligence or willful misconduct of User. CBRE shall have the right to exclusively direct and control its defense and hire counsel of its choice, at User’s expense.'
  },
  'termsAndConditions.userIndemnificationList5': {
    id: 'termsAndConditions.userIndemnificationList5',
    defaultMessage:'At CBRE’s option and request, User shall, at its own expense, indemnify and hold harmless CBRE and its subsidiaries, affiliates, officers, employees, agents, co-branders, associations, and other partners harmless from and against any losses, costs, damages, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees), arising out of or related to any third-party claim, action or allegation related to or arising from: (a) any transaction or dispute between User and any third party; (b) facts or alleged facts that would constitute a breach of any of User’s representations, warranties, or covenants under this Agreement or of any applicable law, rule or regulation, whether or not referenced herein; (c) any User materials, information or content submitted in or on Office Ready; (d) User’s violation of any rights of any third party, (e) User’s use or misuse of Office Ready; or (f) the negligence or willful misconduct of User. CBRE shall have the right to exclusively direct and control its defense and hire counsel of its choice, at User’s expense.'
  },
  'termsAndConditions.userIndemnificationList6': {
    id: 'termsAndConditions.userIndemnificationList6',
    defaultMessage:'At CBRE’s option and request, User shall, at its own expense, indemnify and hold harmless CBRE and its subsidiaries, affiliates, officers, employees, agents, co-branders, associations, and other partners harmless from and against any losses, costs, damages, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees), arising out of or related to any third-party claim, action or allegation related to or arising from: (a) any transaction or dispute between User and any third party; (b) facts or alleged facts that would constitute a breach of any of User’s representations, warranties, or covenants under this Agreement or of any applicable law, rule or regulation, whether or not referenced herein; (c) any User materials, information or content submitted in or on Office Ready; (d) User’s violation of any rights of any third party, (e) User’s use or misuse of Office Ready; or (f) the negligence or willful misconduct of User. CBRE shall have the right to exclusively direct and control its defense and hire counsel of its choice, at User’s expense.'
  },
  'termsAndConditions.protectionOfPersonalData': {
    id: 'termsAndConditions.protectionOfPersonalData',
    defaultMessage:'Protection des données à caractère personnel.'
  },
  'termsAndConditions.protectionOfPersonalDataList1': {
    id: 'termsAndConditions.protectionOfPersonalDataList1',
    defaultMessage:'Les termes suivants ont la définition qui leur est donnée sous le règlement n°2016/679 du Parlement européen et Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l\'égard du traitement des données à caractère personnel et à la libre circulation de ces données, dit Règlement Général sur la Protection des Données (RGPD) : « Données à caractère personnel », « Responsable du traitement », « Traitement »,  et « Transfert » de Données à caractère personnel.'
  },
  'termsAndConditions.protectionOfPersonalDataList2': {
    id: 'termsAndConditions.protectionOfPersonalDataList2',
    defaultMessage:'Lors de l’utilisation du Site par l’Utilisateur, CBRE collecte les Données à caractère personnel suivantes : nom et prénom de l’Utilisateur, adresse email de l’Utilisateur et adresse email du contact chez CBRE. CBRE agit donc en tant que Responsable du traitement. Si l’Utilisateur refuse de fournir ces Données à caractère personnel, CBRE ne sera pas en mesure de lui fournir les services demandés.'
  },
  'termsAndConditions.protectionOfPersonalDataList3': {
    id: 'termsAndConditions.protectionOfPersonalDataList3',
    defaultMessage:'Le Traitement de ces Données à caractère personnel a pour finalité la facilitation de l’aménagement d’espace de bureaux et repose sur l’exécution du contrat.'
  },
  'termsAndConditions.protectionOfPersonalDataList4': {
    id: 'termsAndConditions.protectionOfPersonalDataList4',
    defaultMessage:'CBRE ne partagera pas les Données à caractère personnel de l’Utilisateur avec des tiers.'
  },
  'termsAndConditions.protectionOfPersonalDataList5': {
    id: 'termsAndConditions.protectionOfPersonalDataList5',
    defaultMessage:'CBRE transfère certaine de vos Données à caractère personnel aux Etats-Unis pour les conserver. Le Transfert de ces Données à caractère personnel est encadré par les clauses contractuelles types de la Commission du 4 juin 2021.'
  },
  'termsAndConditions.protectionOfPersonalDataList6': {
    id: 'termsAndConditions.protectionOfPersonalDataList6',
    defaultMessage:'Les Données à caractère personnel traitées dans le cadre du présent Contrat seront conservées pendant le temps nécessaire à l’exécution du contrat, sauf pour répondre à des obligations légales et règlementaires.'
  },
  'termsAndConditions.protectionOfPersonalDataList7': {
    id: 'termsAndConditions.protectionOfPersonalDataList7',
    defaultMessage:'Nous avons adopté des mesures de sécurité physiques, électroniques et administratives incluant l\'utilisation de pares-feux étendus et de mots de passe permettant de sécuriser l\'accès aux données personnelles. En outre, nous limitons l\'accès aux Données à caractère personnel aux collaborateurs CBRE qui ont besoin d’en connaître.'
  },
  'termsAndConditions.protectionOfPersonalDataList8': {
    id: 'termsAndConditions.protectionOfPersonalDataList8',
    defaultMessage:'Conformément au RGPD et à la Loi n° 78-17 du 6 janvier 1978 relative à l\'informatique, aux fichiers et aux libertés, l’Utilisateur dispose de plusieurs droits qu’il peut exercer :  droit d’accès, de rectification, d’effacement, de limitation du traitement, droit à la portabilité de ces données, droit d’opposition, droit au retrait du consentement (ainsi que le droit de réclamation auprès de la CNIL – www.cnil.fr - dès lors que ledit traitement constitue une violation du règlement susvisé) auprès du responsable de l’éthique et de la conformité du groupe CBRE ayant la qualité de délégué à la protection des données aux adresses suivantes : 76 rue de Prony 75017 Paris ou Compliance@cbre.fr.'
  },
  'termsAndConditions.general': {
    id: 'termsAndConditions.general',
    defaultMessage:'General.'
  },
  'termsAndConditions.generalList1': {
    id: 'termsAndConditions.generalList1',
    defaultMessage:'Entire Agreement/No Unintentional Waiver.'
  },
  'termsAndConditions.generalList1_2': {
    id: 'termsAndConditions.generalList1_2',
    defaultMessage:'This Agreement constitutes the entire agreement between CBRE and User with respect to Office Ready and all subject matter of this Agreement and supersedes all prior agreements between CBRE and User related to Office Ready if any. CBRE’s failure to enforce any provision of this Agreement will not be construed as a waiver of any provision or right.'
  },
  'termsAndConditions.generalList2': {
    id: 'termsAndConditions.generalList2',
    defaultMessage:'Notices.'
  },
  'termsAndConditions.generalList2_2': {
    id: 'termsAndConditions.generalList2_2',
    defaultMessage:'Notices to you shall be in writing and may be made via email or posting such notices on Office Ready. You will provide notices to us via email to [please insert a support email address], with a copy sent via certified U.S. mail to CBRE at 400 S. Hope Street, 25th Floor, Los Angeles, CA 90071, Attention: General Counsel’s Office. Notices will be deemed given one (1) business day after being sent via email (unless the sender receives a response indicating that the message was undeliverable) or three business days after being posted on the CBRE website (in the case of CBRE), whether or not received.'
  },
  'termsAndConditions.generalList3': {
    id: 'termsAndConditions.generalList3',
    defaultMessage:'Governing Law.'
  },
  'termsAndConditions.generalList3_2': {
    id: 'termsAndConditions.generalList3_2',
    defaultMessage:'This Agreement shall be governed by and construed in accordance with the laws of the State of California without regard to the conflicts of laws provisions thereof. CBRE and User hereby subject themselves exclusively to venues within the State of California. The United Nations Convention on Contracts for the International Sale of Goods (1980) is hereby excluded in its entirety from application to this Agreement. User hereby agrees that Office Ready shall be deemed solely based in California and a passive service that does not give rise to personal jurisdiction over CBRE, either specific or general, in jurisdictions other than the State of California.'
  },
  'termsAndConditions.generalList4': {
    id: 'termsAndConditions.generalList4',
    defaultMessage:'Dispute Resolution.'
  },
  'termsAndConditions.generalList4_2': {
    id: 'termsAndConditions.generalList4_2',
    defaultMessage:'Any dispute, claim or controversy arising out of or relating to this Agreement, or the breach, enforcement, interpretation or validity thereof, including the determination of the scope or applicability of arbitration with respect to this Agreement, shall be determined by arbitration.  The arbitration shall be administered by the Judicial Arbitration and Mediation Service (JAMS), and the arbitration shall be conducted with a single arbitrator.  Furthermore, User agrees that neither User nor CBRE will join any claim with the claim of any other person or entity in arbitration; that no claim will be resolved on a class-wide basis; and that neither User nor CBRE will assert any claim in a representative capacity on behalf of anyone else.  Notwithstanding the foregoing, User agrees that CBRE shall be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.  If User files an arbitration complaint against CBRE, CBRE agrees to pay for any portion of the initial filing fee that exceeds $250; after the initial filing fees, the parties will share the costs of arbitration equally.  User agrees not to commence or prosecute any action against CBRE other than by filing an arbitration complaint in accordance with this paragraph.  IMPORTANTLY, BY AGREEING TO BE BOUND BY THIS AGREEMENT, USER IS WAIVING ANY RIGHT USER HAS TO SEEK RELIEF IN A COURT OF LAW AND ANY RIGHT USER HAS TO A TRIAL BY JURY.'
  },
  'termsAndConditions.generalList5': {
    id: 'termsAndConditions.generalList5',
    defaultMessage:'Severability.'
  },
  'termsAndConditions.generalList5_2': {
    id: 'termsAndConditions.generalList5_2',
    defaultMessage:'In the event that a portion of this Agreement is held unenforceable, the unenforceable portion shall be modified only to the extent necessary to make it enforceable, and the remainder of the provisions will remain in full force and effect.'
  },
  'termsAndConditions.generalList6': {
    id: 'termsAndConditions.generalList6',
    defaultMessage:'Assignment.'
  },
  'termsAndConditions.generalList6_2': {
    id: 'termsAndConditions.generalList6_2',
    defaultMessage:'This Agreement may not be assigned by User without CBRE’s prior written consent. Any attempted assignment without CBRE’s consent shall be void. CBRE reserves the right to assign this Agreement to any third party. This Agreement and the rights and obligations of each of us under it will be binding on our respective successors and assigns.'
  },
  'termsAndConditions.generalList7': {
    id: 'termsAndConditions.generalList7',
    defaultMessage:'Modification.'
  },
  'termsAndConditions.generalList7_2': {
    id: 'termsAndConditions.generalList7_2',
    defaultMessage:'CBRE may modify any of the terms and conditions contained in this Agreement, at any time and in CBRE’s sole and exclusive discretion, by posting a change notice or a new agreement on Office Ready’s site at [https://officeready.cbre.com] (or such other URL as CBRE may designate) or by providing an alert or similar notification in Office Ready. If any modification is unacceptable to User, User’s only recourse is to terminate User’s use of Office Ready.'
  },
  'termsAndConditions.generalList8': {
    id: 'termsAndConditions.generalList8',
    defaultMessage:'Conflicts.'
  },
  'termsAndConditions.generalList8_2': {
    id: 'termsAndConditions.generalList8_2',
    defaultMessage:'In the event there is any conflict between the terms of this Agreement and any fully executed license agreement between User and CBRE with respect to Office Ready, the terms of such license agreement shall control with respect to such conflicting terms.'
  },
  'termsAndConditions.generalList9': {
    id: 'termsAndConditions.generalList9',
    defaultMessage:'© 2012 CBRE, Inc.'
  },
  
  'termsAndConditions.dateCopyright': {
    id: 'termsAndConditions.dateCopyright',
    defaultMessage: '© 2012 CBRE, Inc. Disclaimer: [See Paragraph 6 below]'
  },
  'termsAndConditions.dateEffective': {
    id: 'termsAndConditions.dateEffective',
    defaultMessage: 'Effective as of January 10, 2007'
  },
  'termsAndConditions.readCarefully': {
    id: 'termsAndConditions.readCarefully',
    defaultMessage: 'Please read carefully'
  },
  'termsAndConditions.readCarefullyP1': {
    id: 'termsAndConditions.readCarefullyP1',
    defaultMessage: 'By accessing www.cbre.co.uk ("Site") you confirm that you are a business user over the age of 18 years and permitted to use this Site, and you agree to be bound by all of the following terms and conditions of use ("Terms").'
  },
  'termsAndConditions.readCarefullyP2': {
    id: 'termsAndConditions.readCarefullyP2',
    defaultMessage: 'These Terms govern your access to the Site except where there are separate terms and conditions relevant to particular areas of this Site, which are indicated on the appropriate Site page. If you do not agree to abide by these Terms then you should not use this Site. For clarity, in these Terms, references to "we", "us", "our" and "ourselves" are references to CBRE Limited and any of its affiliates or group companies.'
  },
  'termsAndConditions.readCarefullyP3': {
    id: 'termsAndConditions.readCarefullyP3',
    defaultMessage: 'This Terms of use policy together with our Privacy Policy ("Privacy Policy") available at www.cbre.co.uk/emea_en/PrivacyPolicyEMEA set out the basis on which we will collect, use and disclose certain personal information from you. Your use of this Site signifies your understanding and acceptance of the terms of this Terms of use policy.'
  },
  'termsAndConditions.readCarefullyP4': {
    id: 'termsAndConditions.readCarefullyP4',
    defaultMessage: 'We may from time to time modify these Terms and will post a copy of the amended Terms on this Site. You will be bound by such updated Terms from the time they are posted on this Site. We would advise you to periodically visit this Site and this web page to ensure that you are up to date with any changes that have been made.'
  },
  'termsAndConditions.access': {
    id: 'termsAndConditions.access',
    defaultMessage: 'Access'
  },
  'termsAndConditions.accessP1': {
    id: 'termsAndConditions.accessP1',
    defaultMessage: 'We reserve the right to restrict certain areas of this Site to approved registered users. In the event that any area of the Site requires you to register with us prior to obtaining access, you agree to provide true, accurate, current and complete details, including but not limited to a member name and password as well as your name, address, telephone number and a valid email address ( "Account Information"). You agree to update your Account Information as necessary to keep it accurate. CBRE will use your Account Information in accordance with its Privacy Policy.'
  },
  'termsAndConditions.accessP2': {
    id: 'termsAndConditions.accessP2',
    defaultMessage: 'You agree to notify us immediately of any unauthorised use of your member name, password or account. CBRE will not be responsible for any losses arising out of the unauthorised use of your account and you agree to indemnify CBRE for any improper, unauthorised or illegal uses of your account.'
  },
  'termsAndConditions.license': {
    id: 'termsAndConditions.license',
    defaultMessage: 'License to use the Site'
  },
  'termsAndConditions.licenseP': {
    id: 'termsAndConditions.licenseP',
    defaultMessage: 'CBRE grants to you a limited, non-exclusive, non-transferable license to access and use the Site in accordance with these Terms. Any use of the Site not specifically permitted under these Terms is strictly prohibited.'
  },
  'termsAndConditions.intellectualProperty': {
    id: 'termsAndConditions.intellectualProperty',
    defaultMessage: 'Intellectual Property'
  },
  'termsAndConditions.intellectualPropertyP1': {
    id: 'termsAndConditions.intellectualPropertyP1',
    defaultMessage: 'You acknowledge that CBRE owns or has a license to all title and copyright in and to the content provided on this Site. All title and intellectual property rights in and to any licensed content provided on this Site is the property of the respective content owners and may be protected by applicable copyright or other intellectual property laws and treaties and subject to use restrictions under such laws or treaties.'
  },
  'termsAndConditions.intellectualPropertyP2': {
    id: 'termsAndConditions.intellectualPropertyP2',
    defaultMessage: 'You may use this Site for your information and internal business purposes and you may make a copy of the pages of this Site but only for your internal business use, and provided that you keep all copyright and other proprietary notices in tact. Any modification, transmission, hiring, copying or use of the content of this Site for public or commercial purposes (other than internal business use) is prohibited. The following are registered trademarks or trademarks of CBRE: CBRE and its design logo, as well as certain other CBRE trademarks, service marks, graphics, and logos (collectively, the "CBRE trademarks") used in connection with CBRE\'s provision of products and services. The site may contain third-party trademarks, service marks, graphics, and logos. You are not granted any right or license with respect to the CBRE trademarks or the trademarks of any third party.'
  },  
  'termsAndConditions.restrictions': {
    id: 'termsAndConditions.restrictions',
    defaultMessage: 'Restrictions'
  },
  'termsAndConditions.restrictionsP1': {
    id: 'termsAndConditions.restrictionsP1',
    defaultMessage: 'You agree that you will not:'
  },
  'termsAndConditions.restrictionsP1L1': {
    id: 'termsAndConditions.restrictionsP1L1',
    defaultMessage: 'use the Site to reproduce, transmit, display or distribute copyrighted material in any medium or via any method without CBRE\'s express written permission.'
  },
  'termsAndConditions.restrictionsP1L2': {
    id: 'termsAndConditions.restrictionsP1L2',
    defaultMessage: 'copy, store, edit, change, prepare any derivative work of or alter in any way any of the content provided on this Site. or'
  },
  'termsAndConditions.restrictionsP1L3': {
    id: 'termsAndConditions.restrictionsP1L3',
    defaultMessage: 'use our Site in any way that violates these Terms.'
  },
  'termsAndConditions.restrictionsP2': {
    id: 'termsAndConditions.restrictionsP2',
    defaultMessage: 'You agree that you will not: '
  },
  'termsAndConditions.restrictionsP2L1': {
    id: 'termsAndConditions.restrictionsP2L1',
    defaultMessage: 'impersonate any person or entity or misrepresent your affiliation with any other person or entity.'
  },
  'termsAndConditions.restrictionsP2L2': {
    id: 'termsAndConditions.restrictionsP2L2',
    defaultMessage: 'engage in spamming, flooding, harvesting of e-mail addresses or other personal information, spidering, screen scraping, database scraping, or any other activity with the purpose of obtaining lists of users or any other information, including specifically, property listings available through the Site.'
  },
  'termsAndConditions.restrictionsP2L3': {
    id: 'termsAndConditions.restrictionsP2L3',
    defaultMessage: 'send chain letters or pyramid schemes via the Site.'
  },
  'termsAndConditions.restrictionsP2L4': {
    id: 'termsAndConditions.restrictionsP2l4',
    defaultMessage: 'attempt to gain unauthorized access to other computer systems through the Site. or'
  },
  'termsAndConditions.restrictionsP2L5': {
    id: 'termsAndConditions.restrictionsP2L5',
    defaultMessage: 'use the Site in any manner that could damage, disable, overburden, or impair the Site or interfere with any other party\'s use and enjoyment of the Site.'
  },
  'termsAndConditions.privacyPolicy': {
    id: 'termsAndConditions.privacyPolicy',
    defaultMessage: 'Privacy Policy and Submission of Information'
  },
  'termsAndConditions.privacyPolicyP': {
    id: 'termsAndConditions.privacyPolicyP',
    defaultMessage: 'Unless otherwise addressed in these Terms, your use of this site is subject to CBRE\'s Privacy Policy.   Other than personally identifiable information, which is covered under our Privacy Policy, any material you transmit or post to the Site shall be considered non-confidential and non-proprietary. CBRE shall have no obligations with respect to such material. Any communications and other material which you transmit or post to this Site is and shall remain as our property and by sending the same you agree that we may use the same for any purpose whatsoever, without any compensation being payable by us to you.'
  },
  'termsAndConditions.liability': {
    id: 'termsAndConditions.liability',
    defaultMessage: 'Liability'
  },
  'termsAndConditions.liabilityP1': {
    id: 'termsAndConditions.liabilityP1',
    defaultMessage: 'Nothing in these Terms operates to restrict CBRE\'s liability for death or personal injury arising as a result of our negligence or for fraudulent misrepresentation.'
  },
  'termsAndConditions.liabilityP2': {
    id: 'termsAndConditions.liabilityP2',
    defaultMessage: 'In no event will CBRE be liable to you for indirect, general, special, incidental, consequential, exemplary or other damages (including, without limitation, damages for loss of profits, business interruption, corruption of files, loss of business information or any other pecuniary loss) in connection with the Site in any way or in connection with the use, inability to use or the results of use of the Site, even if CBRE has been advised of the possibility of such damages. No oral or written information or advice given by CBRE or others will create a warranty and neither you nor any third party may rely on any such information or advice.'
  },
  'termsAndConditions.indemnity': {
    id: 'termsAndConditions.indemnity',
    defaultMessage: 'Indemnity'
  },
  'termsAndConditions.indemnityP': {
    id: 'termsAndConditions.indemnityP',
    defaultMessage: 'You will indemnify and keep indemnified CBRE against all losses, costs claims, demands or expenses (whether direct or indirect) arising out of'
  },
  'termsAndConditions.indemnityPL1': {
    id: 'termsAndConditions.indemnityPL1',
    defaultMessage: 'Any claim that your operation, possession or use of the Site or any part of it in contradiction of these Terms in any way or any materials or information you submit to us infringes the copyright or intellectual property rights of any third party. or'
  },
  'termsAndConditions.indemnityPL2': {
    id: 'termsAndConditions.indemnityPL2',
    defaultMessage: 'Your use or misuse of the Site'
  },
  
  
  'error.heading1': {
    id: 'error.heading1',
    defaultMessage: 'Sorry, something went wrong.'
  },
  'error.heading2': {
    id: 'error.heading2',
    defaultMessage: 'This page is missing.'
  },
  'error.description1': {
    id: 'error.description1',
    defaultMessage: 'Try after some time. We now know about this mistake and are working to fix it.'
  },
  'error.description2': {
    id: 'error.description2',
    defaultMessage: '.. maybe the page you are looking for is not found or never existed.'
  },
  'home.sectionBottomSg.heading1_0':{
    id: 'home.sectionBottomSg.heading1_0',
    defaultMessage: "What's"
  },
  'home.sectionBottomSg.heading1_1':{
    id: 'home.sectionBottomSg.heading1_1',
    defaultMessage: "Next?"
  },
  'home.sectionBottomSg.subHeading1_0':{
    id: 'home.sectionBottomSg.subHeading1_0',
    defaultMessage: "We are"
  },
  'home.sectionBottomSg.subHeading1_1':{
    id: 'home.sectionBottomSg.subHeading1_1',
    defaultMessage: "here"
  },
  'home.sectionBottomSg.subHeading2_1':{
    id: 'home.sectionBottomSg.subHeading2_1',
    defaultMessage: "steps"
  },
  'home.toast.message':{
    id: 'home.toast.message',
    defaultMessage: "Select or change the country before starting the quiz"
  },
  'countryModal.heading': {
    id:'countryModal.heading',
    defaultMessage:"Your office will be in"
  }
});
