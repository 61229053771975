import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { languages } from "../translations/languages";
import flatten from 'flat';

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps(state: any) {  
    
  const languageCode = state.quiz.languageCode;

  const foundLanguage = languages.find(l => l.key === languageCode);
  // default language english
  const defaultLanguage = languages.find(l => l.key === 'en-US');
  
  const msg = foundLanguage ? foundLanguage.data : (defaultLanguage as any).data;

  return { locale: languageCode, messages: flatten(msg) as any };
}

export default connect(mapStateToProps)(IntlProvider);