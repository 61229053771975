import { HIGH_CIRCULATION_MAP, STANDARD_CIRCULATION_MAP } from "../models/enums";

  /*
      The Shared Program Calculator is a module that returns a set of functions for program calculations.
  
      Initially, the module only returns the "init" function.
  
      Once "init" has been called:
        - all other calculation functions become publicly available
        - the module has local access to metrics including: work seats, group seats, headcount, and blended circulation percentage
  */
 export class programCalculator {
  
  // *************************************
  //  PUBLIC
  // *************************************

  getCirculationPercentageFromCircType = (circType: string ,officeDensity: number) => {
    // if (circType === CIRCULATION_TYPE.BLENDED) return programMetrics.blendedCircPercentage;
    return officeDensity=== 2 ? HIGH_CIRCULATION_MAP[circType] : STANDARD_CIRCULATION_MAP[circType];
  }

  calculateUSF = (nsf: number, unitCircType: any,officeDensity: number) => {
    const circulationPercentage = this.getCirculationPercentageFromCircType(unitCircType, officeDensity);
    return Math.round(nsf / (1 - circulationPercentage));
  };
  
  calculateMeWeSupportProgramPartNSFAndUSF = (quantity: number, unitSF: number, unitCircType: string, officeDensity: number) => {
    const nsf = quantity * unitSF;
    const usf = this.calculateUSF(nsf, unitCircType, officeDensity);
    return { nsf, usf };
  };
  
  calculateMeProgramPart = (multiplier: number, unitSF: number, unitCircType: string, officeDensity: number, workSeats: number) => {
    const quantity = Math.round(multiplier * workSeats);
    const { nsf, usf } = this.calculateMeWeSupportProgramPartNSFAndUSF(quantity, unitSF, unitCircType, officeDensity);
    return { quantity, nsf, usf };
  };
  
  // Shared program part not in use for now
  // calculateSharedFocusProgramPart = (unitSF: number, unitCircType: string, ratio: number, totalOpenMeSeats: number, officeDensity: number) => {
  //   const quantity = ratio ? Math.round(totalOpenMeSeats / ratio) : 0;
  //   const { nsf, usf } = this.calculateMeWeSupportProgramPartNSFAndUSF(quantity, unitSF, unitCircType, officeDensity);
  //   return { quantity, nsf, usf };
  // };
  
  calculateWeProgramPart = (multiplier: number, unitSeats: number, unitSF: number, unitCircType: string, officeDensity: number, groupSeats: number) => {
    // this should be used in spacer free we calculations
    const quantity = Math.round((multiplier * groupSeats) / unitSeats);
    
    const { nsf, usf } = this.calculateMeWeSupportProgramPartNSFAndUSF(quantity, unitSF, unitCircType, officeDensity);
    return { quantity, nsf, usf };
  };
  
  calculateSupportProgramPart = (multiplier: number, unitSF: number, unitCircType: string, isFloorDependent: boolean, floors:number, officeDensity: number, headcount: number) => {
    let quantity;
    if (isFloorDependent) {
      quantity = Math.round((floors || 0) * multiplier); // we round here after multiplying not before - this causes display discrepancies
    } else {
      const calculatedQuantity = Math.round((headcount * multiplier) / unitSF);
      quantity = Math.max(calculatedQuantity, 1);
    }
    const { nsf, usf } = this.calculateMeWeSupportProgramPartNSFAndUSF(quantity, unitSF, unitCircType, officeDensity);
    return { quantity, nsf, usf };
  };
  
  calculateAmenityProgramPart = (multiplier: number, unitSF: number, unitCircType: string, unitMinSF: number, unitMaxSF: number, officeDensity: number, headcount: number) => {
    const quantity = 1;
    let nsf = multiplier ? Math.round(headcount * multiplier) : Math.round(unitSF);
    if (unitMinSF && nsf < unitMinSF) {
      nsf = unitMinSF;
    } else if (unitMaxSF && nsf > unitMaxSF) {
      nsf = unitMaxSF;
    }
    const usf = this.calculateUSF(nsf, unitCircType, officeDensity);
    return { quantity, usf, nsf };
  }; 
  
  calculateCategoryUSFTotals = ( meSpaces: any, weSpaces: any, amenitySpaces: any) => {
    // Suggested or calculated
    let totalMeSF = 0;
    let totalWeSF = 0;
    let totalAmenitySF = 0;
    let mePercentage = 0;
    let wePercentage = 0;
    let amenityPercentage = 0; 
  
    meSpaces.forEach((space: any) => {
      totalMeSF += space.usf;
    });
  
    weSpaces.forEach((space: any) => {
      totalWeSF += space.usf;
    });
  
    amenitySpaces.forEach((space: any) => {
      totalAmenitySF += space.usf;
    });
  
    const totalSF = totalMeSF + totalWeSF + totalAmenitySF;
    
    mePercentage = Math.round((totalMeSF / totalSF) * 100 );
    wePercentage = Math.round((totalWeSF / totalSF) * 100 );
    amenityPercentage = Math.round((totalAmenitySF / totalSF) * 100 );

    const totalPercentage = mePercentage + wePercentage + amenityPercentage ;
    
    // adjust total percentage to be 100
    if(mePercentage > wePercentage) {
     if(mePercentage > amenityPercentage) {
       if(totalPercentage === 101) {
        mePercentage = mePercentage - 1;
       } else if(totalPercentage === 99) {
        mePercentage = mePercentage + 1;
       }
      } else {
        if(totalPercentage === 101) {
          amenityPercentage = amenityPercentage - 1;
         } else if(totalPercentage === 99) {
          amenityPercentage = amenityPercentage + 1;
         }
      }
    } else {
      if(wePercentage > amenityPercentage) {
        if(totalPercentage === 101) {
          wePercentage = wePercentage - 1;
         } else if(totalPercentage === 99) {
          wePercentage = wePercentage + 1;
         }
      } else {
        if(totalPercentage === 101) {
          amenityPercentage = amenityPercentage - 1;
         } else if(totalPercentage === 99) {
          amenityPercentage = amenityPercentage + 1;
         }
      }
    }
    
  
    const areaTotals = {
      totalMeSF,      
      totalWeSF,
      totalAmenitySF,
      totalSF,
      mePercentage,
      wePercentage,
      amenityPercentage
    };  
    
    return areaTotals;
  };
}
  