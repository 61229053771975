import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { AppState } from "../../store/state";
import { QuizResultComponent } from "./quiz-result";
import { getQuizResultData, quizStart, postContactDesignerData, updateQuizResultData, setCountry, getQuizResultPageData } from "../../redux/actions";

const mapStateToProps = (state: AppState) => {
  return {
    countryCode : state.quiz.countryCode,
    spaceQuizData : state.quiz.spaceQuizData,
    designQuizData : state.quiz.designQuizData,
    quizResultData : state.quiz.quizResultData,
    profileData : state.quiz.profileData,
    costEstimateData:state.quiz.costEstimateData,
    locationData: state.quiz.locationData,
    loading:state.quiz.loading,
    errorType : state.quiz.errorType
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getQuizResultPageData : (quizId:string) => dispatch(getQuizResultPageData(quizId)),
    getQuizResultData : (quizId:string) => dispatch(getQuizResultData(quizId)),
    quizStart : () => dispatch(quizStart()),
    postContactDesignerData: (contactDesignerData:any) => dispatch(postContactDesignerData(contactDesignerData)),
    updateQuizResultData: (quizId:string, quizResultData:any) => dispatch(updateQuizResultData(quizId, quizResultData)),
    setCountry : (countryCode: string, languageCode: string) => dispatch(setCountry(countryCode, languageCode)),
  };
};

export const QuizResultContainer = injectIntl(connect(mapStateToProps, mapDispatchToProps)(QuizResultComponent));