import React from "react";
import { WrappedComponentProps } from "react-intl";
import "./quiz-result.scss";
import messages from "../../translations/messages";
import { Link } from "react-router-dom";
import routes from "../route/routes";
import ReactPlayer from "react-player";
import { Footer } from "../common/footer/footer";
import { isUUID } from "../../helper/helper";
import { ModalpopUp } from "../common/modal-popup/modal-popup";
import {Loader} from "../common/Loader/Loader";
import { YourProgram } from "../common/your-program/your-program";
import { YourCost } from "../common/your-cost-estimate/your-cost-estimate";
import { SendResultPopup } from "../common/result-popup/result-popup";
import share from "../../assets/icon/share.png";
import email from "../../assets/icon/email.png";
import{PopupStyles} from "../common/popup-styles/popup-styles";
import { AppErrors, countries } from "../../models/enums";
import appConfig from "../../helper/app-config";

const config = appConfig();

interface QuizResultComponentProps extends WrappedComponentProps {
  countryCode: string;
  quizResultData: any;
  profileData: any;
  costEstimateData:any;
  locationData:any;
  getQuizResultData(quizId:string): void;
  getQuizResultPageData(quizId:string): void;
  quizStart(): void;
  history: any;
  match: any;
  location: any;
  postContactDesignerData(contactDesignerData:any): void;
  updateQuizResultData(quizId:string, quizResultData:any): void;
  setCountry(countryCode: string, languageCode: string): void;
  loading:any;
  errorType : string;
  windowWidth: any;
}
export class QuizResultComponent extends React.Component<QuizResultComponentProps> {
  state = {
    videoURL : 'https://vimeo.com/439379884/4be122f7f2',
    controls : true,
    playing: true,
    isOpenModal:false,
    quizId:'',
    isOpenSendResultPopup:false,
    programData: {} as any,
    headCount: 0,
    windowWidth: 0,
    downloadPDFEnabled: false
  }
  public constructor(props: QuizResultComponentProps) {
    super(props);
    this.state.windowWidth = window.innerWidth;
  }
  handleResize = () =>{
    this.setState({ windowWidth : window.innerWidth});
  }  
  componentDidMount() {
    if(this.props.match.params.quizId) {
      const quizId = this.props.match.params.quizId;
      if(isUUID(quizId)) {
        this.props.getQuizResultPageData(quizId);
        this.setState({quizId: quizId});
      } else {
        this.props.history.push(routes.officeReadyError);
      }
    }
    window.addEventListener("resize", this.handleResize );
    localStorage.removeItem('allowDesignPalette');
  }
  componentDidUpdate(){
    if(this.props.countryCode !== this.props.quizResultData.result?.countryCode){
      const _country = countries.find((item) => {
        return item.countryCode === this.props.quizResultData.result?.countryCode
      });
      if(_country !== undefined) {
        const _lang =  _country.languages.find((language:any) => {return language.default });
        this.props.setCountry(_country.countryCode, _lang.languageCode);
        document.body.className = '';
        document.documentElement.lang = _lang.languageCode;
        document.body.classList.add("lang_" + _lang.languageCode);
      }  
    }
  }
  componentWillUnmount(){
    window.addEventListener("resize", this.handleResize );
  }  
  palletteLoaded = () => {
    this.setState({downloadPDFEnabled: true});
  }
  retakeQuiz = () => {
    this.props.quizStart();
    this.props.history.push(routes.quiz, {email: this.props.quizResultData.user.email});
  }
  onPopupClose = () => {
    this.setState({ isOpenModal: false });
    document.body.classList.remove('overflow-hidden');
  }
  onSendResultPopupClose = () => {
    this.setState({ isOpenSendResultPopup: false });
  }
  onQuizExit = () => {
    this.props.history.push('/');
  }
  onSendResultPopupExit = () => {
    this.props.history.push('/');
  }
  contactCbreDesigner = () => {
    this.setState({ isOpenModal: true });
    document.body.classList.add('overflow-hidden');
  }
  SendResultPopup = () => {
    this.setState({ isOpenSendResultPopup: true });
  }
  scrolltotop=()=> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.setState({ isOpenSendResultPopup: true });
  }
  postContactDesignerData = (postContactDesignerData: any) => {
    this.props.postContactDesignerData(postContactDesignerData)
  }
  updateQuizResultData = (headCount:number, totalSF: number) => {
    if(this.state.quizId !== '') {
      let resultData = this.props.quizResultData.result;
      resultData.headCount = headCount;
      resultData.totalSF = totalSF;
      this.props.updateQuizResultData( this.state.quizId, resultData);
      this.setState({headCount: headCount});
      setTimeout(() => { this.props.getQuizResultData(this.state.quizId);}, 2000);
    }
  }
  openDownloadPDFDialog = () => {
    const data = {
      countryCode: this.props.countryCode,
      quizId: this.state.quizId,
      fname: this.props.quizResultData.user.fname,
      design: this.props.profileData.design,
      space: this.props.profileData.space,
      costEstimateData: this.props.costEstimateData,
      locationData: this.props.locationData,
      officeLocation: this.props.quizResultData.result?.officeLocation,
      programResult: this.state.programData
    }
    this.props.history.push(routes.resultPDF,{resultData: data})
  }
  getProgramData = (programData: any) => {
    this.setState({programData : programData});
  }
  handleExpandCollaps = (name: any) => {
    var element = document.getElementById(name);
    if(element !== null && element !== undefined) {
     if (element.classList.contains("item-active")) {
         element.classList.remove("item-active");
         } else  {
         element.classList.add("item-active");
     }
   }
 }
  public render() {
    if(this.props.errorType !== '' && this.props.errorType === AppErrors.GET_QUIZ_RESULT_API_ERROR) {
      this.props.history.push('/office-ready-error');
    }
    const _headcount = (this.state.headCount !== 0 && this.state.headCount !== this.props.quizResultData.result?.headCount) ? this.state.headCount : this.props.quizResultData.result?.headCount;
    return (
      <>
        <div className="quiz-result-header">
            <Link to={routes.home} className="cbre_home_logo">
              <strong>{this.props.intl.formatMessage(messages["global.CBRE"])}</strong> {this.props.intl.formatMessage(messages["global.officeReady"])}
            </Link>
            <div className="header-links">
              <a className={`primary mdc-button mdc-button--outlined custom-outlined-button mdc-ripple-upgraded ${this.state.downloadPDFEnabled ? "":"disabled"}` } data-test="open-pdf" onClick={()=> {this.state.downloadPDFEnabled && this.openDownloadPDFDialog()}}>{this.props.intl.formatMessage(messages["quizResult.header.downloadPDF"])}</a>
              <a className="primary mdc-button mdc-button--outlined custom-outlined-button mdc-ripple-upgraded" data-test="open-sendResultPopup" onClick={this.SendResultPopup}>{this.props.intl.formatMessage(messages["quizResult.header.sendResult"])}</a>
              <button onClick={this.contactCbreDesigner} className="primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded">{this.props.intl.formatMessage(messages["quizResult.header.reviewWithCBRE"])}</button>
            </div>
            {this.state.isOpenModal && <ModalpopUp quizId={this.props.match.params.quizId} user={this.props.quizResultData.user} calculatedResultData={this.props.quizResultData.result} headCount={_headcount} cbreProfessionalEmail={this.props.quizResultData.cbreProfessionalEmail} leadCreation={this.props.quizResultData.leadCreation} onPopupClose={this.onPopupClose} onQuizExit={this.onQuizExit} postContactDesignerData={this.postContactDesignerData} />}
            {this.state.isOpenSendResultPopup  && <SendResultPopup quizId={this.props.match.params.quizId} onSendResultPopupClose={this.onSendResultPopupClose} onSendResultPopupExit={this.onSendResultPopupExit} />}
        </div>
        <div className="retake-quiz">
            <a data-test="btn-link" onClick={this.retakeQuiz}>
              {this.props.intl.formatMessage(messages["quizResult.header.retakeQuiz"])}
            </a>
          </div>
          <div className="header-links-mobile">
              <a onClick={this.SendResultPopup}><img src={share} /></a>
              <a onClick={this.contactCbreDesigner}><img src={email} /></a>              
            </div>
        {(this.props.quizResultData && this.props.profileData?.design?.profileVideo)==null 
          ? <Loader/> 
          : <div className="quiz-result-container">
            <div className="profile-section">
          <div className="quiz-result-heading">
          { this.props.quizResultData.user?.fname + this.props.intl.formatMessage(messages["quizResult.heading"]) }
          </div>
        {this.props.countryCode === 'SG' 
          ? <div className="iframe_container">            
              <div className="thumbnail_container" style={{ backgroundImage:`url(${this.props.profileData?.design?.thumbnail ? config.REACT_APP_S3 + atob(this.props.profileData?.design?.thumbnail) : ''})` }}>
                <div className="play_icon">
                    <a href={this.props.profileData?.design?.profileVideo ? atob(this.props.profileData?.design?.profileVideo) : ''}  target="_blank" rel="noopener noreferrer">&nbsp;</a>
                </div>
              </div>
              <p>{this.props.intl.formatMessage(messages["quizResult.designProfile.videoDescription"])}</p>
            </div>           
          : <div className="profile-video">
              <ReactPlayer playsinline={true} url={this.props.profileData?.design?.profileVideo ? atob(this.props.profileData?.design?.profileVideo) : ''} controls={this.state.controls}
                width='100%' height='100%' light={this.props.profileData?.design?.thumbnail ? config.REACT_APP_S3 + atob(this.props.profileData?.design?.thumbnail): true} 
                playing={this.state.playing}/>
            </div>
         }
          <div className="profile-data">
            <div className="profile left">
              <div className="profile-heading">
              { this.props.intl.formatMessage(messages["quizResult.designProfile.heading"]) + this.props.profileData?.design?.profileName}
              </div>
              <div className="profile-desc">
              { this.props.profileData?.design?.profileDescription.split('\n\n')[0] }    
              <div className="mtop20">{ this.props.profileData?.design?.profileDescription.split('\n\n')[1] }</div>   
              </div>
            </div>
            <div className="profile right">
              <div className="profile-heading">
              { this.props.intl.formatMessage(messages["quizResult.spaceProfile.heading"]) + this.props.profileData?.space?.profileName }
              </div>
              <div className="profile-desc">
              { this.props.profileData?.space?.profileDescription.split('\n\n')[0] }
              <div className="mtop20">{ this.props.profileData?.space?.profileDescription.split('\n\n')[1] }</div>   
              </div>
            </div>
            </div>
          </div>
          <div className="section-container">
          <div className="section nav-menu-item item-active" id="yourProgram" >
            <div className="nav-menu-item-head heading" onClick={() => this.handleExpandCollaps("yourProgram")}>
            { this.props.intl.formatMessage(messages["quizResult.actionLink.yourProgram"])}
            </div>
            <div className="nav-menu-item-body">
            { this.props.profileData.space && this.props.quizResultData.result
              ?
              <YourProgram kitOfParts={this.props.profileData.space?.kitOfParts}
                spaceProfile={this.props.quizResultData.result.spaceProfile}
                headCount={this.props.quizResultData.result.headCount}
                daysInOffice = {this.props.quizResultData.result.daysInOffice}
                officeDensity = {this.props.quizResultData.result.officeDensity}
                countryCode= {this.props.countryCode}
                updateQuizResultData = {this.updateQuizResultData}
                getProgramData = {this.getProgramData}/>
              : <></>
            }
            </div>
          </div>
          </div>
          <div className="section-container">
          <div className={`section nav-menu-item ${this.state.windowWidth>500 ? 'item-active' : ''}  `} id="yourPalette" >
            <div className="nav-menu-item-head heading" onClick={() => this.handleExpandCollaps("yourPalette")}>
            { this.props.intl.formatMessage(messages["quizResult.actionLink.yourPalette"])}
            </div>
            <div className="nav-menu-item-body">
              { this.props.profileData?.design?.pallete &&
              <div className="palette-div" id='paletteDiv'><img onLoad={this.palletteLoaded} src={config.REACT_APP_S3 + atob(this.props.profileData?.design?.pallete)} alt="" className="img" /> </div>}
            </div>
          </div>
          </div>
          <div className="section-container">
          <div className={`section nav-menu-item ${this.state.windowWidth>500 ? 'item-active' : ''}`} id="yourCostEstimate" >
            <div className="nav-menu-item-head heading" onClick={() => this.handleExpandCollaps("yourCostEstimate")} >
            { this.props.intl.formatMessage(messages["quizResult.actionLink.yourCostEstimate"])}
            </div>
            <div className="nav-menu-item-body">
             { this.props.costEstimateData && 
              <YourCost countryCode={this.props.countryCode} costEstimateData={this.props.costEstimateData } locationData={this.props.locationData} 
              officeLocation={this.props.quizResultData.result?.officeLocation} totalSF={this.state.programData?.areaTotals?.totalSF} designProfileName={this.props.profileData?.design?.profileName}/> }
            </div>
          </div>
          </div>
          {this.props.countryCode === 'SG' &&
           <div className="section-container">
           <div className={`section nav-menu-item ${this.state.windowWidth>500 ? 'item-active' : ''}`} id="nextStep" >
             <div className="nav-menu-item-head heading" onClick={() => this.handleExpandCollaps("nextStep")} >
             { this.props.intl.formatMessage(messages["quizResult.actionLink.nextStep"])}
             </div>
             <div className="nav-menu-item-body">
               <div className="next_step_box">
              <div>
                <p>{this.props.intl.formatMessage(messages["quizResult.nextStep.desc1"])}</p>
                <p>{this.props.intl.formatMessage(messages["quizResult.nextStep.desc2"])}</p>
              </div>
              <div className="tile-section">
              <div className="tile-container">
                <div className="tilestack">
                <div className="tile" onClick={this.contactCbreDesigner}>
                    <div className="tile-image-wrap">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                      <path d="M24 12C24 10.9 23.1 10 22 10H11.64L12.96 3.64L13 3.18C13 2.56 12.74 2 12.34 1.58L10.76 0L0.88 9.88C0.34 10.42 0 11.18 0 12V25C0 26.66 1.34 28 3 28H16.5C17.74 28 18.8 27.24 19.26 26.18L23.78 15.6C23.92 15.26 24 14.88 24 14.5V12ZM20 14.26L15.84 24H4V12.42L7.86 8.56L6.72 14H20V14.26ZM45 20H31.5C30.26 20 29.2 20.76 28.74 21.82L24.22 32.4C24.08 32.74 24 33.12 24 33.5V36C24 37.1 24.9 38 26 38H36.36L35.04 44.36L35 44.84C35 45.46 35.26 46.02 35.66 46.44L37.24 48L47.12 38.12C47.66 37.58 48 36.82 48 36V23C48 21.34 46.66 20 45 20ZM44 35.58L40.14 39.44L41.28 34H28V33.74L32.16 24H44V35.58Z" fill="#003F2D"/>
                    </svg>
                    </div>
                    <div className="tile-text"> {this.props.intl.formatMessage(messages["quizResult.header.reviewWithCBRE"])}</div>
                  </div>   
                  </div>
                  </div>
              </div>
              </div> 
             </div>
           </div>
           </div>          
          }
        <div className="section-container">
        <div className={`section nav-menu-item ${this.state.windowWidth>500 ? 'item-active' : ''}`} id="additionalResources">
            <div className="nav-menu-item-head heading" onClick={() => this.handleExpandCollaps("additionalResources")}>
            { this.props.intl.formatMessage(messages["quizResult.actionLink.additionalResources"])}
            </div>
          <div className="nav-menu-item-body">
            <div className="tile-section">
              <div className="tile-container">
                <div className="tilestack">
                { this.state.windowWidth>500 &&
                  <div className={`tile ${this.state.downloadPDFEnabled ? "":"disabled"}`} onClick={()=> {this.state.downloadPDFEnabled && this.openDownloadPDFDialog()}}>
                    <div className="tile-image-wrap">                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32" fill="none">
                      <path d="M 28 22 L 28 28 L 4 28 L 4 22 L 0 22 L 0 28 C 0 30.199219 1.800781 32 4 32 L 28 32 C 30.199219 32 32 30.199219 32 28 L 32 22 Z M 26 14 L 23.179688 11.179688 L 18 16.339844 L 18 0 L 14 0 L 14 16.339844 L 8.820312 11.179688 L 6 14 L 16 24 Z M 26 14 " fill="#003F2D"/>
                    </svg>
                    </div>
                    <div className="tile-text"> {this.props.intl.formatMessage(messages["quizResult.header.downloadPDF"])}</div>
                  </div>
                    }
                  <div data-test="scrolltop" className="tile" onClick={this.scrolltotop}>
                    <div className="tile-image-wrap">
                      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="39" viewBox="0 0 35 39" fill="none">
                        <path d="M28.75 27.2242C27.325 27.2242 26.05 27.7867 25.075 28.668L11.7062 20.8867C11.8 20.4555 11.875 20.0242 11.875 19.5742C11.875 19.1242 11.8 18.693 11.7062 18.2617L24.925 10.5555C25.9375 11.493 27.2687 12.0742 28.75 12.0742C31.8625 12.0742 34.375 9.56172 34.375 6.44922C34.375 3.33672 31.8625 0.824219 28.75 0.824219C25.6375 0.824219 23.125 3.33672 23.125 6.44922C23.125 6.89922 23.2 7.33047 23.2938 7.76172L10.075 15.468C9.0625 14.5305 7.73125 13.9492 6.25 13.9492C3.1375 13.9492 0.625 16.4617 0.625 19.5742C0.625 22.6867 3.1375 25.1992 6.25 25.1992C7.73125 25.1992 9.0625 24.618 10.075 23.6805L23.425 31.4805C23.3312 31.8742 23.275 32.2867 23.275 32.6992C23.275 35.718 25.7313 38.1742 28.75 38.1742C31.7687 38.1742 34.225 35.718 34.225 32.6992C34.225 29.6805 31.7687 27.2242 28.75 27.2242ZM28.75 4.57422C29.7812 4.57422 30.625 5.41797 30.625 6.44922C30.625 7.48047 29.7812 8.32422 28.75 8.32422C27.7188 8.32422 26.875 7.48047 26.875 6.44922C26.875 5.41797 27.7188 4.57422 28.75 4.57422ZM6.25 21.4492C5.21875 21.4492 4.375 20.6055 4.375 19.5742C4.375 18.543 5.21875 17.6992 6.25 17.6992C7.28125 17.6992 8.125 18.543 8.125 19.5742C8.125 20.6055 7.28125 21.4492 6.25 21.4492ZM28.75 34.6117C27.7188 34.6117 26.875 33.768 26.875 32.7367C26.875 31.7055 27.7188 30.8617 28.75 30.8617C29.7812 30.8617 30.625 31.7055 30.625 32.7367C30.625 33.768 29.7812 34.6117 28.75 34.6117Z" fill="#003F2D"/>
                      </svg>
                    </div>
                    <div className="tile-text"> {this.props.intl.formatMessage(messages["quizResult.header.sendResult"])}</div>
                  </div>
                  { this.props.countryCode !== 'SG' &&
                    <div className="tile" onClick={this.contactCbreDesigner}>
                      <div className="tile-image-wrap">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <path d="M24 12C24 10.9 23.1 10 22 10H11.64L12.96 3.64L13 3.18C13 2.56 12.74 2 12.34 1.58L10.76 0L0.88 9.88C0.34 10.42 0 11.18 0 12V25C0 26.66 1.34 28 3 28H16.5C17.74 28 18.8 27.24 19.26 26.18L23.78 15.6C23.92 15.26 24 14.88 24 14.5V12ZM20 14.26L15.84 24H4V12.42L7.86 8.56L6.72 14H20V14.26ZM45 20H31.5C30.26 20 29.2 20.76 28.74 21.82L24.22 32.4C24.08 32.74 24 33.12 24 33.5V36C24 37.1 24.9 38 26 38H36.36L35.04 44.36L35 44.84C35 45.46 35.26 46.02 35.66 46.44L37.24 48L47.12 38.12C47.66 37.58 48 36.82 48 36V23C48 21.34 46.66 20 45 20ZM44 35.58L40.14 39.44L41.28 34H28V33.74L32.16 24H44V35.58Z" fill="#003F2D"/>
                        </svg>
                      </div>
                      <div className="tile-text"> {this.props.intl.formatMessage(messages["quizResult.header.reviewWithCBRE"])}</div>
                    </div>
                  }
                  </div>
              </div>
            </div>
          </div>
      </div>
      </div>
      <div className="section-container">
        <div className={`section nav-menu-item ${this.state.windowWidth>500 ? 'item-active' : ''}`} id="styles" >
          <div className="nav-menu-item-head heading" onClick={() => this.handleExpandCollaps("styles")}>
            { this.props.intl.formatMessage(messages["quizResult.actionLink.styles"])}
          </div>
          <div className="nav-menu-item-body">
            {this.props.profileData && <PopupStyles others={this.props.profileData.others} countryCode={this.props.countryCode} />}
          </div>
          </div>
        </div>
      </div>
      }
      <Footer isDisabled={true}/>
      </>
    );
  }
}