import * as React from "react";
import "./Loader.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";

interface LoaderProps extends WrappedComponentProps {

}
class LoaderComponent extends React.PureComponent<LoaderProps> {

  public constructor(props: any) {
    super(props);
  }

  public render() {
    return (
       <div className="loader"></div>
    )
  }
}

export const Loader = injectIntl(LoaderComponent);

