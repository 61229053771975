import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import { AppState } from "../../../store/state";
import { getLocationList } from "../../../redux/actions";
import "./locationdropdown.scss"
import Select from "react-dropdown-select";


interface LocationDropdownProps extends WrappedComponentProps {
  countryCode: string;
  question: any;
  answerSelected: any;
  selectOption(event: any, questionId: any, questionType: any): void;
  getLocationList(countryCode: string): void;
  locationData: any;
}

class LocationDropdownComponent extends React.Component<LocationDropdownProps> {
  state = {
    selectedOptValues: { label: '' },
    eventType: '',
    searchText: '',

  }  
  constructor(props: LocationDropdownProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state.selectedOptValues = this.props.answerSelected?.optionId && { label: this.props.answerSelected.optionId } as any;
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.props.getLocationList(this.props.countryCode)    
  }

  handleKeyDownFn(){
    const dd = document.querySelector("div.locationdropdown-container > div.react-dropdown-select-type-single > span") as HTMLElement;
    const dd1 = document.querySelector('div.locationdropdown-container > div.react-dropdown-select-type-single > input:not([value=""])') as HTMLElement;
    if (dd1) dd.style.display = 'none';    
  }

  /* istanbul ignore next */
  handleChange = (event: any) => {
    const selectedOptValues = event[0];
    if(selectedOptValues !== undefined ) {
      this.setState({ selectedOptValues }, () => { this.onDropdownClose(); });
      this.props.selectOption(selectedOptValues.label, this.props.question.questionId, this.props.question.questionType);
    }    
  }

  /* istanbul ignore next */
  onUnload = (e: any) => {
    this.setState({
       searchText: e.target.defaultValue,
       eventType: e.type
    })
  }
  
  /* istanbul ignore next */
  onDropdownOpen = () => {
    const dd = document.querySelector("div.locationdropdown-container > div.react-dropdown-select-type-single > span") as HTMLElement;
    const dd1 = document.querySelector('div.locationdropdown-container > div.react-dropdown-select-type-single > input:not([value=""])') as HTMLElement;
    if (dd1) dd.style.display = 'none';
  }

  /* istanbul ignore next */
  onDropdownClose = () => {    
    if (this.state.selectedOptValues && this.state.selectedOptValues.label) {
       const dd = document.querySelector("div.locationdropdown-container > div.react-dropdown-select-type-single > span") as HTMLElement;
       if (dd) dd.style.display = 'flex';
    }
  }

  render() {

      const locationDataDisplay = this.props.locationData.data && this.props.locationData.data?.map((data: any, index: any) => {
      return this.props.countryCode === 'US' ? { label: data.city + ", " + data.division + ", " + data.country } : { label: data.city }
    });

    const text = this.props.question.text.split('___');
    const note = this.props.question.sentence;
    return (
      <>
        <div className="question-container">
          <span>{text[0]}</span>
          <div className={`dropdown-div-suggest ${text[1].length > 1 ? '' : 'dotContent'}`}>
            <Select
              className="locationdropdown-container"
              options={ locationDataDisplay }
              dropdownHeight="150px"
              placeholder={this.props.intl.formatMessage(messages["location.placeholder"])}
              values={this.state.selectedOptValues?.label ? [this.state.selectedOptValues] : []}
              onChange={this.handleChange}
              onDropdownOpen={this.onDropdownOpen}
              onDropdownClose={this.onDropdownClose}
              handleKeyDownFn={this.handleKeyDownFn} />
              {note  && 
                <div className="city_hint">{note}</div>
                 }

          </div>
          { text[1].length > 1 && <span className="location-dot">{text[1]}</span> }
        </div>        
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    countryCode: state.quiz.countryCode,
    locationData: state.quiz.locationData,
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLocationList: (countryCode: string) => dispatch(getLocationList(countryCode)),
  };
};

export const LocationDropdown = injectIntl(connect(mapStateToProps, mapDispatchToProps)(LocationDropdownComponent));
