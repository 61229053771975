
const appConfig = () => {
 
  let config = {
    REACT_APP_BASE_API_URL: process.env.REACT_APP_BASE_API_URL || '',
    REACT_APP_BASE_APP_URL : process.env.REACT_APP_BASE_APP_URL || '',
    REACT_APP_LOCATION_API_URL: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?category=City&f=json&text=',
    REACT_APP_COUNTRIES: process.env.REACT_APP_BASE_COUNTRIES || '',
    REACT_APP_S3: process.env.REACT_APP_S3 || '',
    REACT_APP_PALETTE_PASSCODE: process.env.REACT_APP_PALETTE_PASSCODE || '',
  }
  /* istanbul ignore next */
  if(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    config.REACT_APP_BASE_API_URL = 'https://devapi.pjm-np.cbre.com/officeready/v1/';
    config.REACT_APP_BASE_APP_URL = 'https://devui.officeready-np.cbre.com';
    config.REACT_APP_COUNTRIES = 'US,ES,SG,UK,FR';
    config.REACT_APP_S3 = 'https://s3.amazonaws.com/gws-officeready-staticdata/';
    config.REACT_APP_PALETTE_PASSCODE = process.env.REACT_APP_PALETTE_PASSCODE || '';
  }  
  return config;
};

export default appConfig;
