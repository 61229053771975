import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./design-palette-detail.scss";
import profileDetailData from "./design-palette-data.json";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { Footer } from "../common/footer/footer";
import { connect } from "react-redux";
import appConfig from "../../helper/app-config";
import "../common/modal-popup/modal-popup.scss";
import { PageModal } from "../common/page-modal/pageModal";

const config = appConfig();

interface DesignPalettePageProps extends WrappedComponentProps {
    pageName: any;
    isOpenModal:false;
    palette:string;
}

class DesignPalettePage extends React.PureComponent<DesignPalettePageProps> {
    
    state = {
        pageName: '',
        passcode: '',
        errorMessage: '',
        authenticated: false,
        isOpenModal:false,
        palette:'',
    };

    public constructor(props: DesignPalettePageProps) {
        super(props);

        this.onPasscodeChange = this.onPasscodeChange.bind(this);
        this.onPasscodeCheck = this.onPasscodeCheck.bind(this);
        this.onProfileclick = this.onProfileclick.bind(this);
    }

    componentDidMount() {
        const profile = this.props.pageName;
        this.setState({ pageName: profile });
        if(localStorage.getItem('allowDesignPalette') === 'true'){
            this.setState({ authenticated: true });
        }        
    }
    componentWillUnmount(){
        document.body.classList.remove('modalOpen2');
    }

    onProfileclick = (profile: any) => {
        this.setState({ pageName: profile });
        const mainContainer = document.querySelector('.MuiPaper-root');
        if(mainContainer !=null) {
            mainContainer.scrollTop = 0;
        }
    }

    onPasscodeChange = (event: any) => {
        this.setState({ passcode: event.target.value, errorMessage: '' });
    }

    onPasscodeCheck = () => {
        if(this.state.passcode !== '' && config.REACT_APP_PALETTE_PASSCODE === this.state.passcode) {
            this.setState({authenticated: true});
            localStorage.setItem('allowDesignPalette','true');
        } else {
            this.setState({ errorMessage: 'Please enter valid passcode' })
        }
    }
    
    handleModalOpen = () => 
    {
        this.setState({isOpenModal:true});
        this.setState({palette:'wellnessPage'});
        document.body.classList.add('modalOpen');
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    };

    handleModalClose = () => {
       this.setState({isOpenModal:false});
       document.body.classList.remove('modalOpen');
    };
   
    public render() {
        const pData: any = profileDetailData;
        let profileData: any = null
        if (this.state?.pageName !== '') {
            profileData = pData[this.state.pageName]
        }
        const items = [
            <div className="item" data-value="1">
                <img src={config.REACT_APP_S3 + `${profileData?.gallery.img1}`} alt="" />
                <div className="Overlay">{`${profileData?.name}`}<span>Reception</span></div>
            </div>,
            <div className="item" data-value="2">
                <img src={config.REACT_APP_S3 + `${profileData?.gallery.img2}`} alt="" />
                <div className="Overlay">{`${profileData?.name}`}<span>Work Space</span></div>
            </div>,
            <div className="item" data-value="3">
                <img src={config.REACT_APP_S3 + `${profileData?.gallery.img3}`} alt="" />
                <div className="Overlay">{`${profileData?.name}`}<span>Collaboration Space</span></div>
            </div>,
            <div className="item" data-value="4">
                <img src={config.REACT_APP_S3 + `${profileData?.gallery.img4}`} alt="" />
                <div className="Overlay">{`${profileData?.name}`}<span>Meeting space</span></div>
            </div>,
            <div className="item" data-value="5">
                <img src={config.REACT_APP_S3 + `${profileData?.gallery.img5}`} alt="" />
                <div className="Overlay">{`${profileData?.name}`}<span>Pantry</span></div>
            </div>,
        ];
        const items2 = [
            <div className="item" data-value="1">
                <img src={config.REACT_APP_S3 + `${profileData?.gallery.img1}`} alt="" />
                <div className="Overlay">{`${profileData?.name}`}<span>Reception</span></div>
            </div>,
            <div className="item" data-value="2">
                <img src={config.REACT_APP_S3 + `${profileData?.gallery.img2}`} alt="" />
                <div className="Overlay">{`${profileData?.name}`}<span>Work Space</span></div>
            </div>,
            <div className="item" data-value="3">
                <img src={config.REACT_APP_S3 + `${profileData?.gallery.img3}`} alt="" />
                <div className="Overlay">{`${profileData?.name}`}<span>Collaboration Space</span></div>
            </div>,
            <div className="item" data-value="4">
                <img src={config.REACT_APP_S3 + `${profileData?.gallery.img4}`} alt="" />
                <div className="Overlay">{`${profileData?.name}`}<span>Pantry</span></div>
            </div>,
        ];
        return (
            this.state.authenticated ?
                <>  
                    <div className="design_palette">
                        <div className="top-content">
                            <div className="img-container" style={{ backgroundImage: `url(${config.REACT_APP_S3}${profileData?.headerBg})` }}>
                                <div className="text-content">
                                    <div className="div-text">
                                        <div className="text">
                                            {pData?.bannerHeading1}
                                        </div>
                                        <div className="mtop10 mlef20 text">
                                            {pData?.bannerHeading2}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="brand-info">
                            <div className="heading">{profileData?.name}​</div>
                            <div className="desc">{profileData?.rate}​</div>
                        </div>
                        <div className="main-content">
                            <div className="section bgwhite">
                                <div className="div-content">
                                    <div className="div-text-center">
                                        <div className="heading">{profileData?.section1Txt}
                                        </div>
                                        <div className="text mtop20">
                                            {profileData?.section1Txt2}
                                        </div>
                                        <div className="text mtop20">
                                            {profileData?.section1Txt3}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section bggreen">
                                <div className="div-content ">
                                    <div className="div-text">
                                        <div className="heading">
                                            {profileData?.name}
                                        </div>
                                        <div className="text">
                                            {profileData?.section2.title}
                                        </div>
                                    </div>
                                    <div className="div-img">
                                        <img src={config.REACT_APP_S3 + `${profileData?.section2.img}`} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="section bgwhite">
                                <div className="div-content ">
                                    <AliceCarousel
                                        mouseTracking
                                        items={profileData?.name === 'ELEGANT' ? items2 : items}
                                        disableDotsControls={true}
                                        controlsStrategy="alternate"
                                    />
                                </div>
                            </div>
                            <div className="section bggreen col_rev">
                                <div className="div-content ">
                                    <div className="div-img">
                                        <img src={config.REACT_APP_S3 + `${profileData?.section3.img}`} alt="" />
                                    </div>
                                    <div className="div-text">
                                        <div className="heading">
                                            {profileData?.name}
                                        </div>
                                        <div className="text">
                                            {profileData?.section3.title}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section bgWhite">
                                <div className="div-content ">
                                    <div className="div-text">
                                        <div className="heading">
                                            {profileData?.name}
                                        </div>
                                        <div className="text">
                                            {profileData?.section4.title}
                                        </div>
                                    </div>
                                    <div className="div-img">
                                        <img src={config.REACT_APP_S3 + `${profileData?.section4.img}`} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="section bggreen col_rev">
                                <div className="div-content ">
                                    <div className="div-img">
                                        <img src={config.REACT_APP_S3 + `${profileData?.section5.img}`} alt="" />
                                    </div>
                                    <div className="div-text">
                                        <div className="heading">
                                            {profileData?.name}
                                        </div>
                                        <div className="text">
                                            {profileData?.section5.title}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section bgWhite">
                                <div className="div-content ">
                                    <div className="div-text">
                                        <div className="heading">
                                            {profileData?.name}
                                        </div>
                                        <div className="text">
                                            {profileData?.section6.title}
                                        </div>
                                    </div>
                                    <div className="div-img">
                                        <img src={config.REACT_APP_S3 + `${profileData?.section6.img}`} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="section bggreen">
                                <div className="div-content ">
                                    <div className="iframe_container">            
                                        <div className="thumbnail_container" style={{ backgroundImage:`url(${config.REACT_APP_S3}${profileData?.videoBg})` }}>
                                            <div className="play_icon">
                                                <a href={profileData?.videoURL}  target="_blank" rel="noopener noreferrer">&nbsp;</a>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div className="section bgWhite">
                                <div className="div-content other_palletes">
                                    <div className="div-text-center">
                                        <div className="heading mbot40">{pData?.otherPallete}​</div>
                                    </div>
                                    <div className="other-palletes">
                                        <div className="cursorp" onClick={() => this.onProfileclick(`${profileData?.otherPallete.pallete1.name.toUpperCase()}`)}>
                                            <p className="heading essential">{profileData?.otherPallete.pallete1.name}</p>
                                            <img src={config.REACT_APP_S3 + `${profileData?.otherPallete.pallete1.img}`} alt="" />
                                        </div>
                                        <div className="cursorp" onClick={() => this.onProfileclick(`${profileData?.otherPallete.pallete2.name.toUpperCase()}`)}>
                                            <p className="heading expressive">{profileData?.otherPallete.pallete2.name}</p>
                                            <img src={config.REACT_APP_S3 + `${profileData?.otherPallete.pallete2.img}`} alt="" />
                                        </div>
                                        <div className="cursorp" onClick={() => this.onProfileclick(`${profileData?.otherPallete.pallete3.name.toUpperCase()}`)}>
                                            <p className="heading elegant">{profileData?.otherPallete.pallete3.name}</p>
                                            <img src={config.REACT_APP_S3 + `${profileData?.otherPallete.pallete3.img}`} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="full_width_container first_container" style={{ backgroundImage: `url(${config.REACT_APP_S3}${pData?.welnessImg})` }}>
                                <a onClick={this.handleModalOpen} className="btn_white cp">THE WELLNESS FACTOR</a>
                            </div>
                            <div className="full_width_container mar_btm60" style={{ backgroundImage: `url(${config.REACT_APP_S3}${pData?.designApproacgImg})` }}>
                                <a onClick={()=>{this.handleModalOpen();this.setState({palette:'designPage'});}} className="btn_white cp">OUR DESIGN APPROACH</a>
                            </div>
                        </div>
                    </div>
                    <Footer isDisabled={true} />
                    
                 {this.state.isOpenModal && <PageModal pageName={this.props.pageName} handleModalClose={this.handleModalClose} isOpenModal={this.state.isOpenModal} handleModalOpen={this.handleModalOpen} palette={this.state.palette} />}
                </>
                :
                <div className="modalpopup" style={{ backgroundImage: `url(${config.REACT_APP_S3}Q1/modal_bg.jpg)` }}>
                    <div className="modal-content auth-check-div">
                        <div className="modal-detail-row">
                            <label>Enter your code</label>
                            <input className="input" type="password" onChange={this.onPasscodeChange} />
                            <div className="errorMsg">
                                {!this.state.authenticated && this.state.errorMessage}
                            </div>
                        </div>
                        <div className="popup-btn-container">
                            <button className='btn-quit primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded' onClick={this.onPasscodeCheck}>
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
        )
    }

}

const mapDispatchToProps = (dispatch: any) => {
    return {
       // setCountry: (countryCode: string, languageCode: string) => dispatch(setCountry(countryCode, languageCode)),
    };
};

export const DesignPalette = injectIntl(connect(null, mapDispatchToProps)(DesignPalettePage));