import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Close} from '@material-ui/icons';
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Wellness } from '../../wellness/wellness';
import { DesignApproach } from '../../designapproach/designapproach';
import './pageModal.scss';
import { DesignPalette } from '../../design-palette-detail/design-palette-detail';

interface PageModalProps extends WrappedComponentProps {
    handleModalClose(): void;
    handleModalOpen(): void;
    isOpenModal:any;
    palette:any;
    pageName:any;
}

class PageModalComponent extends React.PureComponent<PageModalProps> {
    state = {
        isOpenModal:false,
        palette: '',
    }
  public constructor(props: any) {    
    super(props);
  }
  getPalette = () => {
    switch (this.props.palette) {
      case "wellnessPage":
        return <Wellness {...this.props}/>;
      case "designPage":
        return <DesignApproach {...this.props} />;
      default:
        return <DesignPalette  {...this.props}/>;
    }
    
  };
  componentDidMount(){
    document.body.classList.add('modalOpen');
  }
   handleModalClose = () => {
    this.props.handleModalClose();
    document.body.classList.remove('modalOpen');
   };
   
  public render() {
    return (
        <div id="dialog">
        <Dialog data-id="closeModal" fullScreen open={this.props.isOpenModal} onClose={this.handleModalClose} >
          <AppBar >
            <Toolbar>
              <IconButton edge="end" onClick={this.handleModalClose} aria-label="close" data-id="closeID">
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          {this.getPalette()}
        </Dialog>
      </div>
    )
  }

}

export const PageModal = injectIntl(PageModalComponent);

