import * as React from "react";
import "./countrymodal.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import { countries } from "../../../models/enums";
import { connect } from "react-redux";
import { setCountry } from "../../../redux/actions";
import appConfig from "../../../helper/app-config";
import profileDetailData from "../../design-palette-detail/design-palette-data.json";
const config = appConfig();

interface CountryModalProps extends WrappedComponentProps {
    countryCode: string;
    languageCode: string;
    onModalClose(): void;
    setCountry(countryCode: string, languageCode: string): void;
}
class CountryModalComponent extends React.PureComponent<CountryModalProps> {
    state = {
        countryCode: '',
        languageCode: '',
        enableBtn: true
    }
    countryList : Array<any> = [];
    public constructor(props: any) {
        super(props);
    }

    componentDidMount(){
        this.enableCountryFromConfig();
    }
    chooseCountry = (event: any) => {
        this.setState({ enableBtn: false });
        this.setState({ countryCode: event.target.value });
    }

    onModalClose = () => {
        let _country = countries.find(x => x.countryCode === this.state.countryCode)
        if (_country) {
            const lang = _country.languages.find((l: any) => l.default);
            this.setState({ countryCode: _country.countryCode });
            this.updateCountryAndLanguage(_country.countryCode, lang.languageCode);
        }
        if (this.props.onModalClose !== null && this.props.onModalClose !== undefined) {
            this.props.onModalClose();
        }
    }

    updateCountryAndLanguage = (countryCode: string, languageCode: string) => {
        localStorage.setItem('countryCode', countryCode);
        localStorage.setItem('languageCode', languageCode);
        this.props.setCountry(countryCode, languageCode);
        document.body.className = '';
        document.documentElement.lang = languageCode;
        document.body.classList.add("lang_" + languageCode);
    }
    enableCountryFromConfig = () => {
        const allwdCountries = config.REACT_APP_COUNTRIES;
        countries.forEach((item: any) => {
          if(allwdCountries.indexOf(item.countryCode) > -1){
            this.countryList.push(item);
          }
        })
      }
    public render() {
        const pData: any = profileDetailData;
        const isEnableBtn = this.state.enableBtn;
        const _countries = this.countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        return (
            <div className="countrymodal" style={{ backgroundImage: `url(${config.REACT_APP_S3}${pData?.countrModalBg})` }}>
                <div className="modal-content">
                    <h1 className="popup-heading">
                        <div className="heading"> {this.props.intl.formatMessage(messages["countryModal.heading"])}</div>
                    </h1>
                    <div>
                        <select onChange={this.chooseCountry}>
                            <option>Please select country</option>
                            {
                                _countries?.map((country: any) => {
                                    return (
                                        <option key={country.countryCode} value={country.countryCode}>{country.countryName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="popup-btn-container" >
                        <button className="btn btn-save" data-test="continue_Btn" onClick={this.onModalClose} {...(isEnableBtn && { disabled: true })}>
                            {this.props.intl.formatMessage(messages["global.proceed"])}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setCountry: (countryCode: string, languageCode: string) => dispatch(setCountry(countryCode, languageCode))
    };
};
export const CountryModal = injectIntl(connect(null, mapDispatchToProps)(CountryModalComponent));

