export const isUUID = (uuid: any) => {
    let s = "" + uuid;
    const _res = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return _res !== null ;
}

export  const mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const  nameValidation= /^([a-zA-Z ]){1,100}$/;

export const  textFormat = /^[a-zA-Z0-9 !@#$&?%^_;:()\-`.+,"í¡á¿óáñéú]*$/;

export const formatNumber = (num: number) => {
    if(num === undefined) return '';
    return num?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export const isAlphanumeric = (text: string) => {
    const regEx = /^[a-zA-Z0-9 !@#$&?%^*_;:()\\-`.+,\"]+$/i;
    return regEx.test(text);
}

export const validateText = (event: any) =>{
    if (!textFormat.test(event.key)) {
        event.preventDefault();
    }
}