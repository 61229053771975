import * as React from "react";
import "./image-select.scss";
import { injectIntl } from "react-intl";
import { QuestionComponentProps } from "../../models/question-component-props";
import messages from "../../translations/messages";
import appConfig from "../../helper/app-config";

const config = appConfig();

class ImageSelectComponent extends React.PureComponent<QuestionComponentProps> {

    state = {
        selectedOptions : ''
    };
    public constructor(props: QuestionComponentProps) {
        super(props);
        this.state.selectedOptions = this.props.answerSelected?.optionId || '';
    }

    isActive = (optionId: any) => {
      const optionIds = this.state.selectedOptions?.split('|');
      const _result = optionIds?.filter((as: any) => { return as == optionId });
      return _result && _result.length > 0;
    }

    selectImage = (event: any) => {
        const optionId = event.currentTarget.dataset.option_id;
        let selOptions;
        if(this.props.question?.allowedChoice > 1){
            selOptions = this.selectMultiImage(optionId);
        } else {
            selOptions = optionId;
        }
        if(selOptions) {
            this.setState({ selectedOptions : selOptions });
            this.props.selectOption(selOptions , this.props.question.questionId, this.props.question.questionType);
        }
    }

    selectMultiImage = (optionId: any) => {        
        const optionIds = this.state.selectedOptions?.split('|')?.filter(r => r !== '');
        const isSelected = optionIds?.find((x)=> { return x== optionId});
        let selOptions;
        if(isSelected){
            if(this.state.selectedOptions.length > 1) {
                selOptions = optionIds.filter((x)=> {return x != optionId});
            }
        } else if (optionIds?.length < this.props.question?.allowedChoice) {
            selOptions  = [...optionIds ,optionId ];
        }
        if(selOptions) {
            return selOptions.join('|');
        }
    }

    getNumText = (number: number) => {
        switch (number) {
            case 2:
                return this.props.intl.formatMessage(messages["global.two"]);
            case 3:
                return this.props.intl.formatMessage(messages["global.three"]);
            default:
                break;
        }
        return number.toString();
    }

    public render() {
        const orderedOptions = this.props.question.options?.sort((a: any, b:any) => {return a.sequence-b.sequence});
        return (
            <>
                <div className="image-question-title">{this.props.question.text}</div>
                <div className="image-ques-sec">
                    { this.props.question?.allowedChoice > 1  ? this.props.intl.formatMessage(messages["global.chooseupto"]) + ' ' +  this.getNumText(this.props.question?.allowedChoice) + ':' : this.props.question?.sentence && `${this.props.question?.sentence}:` } 
                </div>
                <div className="img-grid">
                    {
                        orderedOptions?.map((option: any, index: number) => {
                            return (
                                <div data-option_id={option.optionId} onClick={this.selectImage}
                                    className={`img-tile ${this.isActive(option.optionId) && 'active'}`} key={index}>
                                    { this.isActive(option.optionId) && 
                                        <div className="response">
                                            <i className="icon image-icon-success"></i>
                                            <span>{option.response}</span>
                                        </div> 
                                    }
                                    <img src={config.REACT_APP_S3 + atob(option.imgData)} alt="" className="img" />
                                    { option?.caption  && 
                                        <div className="img-title">
                                            {option.caption}
                                        </div> 
                                    }
                                </div>                                
                            )
                        })
                    }                    
                </div>
            </>
        )
    }

}

export const ImageSelect = injectIntl(ImageSelectComponent);

