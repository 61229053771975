import * as React from "react";
import "./result-popup.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import appConfig from "../../../helper/app-config";
import copy from "copy-to-clipboard";
import routes from "../../route/routes";

const config = appConfig();

interface SendResultPopupProps extends WrappedComponentProps {
  onSendResultPopupClose(): void;
  onSendResultPopupExit(): void;
  quizId: '';
}
export class SendResultPopupComponent extends React.PureComponent<SendResultPopupProps> {

  state = {
    link: config.REACT_APP_BASE_APP_URL,
    copySuccess: false
  }
  
  public constructor(props: any) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.Copytext = this.Copytext.bind(this);    
  }

  node = React.createRef<HTMLDivElement>();

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  handleClick = (evnt: any) => {
    if(this.node.current?.contains(evnt.target)){
      return;
    }
    this.onSendResultPopupClose();
  }

  onSendResultPopupClose = () => {
    if (this.props.onSendResultPopupClose !== null && this.props.onSendResultPopupClose !== undefined) {
      this.props.onSendResultPopupClose();
    }
  }

  handleInputChange(e: { target: { value: any; }; }) {
    this.setState({ textToCopy: e.target.value });
  }
  
  Copytext() {
    copy(this.state.link + routes.result + '/'+ this.props.quizId);
    this.setState({copySuccess: true});
  }

  public render() {
    return (
      <div className="result-modalpopup" ref={this.node}>
        <div className="result-modal-content">
          <div className="result-close" data-test='close_icon' onClick={this.onSendResultPopupClose}>&times;</div>
          <div className="result-modal-detail-row">
            <label>{this.props.intl.formatMessage(messages["resultPopup.heading"])}<span></span></label>
            <div className="copy_input_box">
              <input className="result-input_Link" type="text" value={this.state.link + routes.result + '/'+ this.props.quizId} name="link" onChange={this.handleInputChange} />
              <button className="primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded result-copy-btn" onClick={this.Copytext}>
                {this.props.intl.formatMessage(messages["popupmodal.Copytoclipboardbutton"])}
              </button>
            </div>
            {
              this.state.copySuccess &&
              <div className="copy-message">
                {this.props.intl.formatMessage(messages["popupmodal.Copytoclipboardmessage"])}
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
export const SendResultPopup = injectIntl(SendResultPopupComponent);