import React from "react";
import './headcount.scss';
import Add from '../../../assets/icon/Add.png';
import Minus from '../../../assets/icon/Minus.png';
import { QuestionComponentProps } from "../../../models/question-component-props";
import messages from "../../../translations/messages";

class HeadcountComponent extends React.Component<QuestionComponentProps> {

  state = {
    value: '',
    placeholder:this.props.intl.formatMessage(messages["quiz.headcount.placeholderMessage"]),
  };
  
  constructor(props: QuestionComponentProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state.value = this.props.answerSelected?.optionId || '';
  }

  validateNumber = (e: any) => {
     if(e.charCode === 46 || e.charCode === 43 || e.charCode === 69 || e.charCode === 190 || e.charCode === 187 || (e.charCode > 64 && e.charCode < 91) || (e.charCode > 97 && e.charCode < 122)) {
       e.preventDefault();
       return false;
     }
  }
  
  Increase = () => {
    const _val = parseInt(this.state.value) ? (parseInt(this.state.value) + 1 ) : 1 ;
    this.setState({ value: _val });
    this.triggerSelectOption(_val);
  }

  Decrease = () => {
    const _val = parseInt(this.state.value) ? (parseInt(this.state.value) - 1) : 1 ;
    this.setState({ value: _val });
    this.triggerSelectOption(_val);
  }

  handleMouseHover = () => {
      this.setState({placeholder:''}) }
  handleMouseOut = () => {
    if( this.state.value === '' ){this.setState({placeholder:this.props.intl.formatMessage(messages["quiz.headcount.placeholderMessage"])}) }
  }
  handleChange = (event: any) => {
    const _val = parseInt(event.target.value) ? parseInt(event.target.value) : '' ;
    this.setState({ value: _val });
    this.triggerSelectOption(_val);
    if(_val===''){
      this.setState({placeholder:this.props.intl.formatMessage(messages["quiz.headcount.placeholderMessage"])});
    }
  }

  triggerSelectOption = (value : any) => {
    if(value === '' || value > 500){
      this.props.removeOption(this.props.question.questionId, this.props.question.questionType);
    } else {
      this.props.selectOption(value, this.props.question.questionId, this.props.question.questionType);
    }
  }

  render() {
    const text = this.props.question.text.split('___');
    return (
      <div className="question-container">
        <div className="hc-container">
          <span>{text[0]}</span>
          <div className="headcount-comp">
            <button onClick={this.Decrease}  className="decrease" disabled={this.state.value == '1' || this.state.value == ''}> <img src={Minus} className={`${this.state.value == '1' || this.state.value == '' ? "disabled" : "" }`}/> </button>
            <input type="number" onInput={(e:any) => e.target.value = e.target.value.slice(0, 5)} value={this.state.value}  className="headcount" placeholder={this.state.placeholder}
              onChange={this.handleChange} onKeyPress={this.validateNumber} />
             <button onClick={this.Increase}  className="increase"  onMouseOver={this.handleMouseHover} onMouseOut={this.handleMouseOut} disabled={parseInt(this.state.value) >= 500}> <img src={Add} className={`${parseInt(this.state.value) >= 500  ? "disabled" : "" }`}/> </button>
          </div>
          <span>{text[1]}</span>
        </div>
        {parseInt(this.state.value) > 500 && <div className="headcount-message">{this.props.intl.formatMessage(messages["quiz.questions.headcount"])}</div>}
        <div className="hc-hint">{this.props.question?.sentence}</div>
      </div>
    );
  }
}

export const Headcount = HeadcountComponent;