import React from "react";
import './dropdown-question.scss';
import Select from "react-dropdown-select";
import { QuestionComponentProps } from "../../../models/question-component-props";

class DropdownComponent extends React.Component<QuestionComponentProps> {

  state = {
    value: ''
  };

  constructor(props: QuestionComponentProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state.value = this.props.answerSelected?.optionId || '';
  }
   
  customStyles = {
    boxShadow: "none"   
  };

  handleChange = (event: any) => {
    const val = event[0].value;
    this.setState({ value: val });
    this.props.selectOption(val, this.props.question.questionId, this.props.question.questionType );
  }
  
  render() {
    const text = this.props.question.text.split('___');
    const options = this.props.question.options?.map((x: any) => { return { 'label': x.text, value: x.optionId, sequence: x.sequence} });
    const sortedOptions = options.sort((a: any, b:any) => {return a.sequence - b.sequence});
    const _selectedOption = options?.filter((opt:any)=> { return opt.value == this.state.value });
    const selectedOption = _selectedOption && _selectedOption.length ? _selectedOption : [{ 'label': '', value: ''}]
    const optLength = Math.max(...(options.map((el: any) => el.label.length)));
    return (
      <>
        <div className="question-container">
          <span>{text[0]}</span>
          <div className={"dropdown-div" + ' ' + "wd"+ (optLength < 5 ? '85px' :( optLength < 25 ? '175px' : '280px'))} style={{width: optLength < 5 ? '85px' :( optLength < 25 ? '175px' : '280px')}} title={"title"}>
            <Select values={selectedOption} onChange={this.handleChange}  options={sortedOptions} className="select" 
            style={this.customStyles} searchable={false} />
          </div>
          <span className={text[1].length > 1 ? 'mlef5' : ''}>{text[1]}</span>
        </div>      
      </>
    );
  }
}

export const DropdownQuestion = DropdownComponent;