import * as React from "react";
import { injectIntl } from "react-intl";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { QuestionComponentProps } from "../../../models/question-component-props";
import selected from "../../../assets/icon/selected.png";
import { isAlphanumeric, validateText } from "../../../helper/helper";
import messages from "../../../translations/messages";

class MultilineComponent extends React.PureComponent<QuestionComponentProps> {

    state = {
        showOtherTextbox: false,
        otherTextValue:'',
        selectedValue: '',
        invalidOtherText: false
    }

    public constructor(props: QuestionComponentProps) {
        super(props);
        this.getDynamicClass = this.getDynamicClass.bind(this);
        this.state.selectedValue = this.props.answerSelected?.optionId;
    }

    componentDidMount() {
        if(this.state.selectedValue?.startsWith(this.props.intl.formatMessage(messages["global.other"]) + ' - ')){
            const _otherText = this.state.selectedValue.split(this.props.intl.formatMessage(messages["global.other"]) +' - ')[1];
            this.setState({selectedValue: this.props.intl.formatMessage(messages["global.other"]), showOtherTextbox: true, otherTextValue: _otherText });
            if(_otherText !== ''){
                this.props.selectOption(this.props.intl.formatMessage(messages["global.other"]) + ' - ' + _otherText, this.props.question.questionId, this.props.question.questionType);
            }
        }
    }

    getDynamicClass = (answerSelected: any, sentence: any) => {
        let dynamicClass = '';
        if (answerSelected?.optionId && sentence.length >= 8) {
            dynamicClass += "adjust-active";
        } else if (sentence.length >= 8) {
            dynamicClass += "adjust";
        }
        else if (answerSelected?.optionId && sentence.length < 8) {
            dynamicClass += "sentence-active";
        } else {
            dynamicClass += "sentence";
        }
        return dynamicClass;
    }

    selectOption = (evnt:any) => {
        const _selectedValue =  evnt.target?.value;
        if(_selectedValue === this.props.intl.formatMessage(messages["global.other"])){
            this.setState({selectedValue: _selectedValue, showOtherTextbox: true});
            if(this.state.otherTextValue === ''){
                this.setState({invalidOtherText: true});
                this.props.removeOption(this.props.question.questionId, this.props.question.questionType);
            } else {
                this.props.selectOption(this.props.intl.formatMessage(messages["global.other"]) + ' - ' + this.state.otherTextValue, this.props.question.questionId, this.props.question.questionType);
            }
        } else {
            this.setState({selectedValue: _selectedValue,invalidOtherText: false,showOtherTextbox: false });
            this.props.selectOption(evnt, this.props.question.questionId, this.props.question.questionType);
        }        
    }

    getOtherText = (evnt: any) => {
        const otherText = evnt.target?.value;
        if(otherText === '') {
            this.setState({invalidOtherText: true});
            this.props.removeOption(this.props.question.questionId, this.props.question.questionType);
        } else {
            this.setState({otherTextValue: otherText, invalidOtherText: false});
            this.props.selectOption(this.props.intl.formatMessage(messages["global.other"]) + ' - ' + otherText, this.props.question.questionId, this.props.question.questionType);
        }
    }

    changeOtherText = (evnt: any) => {        
        const _txt = evnt.target?.value;
        if(_txt === '' || isAlphanumeric(_txt)) {
            this.setState({otherTextValue: _txt});
        }
    }

    public render() {
        const orderedOptions = this.props.question.options?.sort((a: any, b:any) => {return a.sequence-b.sequence});
        return (
            <>
                <div className="question-title">{this.props.question.text}</div>
                <div className="ques-sec">
                    <div className={this.getDynamicClass(this.props.answerSelected, this.props.question.sentence)}>
                        {this.props.question.sentence}: 
                    </div>
                    <div className="option-container">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="quiz" name="quiz" value={this.props.answerSelected?.optionId || ''} 
                                onChange={this.selectOption}>
                                { orderedOptions?.map((option: any) => {
                                    return (<FormControlLabel value={option.text} 
                                        control={option?.text == this.state.selectedValue ?<img style={{width:'18px'}} alt="" className="selected-img" src={selected} /> : <Radio />} label={option.text}
                                        className={option?.text == this.state.selectedValue ? "label-active" : "label-inactive"}
                                        key={option.text} 
                                    />);
                                })}
                            </RadioGroup>
                        </FormControl>
                        { this.state.showOtherTextbox && 
                            <input type="text" className={this.state.invalidOtherText ? "input required" : "input"} maxLength={50} 
                            onBlur={this.getOtherText} value={this.state.otherTextValue} onChange={this.changeOtherText} onKeyDown={validateText} onPaste={(e)=>{e.preventDefault()}}/>}
                    </div>
                </div>
            </>
        )
    }

}

export const Multiline = injectIntl(MultilineComponent);

