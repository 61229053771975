import React from "react";
import selected from "../../../assets/icon/spectrum_selected.png";
import unselected from "../../../assets/icon/spectrum_unselected.png";
import { getSpaceQuizQuestions, setCountry } from "../../../redux/actions";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { connect } from "react-redux";
import { countries } from "../../../models/enums";
import "./country-language-settings.scss";
import appConfig from "../../../helper/app-config";

const config = appConfig();

interface CountryLangguageSettingsProps extends WrappedComponentProps {
  countryCode: string;
  languageCode: string;
  setCountry(countryCode: string, languageCode: string): void;
  getSpaceQuizQuestions(countryCode: string): void;
  isDisabled?: boolean;
}

class CountryLangguageSettings extends React.PureComponent<CountryLangguageSettingsProps> {
  state = {
    isOpen: false,
    countryCode: '',
    languageCode: ''
  }

  countryList : Array<any> = [];

  constructor(props: any) {
    super(props);
    document.documentElement.lang = props.languageCode;  
    document.body.classList.add("lang_" + props.languageCode);
  }

  componentDidMount() {
    this.setState({ countryCode: this.props.countryCode, languageCode: this.props.languageCode });
    this.enableCountryFromConfig();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.countryCode !== prevProps.countryCode || this.props.languageCode !== prevProps.languageCode) {
      this.setSelectedCountry(this.props.countryCode, this.props.languageCode, false);
    }
  }

  enableCountryFromConfig = () => {
    const allwdCountries = config.REACT_APP_COUNTRIES;
    countries.forEach((item: any) => {
      if(allwdCountries.indexOf(item.countryCode) > -1){
        this.countryList.push(item);
      }
    })
  }

  setSelectedCountry = (countryCode: string, langCode: string, updateLocalStorage: boolean) => {
   const _selCountry = countries.find((item) => { return item.countryCode === countryCode });
    if (_selCountry !== undefined) {
      const lang = _selCountry.languages.find((language: any) => { return langCode === '' ? language.default : language.languageCode === langCode });
      this.setState({ countryCode: countryCode, languageCode: lang?.languageCode });
    }
  }

  showDropdown = () => {
    this.setState({ isOpen: true });
    document.addEventListener('click', this.hideDropdown);
  };

  hideDropdown = () => {
    this.setState({ isOpen: false });
    document.removeEventListener('click', this.hideDropdown);
  };

  chooseCountry = (country: any) => {
    const lang = country.languages.find((language: any) => { return language.default });
    this.setState({ countryCode: country.countryCode, languageCode: lang.languageCode });
    this.updateCountryAndLanguage(country.countryCode, lang.languageCode, true);
    this.hideDropdown();
  }; 

  updateCountryAndLanguage = (countryCode: string, languageCode: string, updateLocalStorage: boolean) => {
    this.props.setCountry(countryCode, languageCode);
    this.props.getSpaceQuizQuestions(countryCode);
    if (updateLocalStorage) {
      localStorage.removeItem('countryCode');
      localStorage.removeItem('languageCode');
      localStorage.setItem('countryCode', countryCode);
      localStorage.setItem('languageCode', languageCode);
    }
    document.body.className = '';
    document.documentElement.lang = languageCode;
    document.body.classList.add("lang_" + languageCode);
  }

  public render() {
    const _countries = this.countryList.filter(c1 => c1.countryCode === this.state.countryCode)
      .concat(this.countryList.filter(c2 => c2.countryCode !== this.state.countryCode).sort((a, b) => a.countryName.localeCompare(b.countryName)));
    return (
      <div className={`dropdown ${this.state.isOpen ? "open" : ""}`}>
        <div className={`dropdown-toggle ${this.props.isDisabled ? "disabled" : ""}`} onClick={() => { !this.props.isDisabled && this.showDropdown() }} >
          <span className={`countryFlags ${this.state.countryCode}`}>{_countries.find((item) => { return item.countryCode === this.state.countryCode })?.countryName}</span>
          <span className="caret"></span>
        </div>
        <div className="dropdown-menu">
          <div className="countryOptions">
            {
              _countries?.map((country: any) => {
                return (
                  <div key={country.countryCode} className={`option ${country.countryCode === this.state.countryCode ? 'selected' : ''}`} onClick={() => this.chooseCountry(country)}>
                    <img className="img" src={country.countryCode === this.state.countryCode ? selected : unselected} />
                    <div className={`countryFlags ${country.countryCode}`}><span>{country.countryName}</span></div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCountry: (countryCode: string, languageCode: string) => dispatch(setCountry(countryCode, languageCode)),
    getSpaceQuizQuestions: (countryCode: string) => dispatch(getSpaceQuizQuestions(countryCode))
  };
};

const mapStateToProps = (state: any) => {
  return {
    countryCode: state.quiz.countryCode,
    languageCode: state.quiz.languageCode
  }
}

export const CountryLanguage = injectIntl(connect(mapStateToProps, mapDispatchToProps)(CountryLangguageSettings));
