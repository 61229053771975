import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import "./progress.scss";

interface ProgressProps extends WrappedComponentProps {
  totalCount: number;
  currentIndex : number;
  isDesignStarted:boolean;
}

class ProgressComponent extends React.PureComponent<ProgressProps> {

    overflowStyle = {
        overflow:'initial',
    }   
    
    progressPercentage = () => {
      if( this.props.currentIndex &&  this.props.totalCount) {
      const _perc =  Math.round(( this.props.currentIndex / this.props.totalCount ) * 100 );
      const perc = this.props.isDesignStarted ? Math.round( 50 + (_perc/2)) : Math.round(_perc/2)
      return perc < 100 ? perc : 100 ;
      } else {
        return this.props.isDesignStarted ? 50 : 0;
      }
    }

  public render() {
    return (
      <div className="progress-bar">
        <div className="progress-text">
            {`${this.progressPercentage()}% ${this.props.intl.formatMessage(messages["progressBar.progressText"])}`}
        </div>

        <svg width='250px' height='10px' style={this.overflowStyle}>
            { this.props.isDesignStarted 
                ? <circle cx={0} cy={0} r={10} fill="#003f2d" />
                : <circle cx={0} cy={0} r={7} fill="none" stroke="#003f2d" strokeWidth={6} />
            }
            { !this.props.isDesignStarted && <line x1="13" y1="0" x2="95" y2="0" stroke="#003f2d" strokeWidth={2} opacity="0.5" /> }
            { this.progressPercentage() < 51 
                ? <line x1="13" y1="0" x2={Math.round((this.progressPercentage() * 2 * 82) / 100 ) + 13 } 
                    y2="0" stroke="#003f2d" strokeWidth={4} />
                : <line x1="13" y1="0" x2="87" y2="0" stroke="#003f2d" strokeWidth={4} />
            }
            { this.props.isDesignStarted 
                ? <circle cx={100} cy={0} r={7} fill="none" stroke="#003f2d" strokeWidth={6} />
                : <circle cx={100} cy={0} r={5} fill="#003f2d" opacity="0.5" />
            }
            {this.progressPercentage() > 50 
              ? <line x1="113" y1="0" x2="195" y2="0" stroke="#003f2d" strokeWidth={2} opacity="0.5" />
              : <line x1="105" y1="0" x2="195" y2="0" stroke="#003f2d" strokeWidth={2} opacity="0.5" />
            }
            { this.progressPercentage() > 50 &&
                <line x1="113" y1="0" x2={Math.round( ((this.progressPercentage() - 50) * 2 * 82)/100 ) + 113 } 
                    y2="0" stroke="#003f2d" strokeWidth={4} />                
            }
            <circle cx={200} cy={0} r={5} fill="#003f2d" opacity="0.5" />
            
            <text x="-55" y="30" fill="#003f2d">{this.props.intl.formatMessage(messages["progressBar.spaceProgram"])}</text>
            <text x="75" y="30" fill="#003f2d" opacity={this.props.isDesignStarted ? "1" : "0.5" }>{this.props.intl.formatMessage(messages["progressBar.designStyle"])}</text>            
            <text x="190" y="30" fill="#003f2d" opacity="0.5">{this.props.intl.formatMessage(messages["progressBar.result"])}</text>
        </svg>              
      </div>
    );
  }
}

export const Progress = injectIntl(ProgressComponent);
