/* istanbul ignore file */
export default {
  home: "/",  
  quiz: "/office-ready-quiz",  
  result: "/office-ready-quiz-result",
  resultPDF: "/office-ready-result-by-CBRE",
  settings: "/settings",
  termsAndConditions: "/terms-and-conditions",
  officeReadyError: "/office-ready-error",
};
