import * as React from "react";
import path from "../../../assets/icon/path.png";
import warning from "../../../assets/icon/warning.png";
import "./modal.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import {Warning} from '@material-ui/icons';

interface ModalProps extends WrappedComponentProps {
  onPopupClose(): void;
  onQuizExit(): void;
}

class ModalComponent extends React.PureComponent<ModalProps> {

  public constructor(props: any) {
    super(props);
  }

  onPopupClose = () => {
    if (this.props.onPopupClose !== null && this.props.onPopupClose !== undefined) {
      this.props.onPopupClose();
    }
  };
  onQuizExit = () => {
    if (this.props.onQuizExit !== null && this.props.onQuizExit !== undefined) {
      this.props.onQuizExit();
    }
  };

  public render() {
    return (
      <div className="modal">
        <div className="modal-content">
          <div className="close" data-test="close_icon" onClick={this.onPopupClose}>&times;</div>
          <h1 className="popup-heading">
            <div className="warn-img">
              <Warning />
            </div>
            <div className="heading"> {this.props.intl.formatMessage(messages["modal.heading"])}</div>
          </h1>
          <div className="popup-desc"> {this.props.intl.formatMessage(messages["modal.description.first"])}</div>
          <div className="popup-desc spacing"> {this.props.intl.formatMessage(messages["modal.description.second"])}</div>
        
          <div className="popup-btn-container">           
            <button className="primary mdc-button mdc-button--outlined custom-outlined-button mdc-ripple-upgraded continue-quiz" data-test="continue_Btn" onClick={this.onPopupClose} >
                {this.props.intl.formatMessage(messages["modal.button.leftBtn"])}
            </button>
            <button className="primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded btn-quit close-quiz" data-test="exit_Btn" onClick={this.onQuizExit}>
                {this.props.intl.formatMessage(messages["modal.button.rightBtn"])}
            </button>
          </div>
        </div>  
      </div>
    )
  }

}

export const Modal = injectIntl(ModalComponent);

