import * as React from "react";
import "./your-cost-estimate.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";

interface YourCostEstimateProps extends WrappedComponentProps {
  countryCode: string;
  costEstimateData: any;
  locationData: any;
  officeLocation?: '';
  weitage?: any;
  totalSF: any;
  designProfileName: string;
}

export class YourCostEstimateComponent extends React.PureComponent<YourCostEstimateProps> {
  state = {
    conversionRate: 1
  }
  public constructor(props: any) {
    super(props);
  }
  sf2SM =  0.092903;

  numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  capitalizeFirstLetter = (string: any) => {
    if(string =='FF&E') {
    return string 
    .toUpperCase()
    }
    else {
    return string
      .toLowerCase()
      .split(' ')
      .map(function (word: any) {
        return word[0].toUpperCase() + word.substr(1);
      })
      .join(' ');
    }
  }

  findCity = (city: string) => {
    if (city != null) {
      var strArr = city.split(',');
      return strArr[0]
    }
  }
  cityName = this.findCity(this.props.officeLocation as string);
  getCurrency  = () => {    
    switch (this.props.countryCode) {
      case "ES":
      case "FR":
        return "€";
      case "UK":
        return (this.cityName === "Dublin" || this.cityName === "Cork") ? "€" : "£";  
      default:
        return "$";
    }
  }  
  
  getCostAsPerWeightage = (cost: any, weitageCity: any) => {
    if(this.props.countryCode === 'UK' && weitageCity && weitageCity.length > 0 ) {      
      const getData:any = this.props.costEstimateData?.data[0];      
      const conversionRate =(JSON.parse(getData?.costObject?.value))?.conversionRate;
      this.setState({ conversionRate : conversionRate});

      if(this.cityName === "Cork" || this.cityName === "Dublin"){
        return (Math.round(((cost*weitageCity[0].weitage)/100)*conversionRate));
      }     
        return (Math.round((cost*weitageCity[0].weitage)/100));      
    }
    return cost;
  }

  public render() {
    let city = this.findCity(this.props.officeLocation as string);  
    const weitageCity = this.props.locationData.data && this.props.locationData.data.filter(function (e: any) {
      if (e.city == city) {
        return e
      }
    })
    var sumTraditional = 0;
    var sumOfficeReady = 0;
    var pName = this.props.designProfileName;
    var profileName = pName.charAt(0).toUpperCase() + pName.toLowerCase().slice(1)
    let costObject: any;
    this.props.costEstimateData.data && this.props.costEstimateData.data.map((item: any) => {
   
      if(item.categoryName === this.props.designProfileName) { 
        costObject = JSON.parse(item.costObject.value)
      }
    });   

    return ( this.props.costEstimateData && this.props.costEstimateData.data ?
      <>
      <div className="grid-item">
        { (this.props.countryCode === 'ES' || this.props.countryCode === 'UK' || this.props.countryCode === 'FR' ) &&
          <div className="cost-estimate-grid"> 
            <div className="d-flex flex-row header-1">
              <div className="colm-1"></div>
              <div className="colm-1">{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.SubTitile"])}  <span>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.CostPerSF"])}</span></div>
            </div>
            { (parseInt(this.props.totalSF) * this.sf2SM) > 3000 
              ?
                <div className="row grid-item-col">
                  {this.props.countryCode !== 'FR' && 
                  <div className="col-item">{this.props.costEstimateData.data[0]?.categoryName}</div>
                }
                  <div className={this.props.countryCode !== 'FR'? 'col-item textleft exceedMsg':'col-item textCenter width-100 single_col exceedMsg'}>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.costThresoldMessage"])}</div>
                </div>

              :
              this.props.costEstimateData.data && this.props.costEstimateData.data.map((item: any, index: any) => {
              const areaArr = item.officeReadyAreaRange?.split('-');
              const feeArr = item.officeReadyFeeRange?.split('-');
              if(areaArr !== undefined && feeArr !== undefined && (parseInt(this.props.totalSF) * this.sf2SM) > parseInt(areaArr[0]) && (parseInt(this.props.totalSF) * this.sf2SM) < parseInt(areaArr[1])) {
                return (
                  <div className="row grid-item-col" key={index}>
                    <div className="col-item">{item.categoryName}</div>
                    {this.props.countryCode !== 'FR' ?
                    <div className="col-item">                     
                      {this.getCurrency() + this.getCostAsPerWeightage(Math.round(feeArr[0]).toFixed(),weitageCity) + " - " + this.getCurrency() + this.getCostAsPerWeightage(Math.round(feeArr[1]).toFixed(),weitageCity)}
                    </div> 
                    :
                    <div className="col-item">                     
                      {this.getCurrency() + this.getCostAsPerWeightage(Math.round(feeArr[0]).toFixed(),weitageCity)}
                    </div>
                }
                  </div>
                );
              }
            })}
          </div>
        }
        { this.props.countryCode === 'US' && weitageCity && weitageCity.length > 0 
            && <div className="cost-estimate-grid">
            <div className="d-flex flex-row header-1">
              <div className="colm-1"></div>
              <div className="colm-1">{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.Title"])}  <span>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.CostPerSF"])}</span></div>
              <div className="colm-1">{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.SubTitile"])} <span>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.CostPerSF"])}</span></div>
            </div>
            {this.props.costEstimateData.data && this.props.costEstimateData.data.map((item: any, index: any) => {
              sumTraditional = sumTraditional + (item.traditionalFee * weitageCity[0].weitage)/100;
              sumOfficeReady = sumOfficeReady + (item.officeReadyFee * weitageCity[0].weitage)/100;
              return (
                <div className="row grid-item-col" key={index}>
                  <div className="col-item col-width-item">{this.capitalizeFirstLetter(item.categoryName)}</div>
                  <div className="col-item" >{this.getCurrency() + this.numberWithCommas((Math.round(item.traditionalFee * weitageCity[0].weitage)/100).toFixed())}</div>
                  <div className="col-item">{this.getCurrency() + this.numberWithCommas((Math.round(item.officeReadyFee * weitageCity[0].weitage)/100).toFixed())}</div>
                </div>
              )
            })}
            <div className="row grid-item-col total">
              <div className="col-item col-width-item">{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.Total"])}</div>
              <div className="col-item">{this.getCurrency() + (this.numberWithCommas(Math.round(sumTraditional).toFixed()))}</div>
              <div className="col-item">{this.getCurrency() + (this.numberWithCommas(Math.round(sumOfficeReady).toFixed()))}</div>
            </div>
          </div>
        } 

        { this.props.countryCode === 'SG' && this.props.costEstimateData?.data.map((item: any, index: any) => {        
            let costObj = JSON.parse(item.costObject.value);
            return(
              <>
                  <div className="cost-estimate-grid"> 
                    <div className="d-flex flex-row header-1">
                      <span>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.Title"])}: <label>{item.categoryName}</label> </span>
                    </div>
                   <div>
                     {costObj[profileName] && Object.keys(costObj[profileName]).map((key, indx) => { 
                       return(                  
                        <div className={`row grid-item-col`}>
                          <div className="col-item">{key}</div>
                          <div className="col-item">{costObj[profileName][key]}</div>
                        </div>
                        )}
                      ) 
                     }
                     </div>
                  </div>                  
                  <div className="description-text-table">
                  {item.categoryName === "Typical office building" ?  
                    this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.TypicalDescription"]) : 
                    this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.GradeDescription"]) 
                  }                      
                 </div>
                 </>
                )
              })
            }
      </div>
      {this.props.countryCode === 'UK' && (city === "Dublin" || city === "Cork") &&  
          <div className="description-text">
            {this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.baseRate1"])} 
            {this.state?.conversionRate} 
            {this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.baseRate2"])}  
          </div>
      }
      <div className="description-text">
        {this.props.countryCode !== 'SG' ?
         this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.Description"]):
         <>
          <p>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.typically"])}</p>
          <p>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.GradeDescriptionList1"])}</p>
          <p>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.GradeDescriptionList2"])}</p>
          <p>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.GradeDescriptionList3"])}</p>
          <p>{this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.GradeDescriptionList4"])}</p>
         </>
         }
      </div>
      <div className="disclaimer">
        <span>{this.props.countryCode === 'ES'  ? this.props.intl.formatMessage(messages["global.notes"]) : this.props.intl.formatMessage(messages["global.disclaimer"])}:</span>
        {this.props.countryCode === 'ES'  ? this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.notes"]) : this.props.intl.formatMessage(messages["quizResult.yourCostEstimate.disclaimer"])}
      </div>
      </>
      : null
    );
  }
}

export const YourCost = injectIntl(YourCostEstimateComponent);