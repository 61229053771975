import React from "react";
import { connect } from "react-redux";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { AppState } from "../../../store/state";
import { getIndustrialList  } from "../../../redux/actions";
import "./autosuggest.scss"
import Select from "react-dropdown-select";
import messages from "../../../translations/messages";

interface AutosuggestProps extends WrappedComponentProps {
  countryCode: string;
  question : any;
  answerSelected : any;
  selectOption(event:any, questionId: any, questionType: any): void;
  getIndustrialList(countryCode: string): void;
  industrialData: any;
}

class AutosuggestComponent extends React.Component<AutosuggestProps> {
  state = {
    selectedOptValues:'',
  }
  constructor(props: AutosuggestProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
   
    const selectedObj = this.props.industrialData?.find((data:any)=> { return data.label== this.props.answerSelected?.optionId});

    this.state.selectedOptValues=selectedObj ;
    
  }
   
  componentDidMount() {
    this.props.getIndustrialList(this.props.countryCode);
    if(this.state.selectedOptValues){
      const inputNode= document.querySelector("div.autoselect-container > div.react-dropdown-select-type-single > input") as HTMLElement;
    if(inputNode)inputNode.style.display='none';
    }
  }
  
  /* istanbul ignore next */
  handleChange = (event: any) => {
    const selectedOptValues = event[0];
    if(selectedOptValues !== undefined) {
      this.setState({selectedOptValues})
      this.props.selectOption(selectedOptValues.label, this.props.question.questionId, this.props.question.questionType);
    }
  }

  /* istanbul ignore next */
  onDropdownOpen = () => {
    const dd= document.querySelector("div.autoselect-container > div.react-dropdown-select-type-single > span") as HTMLElement;
    if(dd)dd.style.display='none';
    const inputNode= document.querySelector("div.autoselect-container > div.react-dropdown-select-type-single > input") as HTMLElement;
    if(inputNode)inputNode.style.display='inline-block';
  }
  
  /* istanbul ignore next */
  onDropdownClose = () => {
    const dd= document.querySelector("div.autoselect-container > div.react-dropdown-select-type-single > span") as HTMLElement;
    if(dd)dd.style.display='inline-block';
    const inputNode= document.querySelector("div.autoselect-container > div.react-dropdown-select-type-single > input") as HTMLElement;
    if(inputNode)inputNode.style.display='none';
  }
  

  render() {    
    const industrialDataDetails = this.props.industrialData && this.props.industrialData?.map((data: any, index: any) => { return { label: data.label } });
    const text = this.props.question.text.split('___');
    return (
      <>
        <div className="question-container">
          <span>{text[0]}</span>
          <div className="sector-dropdown">
            <Select
              className="autoselect-container" 
              options={industrialDataDetails} 
              dropdownHeight="165px"
              placeholder= {this.props.intl.formatMessage(messages["sector.placeHolderMsg"])}
              values={this.state.selectedOptValues ? [this.state.selectedOptValues] :[]}
              onChange={this.handleChange}
              onDropdownOpen={this.onDropdownOpen}
              onDropdownClose={this.onDropdownClose} />
          </div>
          <span>{text[1]}</span>
        </div>      
      </>
    );
  }


}

const mapStateToProps = (state: AppState) => {
  return {
    industrialData: state.quiz.industrialData,
    countryCode: state.quiz.countryCode
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getIndustrialList: (countryCode: string) => dispatch(getIndustrialList(countryCode)),
  };
};

export const Autosuggest = injectIntl(connect(mapStateToProps, mapDispatchToProps)(AutosuggestComponent));
