import React from "react";
import "./quiz-result-pdf.scss";
import { YourCost } from "../common/your-cost-estimate/your-cost-estimate";
import { PieChart } from "react-minimal-pie-chart";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { formatNumber } from "../../helper/helper";
import routes from "../route/routes";
import appConfig from "../../helper/app-config";
import CBRElogo from "../../assets/CBRE-logo.png";
import messages from "../../translations/messages";

const config = appConfig();

interface QuizResultPdfProps extends WrappedComponentProps {
  history: any;
  match: any;
  location: any;
}
class QuizResultPdfComponent extends React.Component<QuizResultPdfProps> {

  resultData = this.props.location.state.resultData;  
  quizResultLink='';
  sf2SM =  0.092903;
  public constructor(props: any) {  
    super(props);
    this.quizResultLink = config.REACT_APP_BASE_APP_URL + routes.result+'/'+ this.resultData.quizId;
  }

  navigateToResult = () => {
    this.props.history.push(routes.result+'/'+ this.resultData.quizId);
  }

  componentDidMount() {
    const printed = document.execCommand('print');
    if(!printed) {
      window.print();
    }
    // Navigate to Result page back
    this.navigateToResult();
  }

  showM2 = () => {
    return this.resultData.countryCode === 'ES' || this.resultData.countryCode === 'FR' ;
  }

  public render() {    
    return (
      <>        
        <div className="pdf-container">
          <div className="pdf-heading" onClick={this.navigateToResult}>
            <span className="pdf-heading1">
              {this.props.intl.formatMessage(messages["global.officeReady"])}
            </span>
            {' ' + this.props.intl.formatMessage(messages["global.by"]) + ' '}
            <span className="logo-font">{this.props.intl.formatMessage(messages["global.CBRE"])}</span>
          </div>
          <div className="pdf-heading-desc">
            <div className="left">{this.props.intl.formatMessage(messages["quizResult.pdf.viewCustomOfficeText"])}</div>
          </div>
            <div className="pdf-result-heading">
              { this.resultData.fname  + this.props.intl.formatMessage(messages["quizResult.pdf.yourResultHeading"]) }
            </div>
            <div className="pdf-section">
                <div className="design-profile-image">
                  <a href={this.quizResultLink}>
                    <img src={config.REACT_APP_S3 +  atob(this.resultData?.design?.thumbnail)} alt="" className="img" width="100%" />
                  </a>
                </div>
                <div className="palette-div">
                    <img src={config.REACT_APP_S3 + atob(this.resultData?.design?.pallete)} alt="" className="img" width="100%"/>
                </div>
            </div>
            <div className="pdf-profile-data">
              <div className="pdf-profile left">
                <div className="pdf-profile-heading">
                  { this.props.intl.formatMessage(messages["quizResult.designProfile.heading"])+ ' ' + this.resultData?.design?.profileName }
                </div>
                <div className="pdf-profile-desc">
                  { this.resultData?.design?.profileDescription }
                </div>
              </div>
              <div className="pdf-profile right">
                <div className="pdf-profile-heading">
                  { this.props.intl.formatMessage(messages["quizResult.spaceProfile.heading"])+ ' ' + this.resultData?.space?.profileName }
                </div>
                <div className={this.resultData?.space?.profileName?.toUpperCase() === 'DIFFERENTIATOR' ? "pdf-profile-desc mbot40" : "pdf-profile-desc"}>
                  { this.resultData?.space?.profileDescription }
                </div>
              </div>
            </div>
            <div className="pdf-seperator"></div>
            <div className="pdf-section divNoBreak">
              <div className="pdf-section-heading">
                { this.props.intl.formatMessage(messages["quizResult.actionLink.yourProgram"]) }
              </div>
              <div className="pdf-row">
                <div className="pdf-col">
                  <span className="pdf-row-desc text-bold">{this.props.intl.formatMessage(messages["quizResult.yourProgram.recommendedOfficeSize"]) + ': '}</span>
                  <div className="pdf-row-heading1 text-bold mlef5">
                    {
                     (this.showM2() ? (this.resultData.programResult?.areaTotals?.totalSF * this.sf2SM).toFixed() : this.resultData.programResult?.areaTotals?.totalSF )
                     + ' ' + this.props.intl.formatMessage(messages["quizResult.pdf.areaUnit"])}</div>
                </div>
              </div>
              <div className="pdf-row">
                <div className="pdf-col box-bg">
                  <span className="pdf-col-heading">{this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSeats"])}</span>
                  <div className="pdf-program-chart">
                    <PieChart 
                      data={[
                        { title: this.props.intl.formatMessage(messages["quizResult.yourProgram.workstations"]), value: this.resultData.programResult?.finalStats.openPercentage, color: '#003F2D' },
                        { title: this.props.intl.formatMessage(messages["quizResult.yourProgram.offices"]), value: this.resultData.programResult?.finalStats.closedPercentage, color: '#DADADA' },
                      ]}
                    />
                  </div>
                  <div className="pdf-chart-label">
                    <div className="label mtop20">
                      <div className="ws-color-code"></div>
                      <div>{this.props.intl.formatMessage(messages["quizResult.yourProgram.workstations"])}</div>
                      <div className="right">{this.resultData.programResult?.finalStats.openPercentage + '%'}</div>
                    </div>
                    <div className="label">
                      <div className="of-color-code"></div>
                      <div>{this.props.intl.formatMessage(messages["quizResult.yourProgram.offices"])}</div>
                      <div className="right">{this.resultData.programResult?.finalStats.closedPercentage + '%'}</div>
                    </div>
                  </div>
                </div>
                <div className="pdf-col box-bg">
                  <span className="pdf-col-heading">{this.props.intl.formatMessage(messages["quizResult.yourProgram.collaboration"])}</span>
                  <div className="number text-center">
                    <div>{formatNumber(this.resultData.programResult?.finalStats.collabSeatRatio)}</div>
                    <div className="colon">:</div>
                    <div>{'1.0'}</div>
                  </div>
                  <div className="desc">
                    <div>{this.props.intl.formatMessage(messages["quizResult.yourProgram.enclosedGroupSeats"])}</div>
                    <div className="right">{this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSeats"])}</div>
                  </div>
                </div>
                <div className="pdf-col box-bg w100px">
                  <span className="pdf-col-heading">{this.props.intl.formatMessage(messages["quizResult.yourProgram.seatsPerPerson"])}</span>
                  <div className="number text-center">
                    <div>{formatNumber(this.resultData.programResult?.finalStats.sharingRatio)}</div>
                    <div className="colon">:</div>
                    <div>{'1.0'}</div>
                  </div>
                  <div className="desc">
                    <div>{this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSeats"])}</div>
                    <div className="right">{this.props.intl.formatMessage(messages["quizResult.yourProgram.persons"])}</div>
                  </div>
                </div>
                <div className="pdf-col box-bg w100px mRig0px">
                  <span className="pdf-col-heading">{this.props.intl.formatMessage(messages["quizResult.yourProgram.density"])}</span>
                  <div className="number text-center">{ this.showM2() ? Math.round(this.resultData.programResult?.finalStats?.usfPerSeat* this.sf2SM) : Math.round(this.resultData.programResult?.finalStats?.usfPerSeat)}</div>
                  <div className="desc text-center">{this.props.intl.formatMessage(messages["quizResult.yourProgram.densityDescriptionSM"])}</div>
                </div>
              </div>
            </div>            
            {this.resultData.countryCode === 'UK' && <div className="pagebreak"></div>}
            <div className="pdf-section"> 
              <div className="pdf-row ptop72px">
                <div className="pdf-space-group-div divNoBreak">
                  <div className="pdf-space-group-heading-div">
                    <div className="pdf-space-group-heading">
                      {this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSpace"])}
                      <br/><br/> 
                      {this.resultData.programResult.areaTotals.mePercentage + '%'}
                    </div>
                  </div>
                  <div className="pdf-space-group-data-div">
                    { this.resultData.programResult?.ME?.map((item: any, index: number) => {              
                    return (
                      <div className="pdf-space-group-item" key={index}>
                        <div className="w30per">{item.spaceUnitName}</div>
                        <div className="w10per">{this.showM2() ? ((item.sf * this.sf2SM).toFixed())  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SM"]) : item.sf  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SF"])}</div>
                        <div className="w10per">{'X'}</div>
                        <div className="w10per">{item.quantity}</div>
                        <div className="w10per">{this.props.intl.formatMessage({id:'global.'+item.unitLabel})}</div>
                        <div className="w10per">{'='} </div>
                        <div className="w20per">{this.showM2()? ((item.sf * item.quantity * this.sf2SM).toFixed())  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SM"]) : (item.sf * item.quantity)  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SF"])}</div>
                      </div> 
                    )
                    })}
                  </div>
                </div>
                <div className="pdf-space-group-div">
                <div className="pdf-space-group-heading-div divNoBreak">
                    <div className="pdf-space-group-heading">
                    {this.props.intl.formatMessage(messages["quizResult.yourProgram.groupSpace"])}
                    <br/><br/>
                    {this.resultData.programResult.areaTotals.wePercentage + '%'}
                    </div>
                  </div>
                  <div className="pdf-space-group-data-div">
                    { this.resultData.programResult?.WE?.map((item: any, index: number) => {              
                    return (
                      <div className="pdf-space-group-item" key={index}>
                        <div className="w30per">{item.spaceUnitName}</div>
                        <div className="w10per">{this.showM2() ? ((item.sf * this.sf2SM).toFixed())  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SM"]) : item.sf  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SF"])}</div>
                        <div className="w10per">{'X'}</div>
                        <div className="w10per">{item.quantity}</div>
                        <div className="w10per">{this.props.intl.formatMessage({id:'global.'+item.unitLabel})}</div>
                        <div className="w10per">{'='} </div>
                        <div className="w20per">{this.showM2() ? ((item.sf * item.quantity * this.sf2SM).toFixed())  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SM"]) : (item.sf * item.quantity)  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SF"])}</div>
                      </div> 
                    )
                    })}
                  </div>
                </div>
                <div className="pdf-space-group-div divNoBreak">
                  <div className="pdf-space-group-heading-div">
                    <div className="pdf-space-group-heading">
                    {this.props.intl.formatMessage(messages["quizResult.yourProgram.amenitySpace"])}
                    <br/><br/>
                    {this.resultData.programResult.areaTotals.amenityPercentage + '%'}
                    </div>
                  </div>
                  <div className="pdf-space-group-data-div">
                    { this.resultData.programResult?.AMENITY?.map((item: any, index: number) => {              
                    return (
                      <div className="pdf-space-group-item" key={index}>
                        <div className="w30per">{item.spaceUnitName}</div>
                        <div className="w10per">{this.showM2() ? ((item.sf * this.sf2SM).toFixed())  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SM"]) : item.sf +  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SF"])}</div>
                        <div className="w10per">{'X'}</div>
                        <div className="w10per">{item.quantity}</div>
                        <div className="w10per">{this.props.intl.formatMessage({id:'global.'+item.unitLabel})}</div>
                        <div className="w10per">{'='} </div>
                        <div className="w20per">{this.showM2() ? ((item.sf * item.quantity * this.sf2SM).toFixed())  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SM"]) : (item.sf * item.quantity)  + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SF"])}</div>
                      </div> 
                    )
                    })}
                  </div>
                </div>
              </div>
              <div className="pdf-row disclaimer">
                <span>{this.props.intl.formatMessage(messages["global.disclaimer"]) + ':'}</span>
                {this.props.intl.formatMessage(messages["quizResult.yourProgram.disclaimer"])}
              </div> 
            </div>
            <div className="pdf-seperator"></div>            
            {this.resultData.countryCode === 'UK' && <div className="pagebreak"></div>}
            <div className="pdf-section mtop300px ptop72px divNoBreak">
              <div className="pdf-section-heading">
                { this.props.intl.formatMessage(messages["quizResult.actionLink.yourCostEstimate"])}
              </div>
              <div className="pdf-cost-estimate">             
               { this.resultData.costEstimateData && 
                <YourCost countryCode={this.resultData?.countryCode} costEstimateData={this.resultData?.costEstimateData } locationData={this.resultData?.locationData} 
                    officeLocation={this.resultData?.officeLocation} totalSF={this.resultData?.programResult?.areaTotals?.totalSF} designProfileName={this.resultData?.design?.profileName}/>
                }
                </div>
            </div> 
            <div className="pdf-seperator"></div>
        </div>
        <div className="pdf-footer">
        <div className="pdf-footer-row">
          <div className="pdf-footer-col">
            <img src={CBRElogo} alt="" className="" />
          </div>
          <div className="pdf-footer-col">           
            <div className="sub-heading">{this.props.intl.formatMessage(messages["global.contactGeneralEnquiries"])}</div>
            <a href={`mailto:${this.props.intl.formatMessage(messages["global.supportEmail1"])}`} target="_blank" rel="noopener noreferrer">
                {this.props.intl.formatMessage(messages["global.supportEmail1"])}
            </a>
          </div>
          <div className="pdf-footer-col">
            <div className="sub-heading">{this.props.intl.formatMessage(messages["global.contactITSupport"])}</div>
            <a href={`mailto:${this.props.intl.formatMessage(messages["global.supportEmail2"])}`} target="_blank" rel="noopener noreferrer">
              {this.props.intl.formatMessage(messages["global.supportEmail2"])}
            </a>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export const QuizResultPdf = injectIntl(QuizResultPdfComponent);