  /* istanbul ignore file */

// @ts-ignore
// import authentication from "react-azure-adb2c";

import appConfig from "../helper/app-config";
import { v4 as getUUID } from 'uuid';
import { store } from '../store/store';

const config = appConfig();

const getHeaders = () => {
  const state = store.getState();
  return {
    'Accept': 'application/json',
    "Content-Type": "application/json",
    "request-id": getUUID(),
    "Requester": state.quiz.clientInfo.ip + '|' + state.quiz.clientInfo.isp + '|' + state.quiz.clientInfo.country + '|' + state.quiz.clientInfo.latitude + '|' + state.quiz.clientInfo.longitude,
    "Access-Control-Allow-Origin": config.REACT_APP_BASE_APP_URL
  };
}

export const post = (relativeUrl: string, data?: any) => {
  let quizId = '';
  const payloadData = data && {...data}
  return fetch(config.REACT_APP_BASE_API_URL + relativeUrl, {
    method: "POST",
    mode: "cors",
    headers: getHeaders(),
    body: JSON.stringify(payloadData),
  })
  .then((response) => 
          {
            for (var pair of response.headers.entries())
            {
              if (pair[0] == 'quizid') { 
                quizId = pair[1];
              }
            }
            return response.json(); 
        })
  .catch(error => error)
  .then(() => { return quizId; })
}

export const remove = (relativeUrl: string, data?: any) => {
  return fetch(config.REACT_APP_BASE_API_URL + relativeUrl, {
    method: "DELETE",
    mode: "cors",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
  .then((response) => { return response.json(); })
  .catch(error => error)
  .then(data2 => { return data2; })
}

export const get = (relativeUrl: string) => {
  return fetch(config.REACT_APP_BASE_API_URL + relativeUrl, {
    method: "GET",
    mode: "cors",
    headers: getHeaders()
  })
  .then((response) => { return response.json(); })
  .catch(error => error)
  .then(data => { return data; })
}

export const getLocation = (searchParam: string) => {
  return fetch(config.REACT_APP_LOCATION_API_URL + searchParam, {
    method: "GET",
  })
  .then((response) => { return response.json(); })
  .catch(error => error)
  .then(data => { return data; })
}

export const put = (relativeUrl: string, data?: any) => {
  const payloadData = data && {...data}  
  return fetch(config.REACT_APP_BASE_API_URL + relativeUrl, {
    method: "PUT",
    mode: "cors",
    headers: getHeaders(),
    body: JSON.stringify(payloadData),
  })
  .then((response) => { return response.json(); })
  .catch(error => error)
  .then(data3 => { return data3; })
}

