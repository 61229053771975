import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Parallax } from 'react-parallax';
import './designApproach.scss';
import profileDetailData from "./../design-palette-detail/design-palette-data.json";
import appConfig from "../../helper/app-config";
import { StaticHeader } from "../common/static-header/static-header";
import messages from "../../translations/messages";

const config = appConfig();
interface DesignApproachPageProps extends WrappedComponentProps {
    //history: any;
}


class DesignApproachPage extends React.PureComponent<DesignApproachPageProps> {    
    componentDidMount(){
        document.body.classList.add('modalOpen2'); 
        window.scrollTo({ left: 0, top: document.body.scrollHeight + 50000 });        
    }
    public render() {
        AOS.init();
        const pData: any = profileDetailData;
        return (
            <>
                <StaticHeader data-aos="fade-in" />
                <div className="da_wrapper">
                    <Parallax bgImage={`${config.REACT_APP_S3}Singapore/static_page/Our-Design-Approach-header.jpg`} strength={500}>
                        <div style={{ height: 990 }}>
                            <div className="top_banner">
                                <div className="identity" data-aos="fade-up" data-aos-duration="2000">
                                    <h1>{this.props.intl.formatMessage(messages["global.officeReady"])}</h1>
                                    <h2 className="country_name">{this.props.intl.formatMessage(messages["global.singapore"])}</h2>
                                </div>
                                <div className="page_title" data-aos="fade-up" data-aos-duration="1000">Our <span>Design</span> Approach</div>
                            </div>
                        </div>
                    </Parallax>
                    <div className="content_wrapper">
                        {pData?.designApproach.pageSection.map((item: any, index: number) => {
                            return (
                                <section key={item.heading} className={'page_details ' + ((index == 0) ? 'flex_start kit_of_parts' : (index % 2 == 0) ? 'flex_start' : 'flex_end')}>
                                    <div className="col_1 img_animate">
                                        <div className="content_box">
                                            <h3 data-aos-duration="500">{item.heading}</h3>
                                            <p data-aos-duration="500">{item.description}</p>
                                        </div>
                                    </div>
                                    <div className="col_2" data-aos-duration="1500">
                                        <img src={config.REACT_APP_S3 + `${item.img1}`} alt="" />
                                    </div>
                                </section>
                            )
                        })}
                    </div>
                    
                </div>
            </>        
        )
    }
}

export const DesignApproach = injectIntl(DesignApproachPage)