import * as React from "react";
import "./static-header.scss";
import cbreLogo from './../../../assets/CBRE-logo.png'
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import { Link } from "react-router-dom";
import logo from "../../../assets/office-ready-logo-black.svg";

interface StaticHeaderProps extends WrappedComponentProps {

}

class StaticHeaderComponent extends React.PureComponent<StaticHeaderProps> {

  public constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div className="static_header">
        <div className="or_logo"><Link to="/"><img src={logo} /></Link></div>
        <div className="cbre_logo">
          <a href="https://cbre.com.sg" target="_blank" rel="noopener noreferrer">
            <img src={cbreLogo} alt="CBRE" />
          </a>
        </div>
      </div>
    )
  }
}

export const StaticHeader = injectIntl(StaticHeaderComponent);

