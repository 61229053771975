import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { PieChart } from 'react-minimal-pie-chart';
import Add from '../../../assets/icon/Add.png';
import Minus from '../../../assets/icon/Minus.png';
import messages from "../../../translations/messages";
import "./your-program.scss";
import "../headcount/headcount.scss";
import Select from "react-dropdown-select";
import info from "../../../assets/icon/information.png";
import { spaceCalculator } from "../../../helper/space-calculator";
import { CATEGORY, MEASURECATEGORY } from "../../../models/enums";
import { formatNumber } from "../../../helper/helper";
import ReactTooltip from 'react-tooltip'

interface YourProgramProps extends WrappedComponentProps {
  kitOfParts: any;
  spaceProfile: string;
  headCount: any;
  daysInOffice:number;
  officeDensity: number;
  countryCode: string;
  updateQuizResultData(headCount: number, totalSF: number): void;
  getProgramData(programData:any) : void;
}

class YourProgramComponent extends React.PureComponent<YourProgramProps> {

  state = {
    lastHeadCount : 1,
    headCount : '',
    triggerRecalculate: true,
    selectedSpaceGroup: [{ 'label': '', value: ''}],
    selectedUnitGroup: [{ 'label': '', value: ''}],
    windowWidth: 0,
  };

  spaceCalculator : any;
  programResult: any;

  public constructor(props: any) {
    super(props);
    this.state.windowWidth = window.innerWidth;
    this.state.headCount = this.props.headCount;
    this.state.lastHeadCount = this.props.headCount;
    this.state.selectedSpaceGroup = [this.spaceGroups[0]];    
    this.state.selectedUnitGroup = [this.unitGroups[0]];
  }
  handleResize = () =>{
    this.setState({ windowWidth : window.innerWidth});
  }
  componentDidMount() {   
    window.addEventListener("resize", this.handleResize )
    if(this.props.countryCode === 'ES' || this.props.countryCode === 'UK'|| this.props.countryCode === 'FR'){
      this.setState({ selectedUnitGroup: [this.unitGroups[1]]});
    }
  }
  componentWillUnmount(){
    window.addEventListener("resize", this.handleResize );
  }
  spaceGroups = [
    {label: this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSpace"]) , value: "ME"},
    {label: this.props.intl.formatMessage(messages["quizResult.yourProgram.groupSpace"]) , value: "WE"},
    {label: this.props.intl.formatMessage(messages["quizResult.yourProgram.amenitySpace"]) , value: "AMENITY"}
  ]
  unitGroups = [
    { label: this.props.intl.formatMessage(messages["quizResult.yourProgram.Sqft"]), value: "Sqft" },
    { label: this.props.intl.formatMessage(messages["quizResult.yourProgram.Sqmt"]), value: "Sqmt" },
    { label: this.props.intl.formatMessage(messages["quizResult.yourProgram.Sqyd"]), value: "Sqyd" },
  ]
  Increase = () => {
    const _val = this.state.headCount + 1 ;
    this.setState({ headCount: _val, triggerRecalculate : false });
  }

  Decrease = () => {
    const _val = parseInt(this.state.headCount) - 1;
    this.setState({ headCount: _val, triggerRecalculate : false });
  }

  handleHeadcountChange = (event: any) => {
    const _val = parseInt(event.target.value) ? parseInt(event.target.value) : '' ;
    this.setState({ headCount: _val, triggerRecalculate : false });
  }

  handleSpaceGroupChange = (event: any) => {
    this.setState({selectedSpaceGroup: [event[0]], triggerRecalculate : false});
  }
  handleUnitGroupChange = (event: any) => {
    this.setState({ selectedUnitGroup: [event[0]], triggerRecalculate: false });
  }
  isDisableResetSave = () => {
   return this.state.lastHeadCount === parseInt(this.state.headCount) || parseInt(this.state.headCount) > 500
    || this.state.headCount === '';
  }
  
  resetChanges = () => {
    this.setState({ headCount: this.state.lastHeadCount, triggerRecalculate: true });
  }

  saveResult = () => {
    const shouldRecalculate = this.state.headCount !== '' && parseInt(this.state.headCount) <= 500; 
    this.setState({ triggerRecalculate : shouldRecalculate });
    // Action to save result
    this.props.updateQuizResultData(parseInt(this.state.headCount), this.programResult.areaTotals.totalSF);
    this.setState({lastHeadCount: this.state.headCount});
  }
  public render() {   
    
    if(this.state.triggerRecalculate && this.props.kitOfParts) { 
      if(this.spaceCalculator === undefined) {
        this.spaceCalculator = new spaceCalculator(this.props.officeDensity);
      }      
      this.programResult = this.spaceCalculator.calculateProgram(this.props.kitOfParts, this.state.headCount, this.props.daysInOffice);
      this.props.getProgramData(this.programResult);
    }
    const spaceGroupData = this.state.selectedSpaceGroup[0]?.value == CATEGORY.ME ?
                            this.programResult?.ME 
                            : this.state.selectedSpaceGroup[0]?.value == CATEGORY.WE ?
                              this.programResult?.WE
                              : this.programResult?.AMENITY ;
    return (      
      <>
      { this.props.kitOfParts && 
      <div className="row d-flex flex-wrap your_program">
        <div className="col w20per box-bg">
            <span className="tile-header">{this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSeats"])}</span>
            <div className="program-chart">
            { this.state.windowWidth > 500 &&
                <PieChart 
                    data={[
                        { title: this.props.intl.formatMessage(messages["quizResult.yourProgram.workstations"]), value: this.programResult?.finalStats.openPercentage, color: '#003f2d' },
                        { title: this.props.intl.formatMessage(messages["quizResult.yourProgram.offices"]), value: this.programResult?.finalStats.closedPercentage, color: '#DADADA' },
                    ]}       
                />
            }
            { this.state.windowWidth < 500 &&
                <PieChart 
                    data={[
                        { title: this.props.intl.formatMessage(messages["quizResult.yourProgram.workstations"]), value: this.programResult?.finalStats.openPercentage, color: '#003f2d' },
                        { title: this.props.intl.formatMessage(messages["quizResult.yourProgram.offices"]), value: this.programResult?.finalStats.closedPercentage, color: '#DADADA' },
                    ]}       
                />
            }
            </div>
            <div className="chart-label">
                <div className={`${this.state.windowWidth>500 ? 'label mtop20' : 'label'} `}>                    
                    <div className="green_circle">{this.props.intl.formatMessage(messages["quizResult.yourProgram.workstations"])}</div>
                    <div className="right">{this.programResult?.finalStats.openPercentage + '%'}</div>
                </div>
                <div className="label mtop20">
                    <div className="off_circle">{this.props.intl.formatMessage(messages["quizResult.yourProgram.offices"])}</div>
                    <div className="right">{this.programResult?.finalStats.closedPercentage + '%'}</div>
                </div>
            </div>
        </div>
        <div className="col w20per box-bg">
            <span className="tile-header">{this.props.intl.formatMessage(messages["quizResult.yourProgram.collaboration"])}</span>
            <div className="number text-center">
                <div>{formatNumber(this.programResult?.finalStats.collabSeatRatio)}</div>
                <div className="colon">:</div>
                <div>{'1.0'}</div>
            </div>
            <div className="desc">
                <div>{this.props.intl.formatMessage(messages["quizResult.yourProgram.enclosedGroupSeats"])}</div>
                <div className={`${this.state.windowWidth>500 ? 'right' : 'left-sub-heading'} `}>{this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSeats"])}</div>
            </div>
        </div>
        <div className="col w20per box-bg">
            <span className="tile-header">{this.props.intl.formatMessage(messages["quizResult.yourProgram.seatsPerPerson"])}</span>
            <div className="number text-center">
                <div>{formatNumber(this.programResult?.finalStats.sharingRatio)}</div>
                <div className="colon">:</div>
                <div>{'1.0'}</div>
            </div>
            <div className="desc">
                <div>{this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSeats"])}</div>
                <div className={`${this.state.windowWidth>500 ? 'right' : 'left-sub-heading'} `}>{this.props.intl.formatMessage(messages["quizResult.yourProgram.persons"])}</div>
            </div>
        </div>
        <div className="col box-bg w20per" id="density-col">
            <span className="tile-header">{this.props.intl.formatMessage(messages["quizResult.yourProgram.density"])}</span>
            <div className="number text-center">
              {this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQFT ? Math.round(this.programResult?.finalStats?.usfPerSeat) + ' ' : (this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQMT ? (this.programResult?.finalStats?.usfPerSeat * 0.092903).toFixed() + ' ' : (this.programResult?.finalStats?.usfPerSeat * 0.111111).toFixed() + ' ')}
             </div>
            <div className="desc text-center">
              {this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQFT ? this.props.intl.formatMessage(messages["quizResult.yourProgram.densityDescriptionUSF"]) : this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQMT ? this.props.intl.formatMessage(messages["quizResult.yourProgram.densityDescriptionSM"]) : this.props.intl.formatMessage(messages["quizResult.yourProgram.densityDescriptionSY"])}                  
             </div>
        </div>
      </div>
      }
      <div className="your-program-bg">
      <div className="row">
          <div className={`${this.state.windowWidth>500 ? 'row-heading mtop20' : 'your-program-tile-header'} `}>{this.state.windowWidth>500 ? this.props.intl.formatMessage(messages["quizResult.yourProgram.customizeYourProgram"]) : this.props.intl.formatMessage(messages["quizResult.actionLink.yourProgramDetails"])}</div>
          <div className={`${this.state.windowWidth>500 ? 'row-desc' : 'row-desc program-text text-center'} `}>{this.state.windowWidth>500 ? this.props.intl.formatMessage(messages["quizResult.yourProgram.customizeYPDescription"]) : this.props.intl.formatMessage(messages["quizResult.yourProgram.customizeYPDescriptionMobile"])}</div>
      </div>
      <div className="row no_wrap_ipad">
          <div className="col hc-container" id="headcount-container">
                <span className="mrig20">{this.props.intl.formatMessage(messages["quizResult.yourProgram.headcount"])}</span>
                <div className="headcount-comp" id="headcount-comp-container">
                    <button onClick={this.Decrease} className="decrease" disabled={this.state.headCount == '1'|| this.state.headCount==''}> <img src={Minus} className={`${this.state.headCount == '1' || this.state.headCount == '' ? "disabled" : "" }`}/> </button>
                    <input type="number" value={this.state.headCount} onInput={(e:any) => e.target.value = e.target.value.slice(0, 5)} className="headcount" onChange={this.handleHeadcountChange} 
                    onKeyDown={ (e) => ( e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 ) && e.preventDefault() } />
                    <button onClick={this.Increase} className="increase" disabled={this.state.headCount >='500'}><img src={Add} className={`${this.state.headCount >= '500'  ? "disabled" : null}`}/> </button>
                </div>
                {parseInt(this.state.headCount) > 500 && <div className="headcount-message-your-pogram text-center">{this.props.intl.formatMessage(messages["quiz.questions.headcount"])}</div>}
                <div className="btn-headcount-section text-center mbot20">
                {this.state.windowWidth<500 && <button className={`primary mdc-button mdc-button--outlined custom-outlined-button mdc-ripple-upgraded btn-sec ${this.isDisableResetSave() ? "disabled" :""}`} onClick={this.resetChanges} disabled={this.isDisableResetSave()}>{this.props.intl.formatMessage(messages["quizResult.yourProgram.reset"])}</button>}
                {this.state.windowWidth<500 && <button className={`primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded mlef20 ${this.isDisableResetSave() ? "disabled" :""}`} onClick={this.saveResult} disabled={this.isDisableResetSave()}>{this.props.intl.formatMessage(messages["quizResult.yourProgram.saveChanges"])}</button>}
              </div>
          </div>          
          {this.state.windowWidth<500 && <div className="your-program-border"></div>}
          <div className={`${this.state.windowWidth>500 ? 'col border' : 'office-space mtop20'} `} id="recommended-office-section">
            <span className="row-desc" id="office-space-title">{this.props.intl.formatMessage(messages["quizResult.yourProgram.recommendedOfficeSize"])}</span>
            <div className="office-size-container">
            <div className="program-section-colon" >:</div>
                <div className="d-flex">
                  <div className="row-heading1">
                    {this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQFT ? this.programResult?.areaTotals?.totalSF + ' ' : (this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQMT ? (this.programResult?.areaTotals?.totalSF * 0.092903).toFixed() + ' ' : (this.programResult?.areaTotals?.totalSF * 0.111111).toFixed() + ' ')}
                    <div className="unit-select-handle" >
                      <Select values={this.state.selectedUnitGroup} onChange={this.handleUnitGroupChange} options={this.unitGroups} searchable={false} />
                    </div>
                    { this.props.countryCode !=='ES' && this.props.countryCode !== 'FR' && 
                    <span className='wd_unit'>
                      {this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQFT ? this.props.intl.formatMessage(messages["quizResult.yourProgram.usf"]) : this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQMT ? this.props.intl.formatMessage(messages["quizResult.yourProgram.nsm"]) : this.props.intl.formatMessage(messages["quizResult.yourProgram.nsy"])}
                      { this.state.selectedUnitGroup[0]?.value != MEASURECATEGORY.SQMT && 
                        <>
                          <img src={info} className="abbr" data-tip={this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQFT ? this.props.intl.formatMessage(messages["quizResult.yourProgram.usfTitle"]) : this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQMT ? '' : this.props.intl.formatMessage(messages["quizResult.yourProgram.nsyTitle"])} alt="" />
                          <ReactTooltip scrollHide={true} resizeHide={true} borderColor={'green'} place={'top'} type={'light'} class={"custom-tool-tip"} offset={{ top: 5, left: 7 }} />
                        </>
                      }
                    </span>
                    }
                  </div>
                </div>
              </div>
            </div>
          <div className={`${this.state.windowWidth>500 ? 'col' : 'office-space'} `}>
            <span className="row-desc" id="office-space-title">{this.props.intl.formatMessage(messages["quizResult.yourProgram.profile"])}</span>
            <div className="office-size-container">
            <div className="program-section-colon">:</div>
            <div className={`${this.state.windowWidth<405 ? 'row-heading1 capitalise row-heading-title' : 'row-heading1 capitalise'} `}>{this.props.countryCode !== 'ES' && this.props.countryCode !== 'FR' && this.props.intl.formatMessage(messages["global.the"])} {this.props.spaceProfile?.toLowerCase()}</div>
          </div>
          </div>
      </div>
                    
      <div className={`row ${this.props.countryCode === 'FR' ? 'd-flex align-items-center' : ''}`}>
        <div className={`row-section ${this.props.countryCode === 'FR' ? 'd-flex flex_grow mlef4Per' : ''}`}>
          <button className={`primary mdc-button mdc-button--outlined custom-outlined-button mdc-ripple-upgraded mlef4Per ${this.isDisableResetSave() ? "disabled" :""}`} onClick={this.resetChanges} disabled={this.isDisableResetSave()}>{this.props.intl.formatMessage(messages["quizResult.yourProgram.reset"])}</button>
          <button className={`primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded ${this.isDisableResetSave() ? " disabled" :""}`} onClick={this.saveResult} disabled={this.isDisableResetSave()}>{this.props.intl.formatMessage(messages["quizResult.yourProgram.saveChanges"])}</button>
        </div>
        {this.props.countryCode === 'FR' &&
          <div className="disclaimer grossarea">
            <span>{this.props.intl.formatMessage(messages["global.disclaimer"]) + ':'}</span> 
            {this.props.intl.formatMessage(messages["quizResult.yourProgram.grossAreaNote"])}
          </div>
        }
      </div>
      <div className="graph-section">
        {this.state.windowWidth>500 &&
      <div className="row mtop50 mbot10">
        <div className="space-group mrig20">
          <div className="square individual-bg"></div>
          <span className="tile-header individual">{this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSpace"])}</span>
        </div>
        <div className="space-group mrig20">
          <div className="square group-bg"></div>
          <span className="tile-header group">{this.props.intl.formatMessage(messages["quizResult.yourProgram.groupSpace"])}</span>
        </div>
        <div className="space-group">
          <div className="square amenity-bg"></div>
          <span className="tile-header amenity">{this.props.intl.formatMessage(messages["quizResult.yourProgram.amenitySpace"])}</span>
        </div>
      </div>
  }
            <div className="row" id="space-data">
              <div className="space-graph-row">
                {this.programResult?.areaTotals.mePercentage > 0 && <div className={`${this.state.windowWidth > 500 ? 'space-group text-center' : 'space-group'} `} style={{ width: this.programResult?.areaTotals.mePercentage + '%' }}>
                  <div className="space-bar individual-bg" title={this.programResult?.areaTotals.mePercentage + '%'}>{this.state.windowWidth > 500 ? this.programResult?.areaTotals.mePercentage + '%' : ''}</div>
                </div>
                }
                {this.programResult?.areaTotals.wePercentage > 0 && <div className={`${this.state.windowWidth > 500 ? 'space-group text-center' : 'space-group'} `} style={{ width: this.programResult?.areaTotals.wePercentage + '%' }}>
                  <div className="space-bar group-bg" title={this.programResult?.areaTotals.wePercentage + '%'}>{this.state.windowWidth > 500 ? this.programResult?.areaTotals.wePercentage + '%' : ''}</div>
                </div>
                }
                {this.programResult?.areaTotals.amenityPercentage > 0 && <div className={`${this.state.windowWidth > 500 ? 'space-group text-center' : 'space-group'} `} style={{ width: this.programResult?.areaTotals.amenityPercentage + '%' }}>
                  <div className="space-bar amenity-bg" title={this.programResult?.areaTotals.amenityPercentage + '%'}>{this.state.windowWidth > 500 ? this.programResult?.areaTotals.amenityPercentage + '%' : ''}</div>
                </div>
                }
              </div> {this.state.windowWidth < 500 &&
                <div className="space-program-section">
                  <div className="space-program-description">
                    <span className="row-percent individual">{this.programResult?.areaTotals.mePercentage + '%'}</span>
                    <span className="program-heading individual">{this.props.intl.formatMessage(messages["quizResult.yourProgram.individualSpace"])}</span></div>
                  <div className="space-program-description">
                    <span className="row-percent group">{this.programResult?.areaTotals.wePercentage + '%'}</span>
                    <span className="program-heading group">{this.props.intl.formatMessage(messages["quizResult.yourProgram.groupSpace"])}</span></div>
                  <div className="space-program-description">
                    <span className="row-percent amenity">{this.programResult?.areaTotals.amenityPercentage + '%'}</span>
                    <span className="program-heading amenity">{this.props.intl.formatMessage(messages["quizResult.yourProgram.amenitySpace"])}</span></div>
                </div>
              }
            </div>
          </div>
          {this.state.windowWidth<500 && <div className="your-program-border"></div>}
      <div className="row">
        <div className="space-group-div">
          <div className="space-group-dropdown">
            <div className="heading">{this.props.intl.formatMessage(messages["quizResult.yourProgram.spaceGroupDesc.text"])}</div>
            <div className="space-group-heading">{this.props.intl.formatMessage(messages["quizResult.yourProgram.spaceGroupDesc.heading"])}</div>

            <Select values={this.state.selectedSpaceGroup} onChange={this.handleSpaceGroupChange} options={this.spaceGroups} className="select" searchable={false}/>
           
          </div>
          <div className="space-group-data-div">
            { spaceGroupData?.map((item: any, index: number) => {              
              return (
                <div className="space-group-item" key={index}>
                  <div className="w30per">{item.spaceUnitName}</div>
                  <div className="w10per">{(this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQFT) ? item.sf + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SF"]) : ((this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQMT) ? (item.sf * 0.092903).toFixed() + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SM"]) : (item.sf * 0.111111).toFixed() + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SY"]))}</div>
                  <div className="w10per">{'X'}</div>
                  <div className="w10per">{item.quantity}</div>
                  <div className="w10per">{this.props.intl.formatMessage({id:'global.'+item.unitLabel})}</div>
                  <div className="w10per">{'='} </div>
                  <div className={`${this.state.windowWidth > 500 ? 'w20per' : 'w13per'} `}>{this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQFT ? (item.sf * item.quantity) + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SF"]) : (this.state.selectedUnitGroup[0]?.value == MEASURECATEGORY.SQMT ? ((item.sf * item.quantity) * 0.092903).toFixed() + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SM"]) : ((item.sf * item.quantity) * 0.111111).toFixed() + ' ' + this.props.intl.formatMessage(messages["quizResult.yourProgram.SY"]))}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      </div>
      <div className="row disclaimer">
        <span>{this.props.intl.formatMessage(messages["global.disclaimer"]) + ':'}</span>
        {this.props.intl.formatMessage(messages["quizResult.yourProgram.disclaimer"])}
      </div>
      </>
    )
  }

}

export const YourProgram = injectIntl(YourProgramComponent);