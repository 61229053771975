import * as React from "react";
import logo from "../../../assets/office-ready-logo-black.svg";
import { Link } from "react-router-dom";
import routes from "../../route/routes";
import "./error.scss";
import { Footer } from "../../common/footer/footer";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";

interface ErrorComponentProps extends WrappedComponentProps {
  location: any;
}
class ErrorComponent extends React.Component<ErrorComponentProps> {

  public constructor(props: any) {
    super(props);
  }

  errorCode = this.props.location.state?.errorCode;

  public render() {
    return (
      <>
        <div className="error-header">
          <div className="error-header-logo">
            <Link to={routes.home}>
              <img src={logo} alt="logo" className="logo" />
            </Link>
          </div>
        </div>
        <div className="error-container">
          <div className="error-heading">
            { this.errorCode === 500 ? this.props.intl.formatMessage(messages["error.heading1"]) : this.props.intl.formatMessage(messages["error.heading2"])}            
          </div>
          <p className="error-paragraph">
            { this.errorCode === 500 
              ? this.props.intl.formatMessage(messages["error.description1"]) 
              : this.props.intl.formatMessage(messages["error.description2"])
            }
          </p>
        </div>
        <div className="error-button-container">
          <a className="back-btn" href={routes.home}>
          {this.props.intl.formatMessage(messages["global.backtohome"])}</a>
          <a className="contact-btn" href={`mailto:${this.props.intl.formatMessage(messages["global.supportEmail2"])}`} target="_blank" rel="noopener noreferrer">{this.props.intl.formatMessage(messages["global.contact"])}</a>
        </div>
        <Footer isDisabled={true} />
      </>
    );
  }
}
export const ErrorPage = injectIntl(ErrorComponent);

