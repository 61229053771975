import { ActionType } from "./action-type";
import { Dispatch } from "redux";
import { AppAction } from "../models/app-action";
import { get ,post, put } from "../services/api-client";
import { isUUID } from "../helper/helper";

export const setCountry = (countryCode: string, languageCode: string) => {
  return (dispatch: Dispatch<AppAction>) => {
    dispatch({
      type: ActionType.SET_COUNTRY,
      payload: { countryCode : countryCode, languageCode: languageCode}
    });
  }; 
}

export const getSpaceQuizQuestions = (countryCode: string) => {
  return (dispatch: Dispatch<AppAction>) => {
    const _spaceQuizQuestion = get('quiz/question/space/'+ countryCode);
    _spaceQuizQuestion.then( data => {
      if(data.message !== undefined ) {
        dispatch({
          type: ActionType.GET_SPACE_QUIZ_QUESTIONS_FAILURE,
          payload: { }
        });
      } else {
        const _quizData = data;
        if (_quizData.questions !== null) {
          _quizData.questions.map((ele: any) => {
            ele.questionType = 'space';
          });
      }
        dispatch({
          type: ActionType.GET_SPACE_QUIZ_QUESTIONS_SUCCESS,
          payload: {
            spaceQuizData : _quizData
          }
        });
      }
    });
  };  
};


export const getLocationList = (countryCode: string) => {
  return (dispatch: Dispatch<AppAction>) => {
    const location =get('quiz/question/space/location/' + countryCode);
    location.then( data => {
      dispatch({
        type: ActionType.GET_LOCATION_QUESTIONS_SUCCESS,
        payload: {
          locationData : data
        }
      });
    });

  };  
};

export const getIndustrialList = (countryCode: string) => {
  return (dispatch: Dispatch<AppAction>) => {
    const industrialDataTest = get('quiz/question/space/sector/' + countryCode);
    industrialDataTest.then( data => {
      dispatch({
        type: ActionType.GET_INDUSTRIAL_QUESTIONS_SUCCESS,
        payload: {
          industrialData : data.sectors
        }
      });
    })
  };  
};

export const getDesignQuizQuestions = (countryCode: string) => {
  return (dispatch: Dispatch<AppAction>) => {
    const _designQuizQuestion =  get('quiz/question/design/' + countryCode);
    _designQuizQuestion.then( data => {
      if(data.message !== undefined) {
        dispatch({
          type: ActionType.GET_DESIGN_QUIZ_QUESTIONS_FAILURE,
          payload: { }
        });
      } else {
        const _quizData = data;
        if (_quizData.questions != null) {
          _quizData.questions.map((ele: any) => {
            ele.questionType = 'design';
            return ele;
          });
        }
        dispatch({
          type: ActionType.GET_DESIGN_QUIZ_QUESTIONS_SUCCESS,
          payload: {            
            designQuizData : _quizData
          }
        });
      }
    });
  };  
};

export const postSpaceQuizAnswers = (spaceQuizAnswers:any) => {
  return (dispatch: Dispatch<AppAction>) => {
    const _quizAnswers = post('quiz', spaceQuizAnswers );
    _quizAnswers.then( data => {
      if (data === '') {
        dispatch({
          type: ActionType.SAVE_SPACE_QUIZ_ANSWERS_FAILURE,
          payload: {}
        });
      } else {
      dispatch({
        type: ActionType.SAVE_SPACE_QUIZ_ANSWERS_SUCCESS,
        payload: { }
      });
    }
    });
  };  
};

export const postQuizAnswers = (quizID:string, quizAnswers:any) => {
  return (dispatch: Dispatch<AppAction>) => {
    const _quizAnswers = quizID !== '' && isUUID(quizID) ?  put(`quiz/${quizID}`, quizAnswers) : post('quiz', quizAnswers );
    _quizAnswers.then( data => {
      if(data === '') {
        dispatch({
          type: ActionType.SAVE_QUIZ_ANSWERS_FAILURE,
          payload: { }
        });
      } else {
        dispatch({
          type: ActionType.SAVE_QUIZ_ANSWERS_SUCCESS,
          payload: {
            quizId : quizID !== '' ? quizID : data
          }
        });
      }
    });
  };  
};

export const getQuizAnswersData = (quizId:string) => {
  return (dispatch: Dispatch<AppAction>) => {
    const _quizAnswers = get('quiz/'+ quizId);
    _quizAnswers.then( data => {     
      const qad = data;
      dispatch({
        type: ActionType.GET_QUIZ_ANSWERS_SUCCESS,
        payload: {
          quizAnswersData : qad
        }
      });
    });
  };  
};

export const getQuizResultPageData = (quizId:string) => {
  return (dispatch: any) => {
    const _quizResultData = get('result/'+ quizId);
    _quizResultData.then( data => {
      if(data.message !== undefined) {
        dispatch({
          type: ActionType.GET_QUIZ_RESULT_DATA_FAILURE,
          payload: { }
        });
      } else {
        dispatch({
          type: ActionType.GET_QUIZ_RESULT_DATA_SUCCESS,
          payload: {
            quizResultData : data
          }
        });
        if(data.result.countryCode !== null) {
          if(data.result.spaceProfile !== undefined && data.result.designProfile !== undefined) {
            dispatch(getProfileData(data.result.spaceProfile, data.result.designProfile, data.result.countryCode));
          }
          dispatch(getcostEstimate(data.result.countryCode));
          dispatch(getLocationList(data.result.countryCode));
        }
      }
    });
  };  
};

export const getQuizResultData = (quizId:string) => {
  return (dispatch: Dispatch<AppAction>) => {
    const _quizResultData = get('result/'+ quizId);
    _quizResultData.then( data => {
      if(data.message !== undefined) {
        dispatch({
          type: ActionType.GET_QUIZ_RESULT_DATA_FAILURE,
          payload: { }
        });
      } else {
        dispatch({
          type: ActionType.GET_QUIZ_RESULT_DATA_SUCCESS,
          payload: {
            quizResultData : data
          }
        });
      }
    });
  };  
};

export const getProfileData = (spaceProfile:string, designProfile: string, countryCode: string) => {
  return (dispatch: Dispatch<AppAction>) => {
    const _profileData = get(`result/profile/${spaceProfile}&${designProfile}/${countryCode}`);
    _profileData.then( data => {
      dispatch({
        type: ActionType.GET_PROFILE_DATA_SUCCESS,
        payload: {
          profileData: data
        }
      });
    });
  };  
};

export const quizStart = () => {
  return (dispatch: Dispatch<AppAction>) => {
      dispatch({
        type: ActionType.QUIZ_START,
        payload: { }
    });
  };  
};

export const postContactDesignerData = (contactDesignerData:any) => {
  return (dispatch: Dispatch<AppAction>) => {
    const _contactData = post('result/review', contactDesignerData );
    _contactData.then( data => {
      dispatch({
        type: ActionType.SAVE_CONTACT_DESIGNER_DATA_SUCCESS,
        payload: { }
      });
    });
  };
};

export const getcostEstimate = (countryCode: string) => {
  return (dispatch: Dispatch<AppAction>) => {
    const costEstimate = get('result/costmetrics/'+ countryCode);
    costEstimate.then(data => {
      dispatch({
        type: ActionType.GET_COST_ESTIMATE_SUCCESS,
        payload: {
          costEstimateData: data
        }
      });
    })
  };
};

export const updateQuizResultData = (quizID: string, quizResultData:any) => {
  return (dispatch: Dispatch<AppAction>) => {
    const _quizResultData = put(`result/${quizID}` , quizResultData );
    _quizResultData.then( data => {
      dispatch({
        type: ActionType.SAVE_QUIZ_RESULT_DATA_SUCCESS,
        payload: { }
      });
    });
  };
};

export const setClientInfo = (clientInfo: any) => {
  return (dispatch: Dispatch<AppAction>) => {
      dispatch({
        type: ActionType.SET_CLIENTINFO,
        payload: { clientInfo: clientInfo }
    });
  };  
};