import * as React from "react";
import "./popup-styles.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import arrowBt from "../../../../src/assets/icon/down-bt-arrow.svg";
import industrial from "../../../../src/assets/icon/industrial.svg";
import fresh from "../../../../src/assets/icon/fresh.svg";
import organic from "../../../../src/assets/icon/organic.svg";
import classic from "../../../../src/assets/icon/classic-img.svg";
import expressive from "../../../../src/assets/icon/expressive.png";
import effortless from "../../../../src/assets/icon/effortless.png";
import elegant from "../../../../src/assets/icon/elegant.png";
import essential from "../../../../src/assets/icon/essential.png";
import { PopupProfile } from "../../common/popup-profile/popup-profile";

import { PageModal } from "../page-modal/pageModal";

interface PopupProfileProps extends WrappedComponentProps {
    others: any,
    countryCode: string;
}

export class PopupStylesComponent extends React.PureComponent<PopupProfileProps> {

    public constructor(props: any) {
        super(props);
    }
    state = {
        isProfileOpen: false,
        selectedProfile: Object,
        isOpenModal:false,
        palette:'',
        pageName:'',
    }
    onPopupClose = () => {
        document.body.classList.remove('overflow-hidden');
        this.setState({ isProfileOpen: false });
    }

    onPopupclick = (profile: any) => {        
        document.body.classList.add('overflow-hidden');
        this.setState({ isProfileOpen: true, selectedProfile: profile });         
    }

    capitalizeFirstLetter = (string: any) => {
        return string
            .toLowerCase()
            .split(' ')
            .map(function (word: any) {
                return word[0].toUpperCase() + word.substr(1);
            })
            .join(' ');
    }

    styleImage = (styleImageName: any) => {
        switch (styleImageName) {
            case "INDUSTRIAL": return industrial;
            case "CLASSIC": return classic;
            case "ORGANIC": return organic;
            case "FRESH": return fresh;
            case "EXPRESSIVE": return expressive;
            case "EFFORTLESS": return effortless;
            case "ELEGANT": return elegant;
            case "ESSENTIAL": return essential;
        }
    }
    handProfileClick =(profile:string) =>{
        this.setState({pageName:profile});
        this.handleModalOpen();
    }
    handleModalOpen = () => 
    {
        this.setState({isOpenModal:true});
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
      };
    handleModalClose = () => {
       this.setState({isOpenModal:false})
    };
     
    public render() {

        return (this.props.others && this.props.others.length > 0 ? 
        <>
        <div className="row-profile">
            <div className="styles-heading"> {this.props.intl.formatMessage(messages["quizResult.popupstyle.lookingStyle"])}</div>
            {this.state.isProfileOpen && <PopupProfile onPopupClose={this.onPopupClose} profile={this.state.selectedProfile} countryCode={this.props.countryCode}/>}
            <div className="pop_styles_parent">
            {this.props.others.map((profile: any, index: any) => (
                <div className="colStyle box-bg-style" onClick={this.props.countryCode === 'SG'? () => this.handProfileClick(profile.profileName) : () => this.onPopupclick(profile)} key={index}>
                    { this.props.countryCode === 'SG' && 
                    <div className={`heading ${profile.profileName?.toLowerCase()}`}>{profile.profileName?.toLowerCase()}</div>
                    }
                    <div className="img-div">
                        <img src={this.styleImage(profile.profileName)} className="img-other" alt="" />
                    </div>
                    <div className="text-div">
                        <div className="heading capitalize-word">{profile.profileName?.toLowerCase()}</div>
                        <div className="heading-text">{profile.profileDescription.split(/\n/)[0]}</div>
                        <div className="heading-text-readmore"> 
                        {this.props.intl.formatMessage(messages["quizResult.popupstyle.Readmore"])}  
                            { this.props.countryCode === 'ES' || this.props.countryCode === 'FR'
                                ? ' ' + this.props.intl.formatMessage(messages["quizResult.popupstyle.Style"]) +' '+ this.capitalizeFirstLetter(profile.profileName)
                                : ' ' + this.capitalizeFirstLetter(profile.profileName) + ' ' + this.props.intl.formatMessage(messages["quizResult.popupstyle.Style"])
                            }
                            <img className="style-Button" id={profile.profileName} src={arrowBt} alt="" />
                        </div>
                    </div>
                </div>
            ))}
        </div> 
        </div>
        {this.state.isOpenModal && <PageModal pageName={this.state.pageName} handleModalClose={this.handleModalClose} isOpenModal={this.state.isOpenModal} handleModalOpen={this.handleModalOpen} palette={this.state.palette} />}
                
       </> 
        :
         null);
    }
}

export const PopupStyles = injectIntl(PopupStylesComponent);

