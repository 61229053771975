import * as React from "react";
import "./popup-profile.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import ReactPlayer from "react-player";
import appConfig from "../../../helper/app-config";

const config = appConfig();

interface PopupProfileProps extends WrappedComponentProps {
  profile: any
  onPopupClose(): void;
  countryCode: string;
}

export class PopupProfileComponent extends React.PureComponent<PopupProfileProps> {

  public constructor(props: any) {
    super(props);
  }
  state = {
    controls: true,
    playing: true,
  }
  onPopupClose = () => {
    if (this.props.onPopupClose !== null && this.props.onPopupClose !== undefined) {
      this.props.onPopupClose();
    }
  }
  public render() {

    return (
      <div className="profilepopup" >
        <div className="profile-content">
          <div className="close-profile" onClick={this.onPopupClose}>&times;</div>
          <div className="row-profile">
            <div className="profile-heading">{this.props.profile.profileName} { this.props.countryCode !== 'ES' && this.props.countryCode !== 'FR' && this.props.intl.formatMessage(messages["quizResult.popupstyle.ProfileText"])} </div>
            <div className="col-dec-profile ">
              {this.props.profile.profileDescription}
            </div>
            <div className="col-img-profile">
              <img src={config.REACT_APP_S3 + atob(this.props.profile?.pallete)} alt="" className="img-profile" />
            </div>
            <div className="clearfix"></div>
            <div className="selected-profile-video">
            <ReactPlayer url={this.props.profile?.profileVideo ? atob(this.props.profile?.profileVideo) : ''} controls={this.state.controls}
              width='100%' height='100%' light={this.props.profile?.thumbnail ? config.REACT_APP_S3 + atob(this.props.profile?.thumbnail) : true} playing={this.state.playing} />
          </div>
          <div className="clearfix"></div>
          </div>
        </div>
      </div>
    )
  }
}

export const PopupProfile = injectIntl(PopupProfileComponent);

