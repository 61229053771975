import * as React from "react";
import CBRElogo from "../../../assets/CBRE-logo.png";
import "./footer.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "../../../translations/messages";
import { CountryLanguage } from "../country-language-settings/country-language-settings";
import routes from "../../route/routes";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

interface FooterProps extends WrappedComponentProps {
  countryCode?: string;
  isDisabled? : boolean;
}

class FooterComponent extends React.PureComponent<FooterProps> {
  public constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <>
      <div className="footer-container"></div>
      <div className="footer">
        <div className="footer-row">
          <div className="footer-col1">
            <img src={CBRElogo} alt="" className="" />
            <div className="social-links mtop20">
              <a href="https://www.facebook.com/cbre/" target="_blank" rel="noopener noreferrer"><i className="icon facebook"></i></a>
              <a href="https://twitter.com/CBREworkplace" target="_blank" rel="noopener noreferrer"><i className="icon twitter"></i></a>
              <a href="https://www.linkedin.com/company/cbre" target="_blank" rel="noopener noreferrer"><i className="icon linkedIn"></i></a>
            </div>
          </div>
          <div className="footer-col2">
            <div className="footer-item">
              <div className="heading">{this.props.intl.formatMessage(messages["global.questions"])}</div>

              <div className="sub-heading">{this.props.intl.formatMessage(messages["global.contactGeneralEnquiries"])}</div>
              <a href={`mailto:${this.props.intl.formatMessage(messages["global.supportEmail1"])}`} target="_blank" rel="noopener noreferrer">
                {this.props.intl.formatMessage(messages["global.supportEmail1"])}
              </a>
              
              <div className="sub-heading">{this.props.intl.formatMessage(messages["global.contactITSupport"])}</div>
              <a href={`mailto:${this.props.intl.formatMessage(messages["global.supportEmail2"])}`} target="_blank" rel="noopener noreferrer">
                {this.props.intl.formatMessage(messages["global.supportEmail2"])}
              </a>
            </div>
            <div className="footer-item">
              <div className="heading">{this.props.intl.formatMessage(messages["global.resources"])}</div>
                <a href={this.props.intl.formatMessage(messages["footer.aboutOfficeready"])} target="_blank" rel="noopener noreferrer" className="link-item link">
                  {this.props.intl.formatMessage(messages["global.aboutOfficeReady"])}
                </a>              
                <a href={this.props.intl.formatMessage(messages["footer.insights"])} target="_blank" rel="noopener noreferrer" className="link-item link">
                  {this.props.intl.formatMessage(messages["global.insights"])}
                </a>
                <a href={this.props.intl.formatMessage(messages["footer.cbreDesign"])} target="_blank" rel="noopener noreferrer" className="link-item link">
                  {this.props.intl.formatMessage(messages["global.whoIsCBREDesign"])}
                </a>              
            </div>
            <div className="footer-item">
              <div className="heading">{this.props.intl.formatMessage(messages["global.more"])}</div>
              <div><CountryLanguage isDisabled={this.props.isDisabled}/> </div>
              <Link to={routes.termsAndConditions + '/' + this.props.countryCode} className="link-item link" target="_blank" rel="noopener noreferrer">
                {this.props.intl.formatMessage(messages["global.termsAndCondition"])}
              </Link>
              <a href={this.props.intl.formatMessage(messages["footer.privacyPolicy"])} target="_blank" rel="noopener noreferrer" className="link-item link">
                {this.props.intl.formatMessage(messages["global.privacyPolicy"])}
              </a>
            </div>            
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {    
    countryCode : state.quiz.countryCode
  }
}

export const Footer = injectIntl(connect(mapStateToProps, null)(FooterComponent));
