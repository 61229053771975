export const CATEGORY = {
    ME: 'ME',
    WE: 'WE',
    SUPPORT: 'SUPPORT',
    AMENITY: 'AMENITY'
  };
  
  export const MEASURECATEGORY = {
    SQFT: 'Sqft',
    SQMT: 'Sqmt',
    SQYd: 'Sqyd',
  };

  export const CIRCULATION_TYPE = {
    ENCLOSED: 'enclosed',
    OPEN: 'open',
    BLENDED: 'blended',
    AMENITY: 'amenity'
  };
  
  export const STANDARD_CIRCULATION_MAP = {
    [CIRCULATION_TYPE.ENCLOSED]: 0.25,
    [CIRCULATION_TYPE.OPEN]: 0.45,
    [CIRCULATION_TYPE.AMENITY]: 0.35
  };
  
 export const HIGH_CIRCULATION_MAP = {
    [CIRCULATION_TYPE.ENCLOSED]: 0.30,
    [CIRCULATION_TYPE.OPEN]: 0.50,
    [CIRCULATION_TYPE.AMENITY]: 0.35
  };

export const AppErrors = {
  GET_SPCAE_QUIZ_API_ERROR : 'OR001',
  GET_DESIGN_QUIZ_API_ERROR : 'OR002',
  GET_QUIZ_RESULT_API_ERROR : 'OR003',
  SAVE_QUIZ_ANSWERS_API_ERROR : 'OR004',
  SAVE_SPACE_QUIZ_API_ERROR : 'OR005'
}

export const countries : Array<any> = [  
  {
    countryName : "USA",
    countryCode : "US",
    languages : [
      {languageName: 'English', languageCode: 'en-US', default: true}
    ]
  },
  {
    countryName : "Spain",
    countryCode : "ES",
    languages : [
      {languageName: 'Spanish', languageCode: 'es-ES', default: true}
    ]
  },
  {
    countryName : "Singapore",
    countryCode : "SG",
    languages : [
      {languageName: 'English', languageCode: 'en-SG', default: true}
    ]
  },
  {
    countryName : "UK",
    countryCode : "UK",
    languages : [
      {languageName: 'English', languageCode: 'en-UK', default: true}
    ]
  },
  {
    countryName : "France",
    countryCode : "FR",
    languages : [
      {languageName: 'French', languageCode: 'fr-FR', default: true}
    ]
  },
].sort((a, b) => a.countryName.localeCompare(b.countryName));
