import React from "react";
import { WrappedComponentProps, injectIntl } from "react-intl";
import "./next-prev.scss";
import messages from "../../translations/messages";

interface NextPrevComponentProps extends WrappedComponentProps {
    isPrevVisible: boolean;
    isNextVisible: boolean;
    isSubmitVisible: boolean;
    isContinueVisible: boolean;
    isNextEnabled: boolean;
    isSubmitEnabled: boolean;
    isViewResultVisible: boolean;
    movePrevStep(): void;
    moveNextStep(): void;
    handleSubmit(): void;
    position?:string; // center/top/bottom
}

class NextPrevComponent extends React.Component<NextPrevComponentProps> {

    public constructor(props: NextPrevComponentProps) {
        super(props);
        this.movePrevStep = this.movePrevStep.bind(this);
        this.moveNextStep = this.moveNextStep.bind(this);
    }    

    getPositionStyle = () => {
        if(this.props.position && this.props.position === 'center') {
            return { bottom: '50%' }
        } else if(this.props.position && this.props.position === 'top') {
            return { top: '100px'  }
        } else {
           return window.screen.width < 500 ? { bottom: '0px' } : { bottom: '40px'}
        }
    }

    movePrevStep = () => {
        this.props.movePrevStep();
    }

    moveNextStep = () => {
        this.props.moveNextStep();
    }

    handleSubmit = () => {        
        this.props.handleSubmit();
    }

    public render() {
        return (
            <div className="quiz-footer mtop20" style={this.getPositionStyle()}>
                <div className="btn-container">
                    { this.props.isPrevVisible && (
                        <button className="primary mdc-button mdc-button--outlined custom-outlined-button mdc-ripple-upgraded prev" onClick={this.movePrevStep}>
                            {this.props.intl.formatMessage(messages["global.previous"])}
                        </button>
                    )}
                    {!this.props.isSubmitVisible && this.props.isNextVisible && (
                        <button className={ this.props.isNextEnabled ? "primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded next" :"primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded  next disabled" } onClick={this.moveNextStep} disabled={!this.props.isNextEnabled}>
                            {this.props.intl.formatMessage(messages["global.next"])}
                        </button>
                    )}
                    { this.props.isSubmitVisible &&(
                        <button className={ this.props.isSubmitEnabled ? "primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded next" :"primary mdc-button mdc-button--unelevated custom-flat-button mdc-ripple-upgraded  next disabled" } onClick={this.handleSubmit} disabled={!this.props.isSubmitEnabled}>
                        { this.props.isContinueVisible ? this.props.intl.formatMessage(messages["global.continue"])
                        : ( this.props.isViewResultVisible 
                            ? this.props.intl.formatMessage(messages["global.viewResult"])
                            : this.props.intl.formatMessage(messages["global.submit"])
                            )
                    }
                        </button>
                    )}
                </div>
            </div>

        );
    }
}

export const NextPrev = injectIntl(NextPrevComponent);
