import React from "react";
import { WrappedComponentProps } from "react-intl";
import { Modal } from "../common/modal/modal";
//import logo from "../../assets/office-ready-logo-black.svg";
import "./quiz.scss";
import messages from "../../translations/messages";
import routes from "../route/routes";
import { NextPrev } from "../next-prev/next-prev";
import { Progress } from "../common/progress/progress";
import { Question } from "../question/question";
import { Confirmation } from "../common/confirmation/confirmation";
import { ThankYou } from "../thank-you/thank-you";
import { QuestionType } from "../../models/question-type";
import { isUUID } from "../../helper/helper";
import { AppErrors, countries } from "../../models/enums";
import { Loader } from "../common/Loader/Loader";

interface QuizComponentProps extends WrappedComponentProps {
  countryCode: string;
  languageCode: string;
  spaceQuizData: any; 
  designQuizData: any;
  getSpaceQuizQuestions(countryCode: string): void;
  getDesignQuizQuestions(countryCode: string): void;
  postSpaceQuizAnswers(quizAnswers:any): void;
  postQuizAnswers(quizID:string, quizAnswers:any): void;
  getQuizAnswersData(quizID:string): void;
  setCountry(countryCode: string, languageCode: string): void;
  quizAnswersData: any;
  quizId: string;
  errorType : string; 
  history: any;
  match: any;
  location: any;
}
export class QuizComponent extends React.Component<QuizComponentProps> {
  state = {
    quizId:'',
    quizAnswers: [] as any[],
    user: {},
    currentPageIndex: 0,
    designStarted: false,
    closeQuiz: false,
    activateSubmitButton:false,
    isActivateThankYou: false,
    showThankyou: false,
    displayContinue: true,
    userEmail: '',
    leadCreation: false,
    thankyouDetails: {},
    isAddCloseButton:true,
    proceedToDesignSelected:false
  }
  
  quizContainerDiv = React.createRef<HTMLDivElement>();
  
  public constructor(props: QuizComponentProps) {
    super(props);
    this.isPrevVisible = this.isPrevVisible.bind(this);
    this.isNextVisible = this.isNextVisible.bind(this);
  }

  componentDidMount() {  
    window.addEventListener("beforeunload", this.onUnload);
    window.history.pushState(null ,routes.home);
    window.onpopstate = (event:any) => {
      event.preventDefault();
        window.history.forward();
        this.closeQuiz();
    }
    if(this.props.match.params.quizId) {
      const quizId = this.props.match.params.quizId;
      if(isUUID(quizId)) {
        if(!this.props.quizAnswersData?.quizData){
          this.props.getQuizAnswersData(quizId);
          this.setState({quizId: quizId});
        }
      } else {
        this.props.history.push(routes.home);
      }
    } else {
      if(!this.props.spaceQuizData?.totalScreens){
        this.props.getSpaceQuizQuestions(this.props.countryCode);
      }
    }
    if(this.props.location.state?.email){
      this.setState({userEmail :this.props.location.state.email});
    }
  }

  componentDidUpdate() {
    if(!this.state.designStarted && this.state.currentPageIndex === 0 && this.state.quizAnswers.length >= 1 ){
      this.scrollToBottom();
    }
     if(this.props.quizAnswersData.result && this.props.countryCode !== this.props.quizAnswersData.result?.countryCode){
       const _country = countries.find((item) => { return item.countryCode === this.props.quizAnswersData.result?.countryCode });
       if(_country !== undefined) {
        const _lang =  _country.languages.find((language:any) => {return language.default });
        this.props.setCountry(_country.countryCode, _lang.languageCode);
        document.body.className = '';
        document.documentElement.lang = _lang.languageCode;
        document.body.classList.add("lang_" + _lang.languageCode);
       }
     }
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }
  onUnload(e: any) {
    const confirmationMessage = "Are you sure you want to close?";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }
 scrollToBottom() {
    if (this.quizContainerDiv !== null && this.quizContainerDiv.current !== null) {
      this.quizContainerDiv.current.scrollIntoView({ behavior: "smooth" });
    }
  }
  isNextVisible() {
    if (!this.state.designStarted) {
      return this.props.spaceQuizData.totalScreens > this.state.currentPageIndex;
    } else {
      return this.props.designQuizData.totalScreens > this.state.currentPageIndex;
    }
  }

  isSubmitVisible() {
    if (!this.state.designStarted) {
      return this.props.spaceQuizData.totalScreens == this.state.currentPageIndex;
    } else {
      return this.props.designQuizData.totalScreens === this.state.currentPageIndex;
    }
  }

  isContinueVisible() {
    return this.isSubmitVisible() && !this.state.isActivateThankYou && this.state.displayContinue && !this.state.designStarted;
  }
  isViewResultVisible() {
    return this.state.designStarted && this.state.currentPageIndex === this.props.designQuizData.totalScreens;
  }

  isPrevVisible() {
    if (this.state.designStarted && this.state.currentPageIndex === 0 && this.state.quizId === "") {
      return true;
    } else {
      return this.state.currentPageIndex > 0;
    }
  }
    
  movePrev = () => {
      let _currIndex = this.state.currentPageIndex - 1;
      if(this.state.designStarted && this.state.currentPageIndex == 0){
      this.setState({ designStarted: false , proceedToDesignSelected: true});
      _currIndex = this.props.spaceQuizData.totalScreens;
      }
      this.setState({ currentPageIndex: _currIndex });
      if (this.state.designStarted && _currIndex == this.props.designQuizData.totalScreens - 1) {
      this.setState({ showThankyou: false });
      }
  }

  moveNext = () => {
      const _currIndex = this.state.currentPageIndex + 1;
      this.setState({ currentPageIndex: _currIndex });
      if(this.state.designStarted && _currIndex == this.props.designQuizData.totalScreens){
        this.setState({showThankyou: true,activateSubmitButton: false});
      }
  }

  handleSubmit = () => {
    this.setState({isAddCloseButton:false,activateSubmitButton: false});
    if(this.state.isActivateThankYou) {         
      if (!this.state.designStarted) {
        // Submit space questions      
        const quizAnswers = this.getSpaceQuizAnswersPayload(this.state.quizAnswers);
        this.props.postSpaceQuizAnswers(quizAnswers);
        this.setState({showThankyou: true});
      } else {
        // Submit design/All questions
        const quizAnswers = this.getDesignQuizAnswersPayload(this.state.quizAnswers);
        this.props.postQuizAnswers(this.state.quizId, quizAnswers);
        this.setState({user: quizAnswers.user});
      }      
    } else {
      this.props.getDesignQuizQuestions(this.props.countryCode);
      this.setState({ designStarted: true, currentPageIndex: 0 });
      this.setState({isAddCloseButton:true});
    }  
  }

  getSpaceQuizAnswersPayload = (quizAnswers: any[]) => {
    const _quizAns: any[] = [];
    const spaceProfileScores: any = {};
    if (quizAnswers) {
      quizAnswers.forEach((qa: any) => {
        if (this.props.spaceQuizData && this.props.spaceQuizData.questions) {
          this.props.spaceQuizData.questions.map((sqd: any) => {
            if (qa.questionId == sqd.questionId && qa.questionType == sqd.questionType) {
              const selOption = sqd.options?.filter((opt: any) => { return opt.optionId == qa.optionId; });
              _quizAns.push({
                questionType: sqd.questionType,
                questionId: qa.questionId,
                questionText: sqd.text,
                answerText: selOption && selOption.length > 0 ? selOption[0].text : qa.optionId,
                sequence: sqd.sequence,
              });
              if (selOption && selOption[0] && selOption[0].profiles) {
                const profiles = selOption[0].profiles.split(",");
                profiles.map((profile: any) => {
                  if (!spaceProfileScores[profile]) spaceProfileScores[profile] = 0;
                  spaceProfileScores[profile] += 1;
                });
              }
            }
          });
        }
      });
    }
    let _maxSPS = 0; let _maxSPN = '';
    Object.keys(spaceProfileScores).forEach((element: any) => {
      if (spaceProfileScores[element] > _maxSPS) {
        _maxSPS = spaceProfileScores[element];
        _maxSPN = element.toString();
      }
    });
    const _result = {
      spaceProfile: _maxSPN,
      role: _quizAns.filter((qa: any) => qa.sequence === 2)[0].answerText,
      title: _quizAns.filter((qa: any) => qa.sequence === 1)[0].answerText,
      industryCode: _quizAns.filter((qa: any) => qa.sequence === 13)[0].answerText,
      officeLocation: _quizAns.filter((qa: any) => qa.sequence === 14)[0]?.answerText,
      headCount: _quizAns.filter((qa: any) => qa.sequence === 9)[0].answerText,
      daysInOffice: _quizAns.filter((qa: any) => qa.sequence === 12)[0].answerText,
      officeDensity: _quizAns.filter((qa: any) => qa.sequence === 11)[0].answerText,
      language: this.props.languageCode,
      countryCode: this.props.countryCode
    }
    if (this.props.countryCode === 'ES') {
      _result.officeDensity = 'menor densidad (más pies cuadrados por persona)' ? 2 : 1
    } else {
      if(this.props.countryCode === 'SG') {
        _result.officeLocation = 'Singapore';
      }
      _result.officeDensity = 'lower density (most SF per person)' ? 2 : 1
    }
    const _user = { email: this.state.userEmail, fname: '', lname: '' }
    return {
      user: _user,
      result: _result,
      status: 'SAVED',
      leadCreation: this.state.leadCreation,
      cbreProfessionalEmail: '',
      quizData: _quizAns
    };
  }

  getDesignQuizAnswersPayload = (quizAnswers: any[]) => {
    const _quizAns: any[] = [];
    const spaceProfileScores: any = {};
    const designProfileScores: any = {};
    // space question payload
    if (this.state.quizId === '') {
      if (quizAnswers) {
        quizAnswers.forEach((qa: any) => {
          if (this.props.spaceQuizData && this.props.spaceQuizData.questions) {
            this.props.spaceQuizData.questions.map((sqd: any) => {
              if (qa.questionId == sqd.questionId && qa.questionType == sqd.questionType) {
                const selOption = sqd.options?.filter((opt: any) => { return opt.optionId == qa.optionId });
                _quizAns.push({
                  questionType: sqd.questionType,
                  questionId: qa.questionId,
                  questionText: sqd.text,
                  answerImage: null,
                  answerText: selOption && selOption.length > 0 ? selOption[0].text : qa.optionId,
                  sequence: sqd.sequence
                });
                if (selOption && selOption[0] && selOption[0].profiles) {
                  const profiles = selOption[0].profiles.split(',');
                  profiles.map((profile: any) => {
                    if (!spaceProfileScores[profile]) spaceProfileScores[profile] = 0;
                    spaceProfileScores[profile] += 1;
                  })
                }
              }
            })
          }
        });
      }
    }
    // design question payload
    if (quizAnswers !== null) {
      quizAnswers.forEach((qa: any) => {
        if (this.props.designQuizData !== null && this.props.designQuizData.questions !== null) {
          this.props.designQuizData.questions.map((dqd: any) => {
            if (qa.questionId == dqd.questionId && qa.questionType == dqd.questionType) {
              const optIds = qa.optionId.split('|');
              let selOption = '';
              optIds.map((opt: any) => {
                const _selOption = dqd?.options?.filter((ele: any) => { return ele.optionId == opt || ele.text == opt });
                if (_selOption?.length) {
                  const _text = (dqd.layout?.toString().toLowerCase() == QuestionType.WordSelect
                    || dqd.layout?.toString().toLowerCase() == QuestionType.MultiLine) ? _selOption[0].text : _selOption[0].imgData;
                  if (selOption === '') {
                    selOption = _text;
                  } else {
                    selOption = selOption + '|' + _text;
                  }
                }
                const profiles = _selOption[0].profiles.split(',');
                profiles.map((profile: any) => {
                  if (!designProfileScores[profile]) designProfileScores[profile] = 0;
                  designProfileScores[profile] += 1;
                });
              });

              _quizAns.push({
                questionType: dqd.questionType,
                questionId: qa.questionId,
                questionText: dqd.text,
                answerText: (dqd.layout?.toString().toLowerCase() == QuestionType.WordSelect || dqd.layout?.toString().toLowerCase() == QuestionType.MultiLine) ? selOption : null,
                answerImage: dqd.layout?.toString().toLowerCase() == QuestionType.ImageSelect || dqd.layout?.toString().toLowerCase() == QuestionType.DesignPalette ? selOption : null
              });
            }
          })
        }
      });
    }
    const _user = {
      email: (this.state.thankyouDetails as any).email,
      fname: (this.state.thankyouDetails as any).firstName,
      lname: (this.state.thankyouDetails as any).lastName
    }
    let _maxSPS = 0; let _maxSPN = '';
    Object.keys(spaceProfileScores).forEach((element: any) => {
      if (spaceProfileScores[element] > _maxSPS) {
        _maxSPS = spaceProfileScores[element];
        _maxSPN = element.toString();
      }
    });
    let _maxDPS = 0; let _maxDPN = '';
    Object.keys(designProfileScores).forEach((element: any) => {
      if (designProfileScores[element] > _maxDPS) {
        _maxDPS = designProfileScores[element];
        _maxDPN = element.toString();
      }
    });
    let _result: any = {};
    if (this.state.quizId && this.state.quizId !== '' && isUUID(this.state.quizId)) {
      _result = this.props.quizAnswersData.result;
      _result.designProfile = _maxDPN;
    } else {
      _result = {
        spaceProfile: _maxSPN,
        designProfile: _maxDPN,
        role: _quizAns.filter((qa: any) =>  qa.sequence === 2)[0].answerText,
        title: _quizAns.filter((qa: any) => qa.sequence === 1)[0].answerText,
        industryCode: _quizAns.filter((qa: any) => qa.sequence === 13)[0].answerText,
        officeLocation: _quizAns.filter((qa: any) => qa.sequence === 14)[0]?.answerText,
        headCount: _quizAns.filter((qa: any) => qa.sequence === 9)[0].answerText,
        daysInOffice: _quizAns.filter((qa: any) => qa.sequence === 12)[0].answerText,
        officeDensity: _quizAns.filter((qa: any) => qa.sequence === 11)[0].answerText,
        language: this.props.languageCode,
        countryCode: this.props.countryCode
      }
      if (this.props.countryCode === 'ES') {
        _result.officeDensity = 'menor densidad (más pies cuadrados por persona)' ? 2 : 1
      } else {
        if(this.props.countryCode === 'SG') {
          _result.officeLocation = 'Singapore';
        }
        _result.officeDensity = 'lower density (most SF per person)' ? 2 : 1
      }
    }
    return {
      user: _user,
      result: _result,
      status: 'SUBMITTED',
      leadCreation: this.props.quizAnswersData.leadCreation,
      cbreProfessionalEmail: (this.state.thankyouDetails as any).cbreProfEmail,
      sendCopyToCBRE: (this.state.thankyouDetails as any).copyCBREProfSelected,
      quizData: _quizAns
    };
  }

  closeQuiz = () => {
    this.setState({ closeQuiz: true });
  }

  onPopupClose = () => {
    this.setState({ closeQuiz: false });
  }

  onQuizExit = () => {
    this.props.history.push('/');
  }

  selectOption = (event: any,questionId: any, questionType: any) => {
    const _quizAnswers = this.state.quizAnswers.filter((qa:any) => { return ( (qa.questionType == questionType && qa.questionId !== questionId ) || qa.questionType != questionType ) });
    const selectedAnswer = { questionId : questionId, questionType: questionType, optionId: (event.target ? event.target.value : event) };
      this.setState({quizAnswers: [..._quizAnswers, selectedAnswer]});
  }

  removeOption = (questionId: any, questionType: any) => {
    const _quizAnswers = this.state.quizAnswers.filter((qa:any) => { return ( (qa.questionType == questionType && qa.questionId !== questionId ) || qa.questionType != questionType ) });
    this.setState({quizAnswers: _quizAnswers});
  }

  activateViewResult = (active: boolean, thankYouData: any) =>{
    this.setState({activateSubmitButton: active});
    if(active) {
      this.setState({ thankyouDetails : thankYouData, isActivateThankYou: true});
    }
  }

  activateSubmit = (active: boolean) =>{
    this.setState({activateSubmitButton: active});
  }

  displayContinue = (active: boolean) =>{
    this.setState({displayContinue: active});
  }

  activateThankYou = (isActivate: boolean, email: string, leadCreation: boolean) =>{
    this.setState({isActivateThankYou: isActivate, userEmail: email, leadCreation: leadCreation});
  }

  getConfirmationMessage = (questions:any,layout:any) => {
    if(!this.state.designStarted && this.state.currentPageIndex === 0) {
      return this.props.intl.formatMessage(messages["quiz.welcomeMsg"]);
    } else if(layout==QuestionType.WordSelect){
      return questions[0]?.options[0]?.response;
    } else if(questions) {
      const ques = questions[0];
      const answerSelected = this.state.quizAnswers.find((x:any) => { return x.questionId === ques?.questionId && x.questionType === ques.questionType })
      const opt = ques?.options?.find((option:any) => option.optionId == answerSelected?.optionId || option.text == answerSelected?.optionId );
      return opt?.response || '';
    }
    return '';
  }

  public render() {    
    if(this.state.quizId !== '' && isUUID(this.state.quizId)) {
      if(this.props.quizAnswersData?.status && this.props.quizAnswersData?.status == 'SAVED') {
        if(!this.state.designStarted && this.props.quizAnswersData.result) {
          this.props.getDesignQuizQuestions(this.props.quizAnswersData.result.countryCode);
          this.setState({designStarted: true, userEmail: this.props.quizAnswersData?.user?.email });
          }
      } else if ( this.props.quizAnswersData?.status && this.props.quizAnswersData?.status == 'SUBMITTED') {
        this.props.history.push(routes.result + '/'+ this.state.quizId);
      }
    }
    if(this.props.quizId !== '' && isUUID(this.props.quizId)) {
      this.props.history.push(routes.result + '/'+ this.props.quizId);
    }
    const _quest = !this.state.designStarted  
                      ? this.props.spaceQuizData.questions?.filter((x:any) => { return x.pageNumber === this.state.currentPageIndex + 1 })
                      : this.props.designQuizData.questions?.filter((x:any) => { return x.pageNumber === this.state.currentPageIndex + 1 })
    const questions = _quest?.sort((a: any, b:any) => {return a.sequence-b.sequence});
    const isAllQuesAnswered = questions?.every((que: any) => { return this.state.quizAnswers.some((x:any) => { return x.questionId === que.questionId && x.questionType == que.questionType }) });
    
    const layout= questions && questions.length && questions[0]?.layout?.toString().toLowerCase();
    
    let confirmationMessage = this.getConfirmationMessage(questions,layout);

    if (this.props.errorType !== '' && (this.props.errorType === AppErrors.GET_SPCAE_QUIZ_API_ERROR
      || this.props.errorType === AppErrors.SAVE_SPACE_QUIZ_API_ERROR || this.props.errorType === AppErrors.GET_DESIGN_QUIZ_API_ERROR || this.props.errorType === AppErrors.SAVE_QUIZ_ANSWERS_API_ERROR)) {
      this.props.history.push(routes.officeReadyError, { errorCode: 500 });
    }
    return (
      <>
        <div className="quiz-header">
          <div className="cbre_home_logo header-logo" onClick={this.state.isAddCloseButton ? this.closeQuiz : this.onQuizExit} >          
          <strong>{this.props.intl.formatMessage(messages["global.CBRE"])}</strong> {this.props.intl.formatMessage(messages["global.officeReady"])}
           </div> 
           { this.state.isAddCloseButton && <button className="button close-btn" onClick={this.closeQuiz}>&times;</button>}
           <div>            
            { !this.state.showThankyou 
              && <div className="progress-container">
                  <Progress totalCount={this.state.designStarted ? this.props.designQuizData.totalScreens : this.props.spaceQuizData.totalScreens}
                    currentIndex={this.state.currentPageIndex} isDesignStarted={this.state.designStarted} />
                </div>
            }
          </div>         
        </div>
        <div className="quiz-container">
          {this.state.currentPageIndex == 0 && questions?.length > 0 &&
            <div className="quiz-heading">
              { !this.state.designStarted 
                ? this.props.intl.formatMessage(messages["quiz.spaceHeading"]) 
                : this.props.intl.formatMessage(messages["quiz.designHeading"])
              }
            </div>
          }
          {!this.state.showThankyou 
           ? ( !this.state.designStarted && this.state.currentPageIndex == this.props.spaceQuizData.totalScreens 
              ? <Confirmation
                  activateSubmit={this.activateSubmit} 
                  activateThankYou={this.activateThankYou}
                  displayContinue= {this.displayContinue}
                  userEmail= {this.state.userEmail}
                  optionFirstSelected={this.state.proceedToDesignSelected}
                  countryCode={this.props.countryCode}
                  />
              :
              ( questions?.length > 0 
                ?  questions?.length > 0 && questions?.map((que:any)=> { 
                  const answerSelected = this.state.quizAnswers.find((x:any) => { return x.questionId === que.questionId && x.questionType == que.questionType });
                  return <Question 
                            question={que} 
                            answerSelected={answerSelected} 
                            selectOption={this.selectOption}
                            removeOption={this.removeOption}
                            key={que.questionId}  />
                  })
                : <Loader />
              )
            )
           : <ThankYou countryCode={this.props.countryCode} userEmail={this.state.userEmail} designStarted={this.state.designStarted} activateViewResult={this.activateViewResult} />
          }
          <div ref={this.quizContainerDiv}></div>      
        
          { isAllQuesAnswered && (layout==QuestionType.MultiLine || layout==QuestionType.WordSelect) && confirmationMessage !== '' &&
            <div className="quiz-welcome-msg">
              { confirmationMessage.indexOf('!') > -1 ?
                <>
                  <div className="div-firstline"><i className="icon icon-success"></i>{confirmationMessage.split('!')[0] + '!'} </div>
                  <div className="div-secondline">{confirmationMessage.split('!')[1]}</div>
                </>
                :
                <div className="div-firstline"><i className="icon icon-success"></i>{confirmationMessage} </div>
              }
            </div>
          }
        </div>
        { !this.state.designStarted && confirmationMessage !== '' && this.state.currentPageIndex === 0 ? this.scrollToBottom() : null}
        { (!this.state.showThankyou || (this.state.showThankyou && this.state.designStarted) ) &&
          <NextPrev data-test='next-prev'isNextVisible={this.isNextVisible()} isPrevVisible={this.isPrevVisible()} isNextEnabled={isAllQuesAnswered}
            isSubmitVisible={this.isSubmitVisible()} isSubmitEnabled={this.state.activateSubmitButton} moveNextStep={this.moveNext} 
            movePrevStep={this.movePrev} handleSubmit={this.handleSubmit} isContinueVisible={this.isContinueVisible()} 
            isViewResultVisible={this.isViewResultVisible()}/>
        }
        {this.state.closeQuiz && <Modal onPopupClose={this.onPopupClose} onQuizExit={this.onQuizExit} />}
      </>
    );
  }
}