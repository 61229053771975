import { CATEGORY, CIRCULATION_TYPE } from "../models/enums";
import { programCalculator } from "./program-calculator";

export class spaceCalculator {

    _programCalculator;
    officeDensity : number;
    constructor(officeDensity:number) {

        this.officeDensity = officeDensity;
        this._programCalculator = new programCalculator();
    }
    

    // "Planning Population" is the portion of the headcount we expect to be in the office on a given day 95% of the time.
    calculatePlanningPopulation(daysPerWeekInOffice: number) {
        const showupRate = daysPerWeekInOffice / 5;
        if (showupRate >= 1) return 1;
        // *************************************************
        const standardDeviation = 0.08; // TODO -- Marked in spread sheet as an input, where does this come from
        const variance = standardDeviation ** 2;

        const alpha = (((1 - showupRate) / variance) - (1 / showupRate)) * (showupRate ** 2);
        const beta = alpha * ((1 / showupRate) - 1);

        return (window as any).jStat?.beta.inv(0.95, alpha, beta);
    }

    // "Sharing Population" is the portion of the headcount not assigned an individual seat
    calculateSharingPopulation(daysPerWeekInOffice: number) {
        let threshold = 3.5;
        return daysPerWeekInOffice > threshold ? 0 : 1;
    }

    // "Workshare" is the number of work seats ("me seats") per member of the planning population.
    // Currently always 1, but could be more complex in the future.
    calculateWorkshare() {
        return 1;
    }

    // ************************************* Seat Count Calculations *************************************

    // Workseats are used to determine me space quantities
    // Non-sharing headcount gets one seat + 5% vacancy for moves, adds, and changes (for regular_US/UK)
    calculateWorkSeats(headcount: number, expectedDailyHeadcount: number, sharingPopulation: number, workshare: number) {
        const nonSharingMultiplier = 1.05;
        const nonSharingHeadcountSeats = (1 - sharingPopulation) * headcount * nonSharingMultiplier;
        const sharingHeadcountSeats = workshare * sharingPopulation * expectedDailyHeadcount;
    
        return Math.round(nonSharingHeadcountSeats + sharingHeadcountSeats);
    }

    calculateGroupSeats(expectedDailyHeadcount: number, groupshare: number) {
        return Math.round(expectedDailyHeadcount * groupshare);
    }

    // *************************************


    calculateMeProgramPart(spaceUnit: any, workSeats: number) {
        const programPart = this._programCalculator.calculateMeProgramPart(
          spaceUnit.multiplier,
          spaceUnit.sf,
          spaceUnit.circulationType,
          this.officeDensity,
          workSeats
        );
        spaceUnit.quantity = programPart.quantity;
        spaceUnit.nsf = programPart.nsf;
        spaceUnit.usf = programPart.usf;
        spaceUnit.unitLabel = programPart.quantity > 1 ? 'seats' : 'seat';
    }
      
     calculateWeProgramPart(spaceUnit: any, groupSeats: number) {
        const programPart = this._programCalculator.calculateWeProgramPart(
            spaceUnit.multiplier,
            spaceUnit.seats,
            spaceUnit.sf,
            spaceUnit.circulationType,            
            this.officeDensity,
            groupSeats
        );
        spaceUnit.quantity = programPart.quantity;
        spaceUnit.nsf = programPart.nsf;
        spaceUnit.usf = programPart.usf;
        spaceUnit.unitLabel = programPart.quantity > 1 ? 'rooms' : 'room';
    }
      
    calculateAmenityProgramPart(spaceUnit: any, headcount: number) {
        const programPart = this._programCalculator.calculateAmenityProgramPart(
          spaceUnit.multiplier,
          spaceUnit.sf,
          spaceUnit.circulationType,
          spaceUnit.minSF,
          spaceUnit.maxSF,
          this.officeDensity,
          headcount
        );
        spaceUnit.quantity = programPart.quantity;
        spaceUnit.nsf = programPart.nsf;
        spaceUnit.usf = programPart.usf;
        spaceUnit.sf = programPart.nsf;
        spaceUnit.unitLabel = 'area';
    }

    calculateSupportProgramPart(spaceUnit: any, headcount: number) {
        const programPart = this._programCalculator.calculateSupportProgramPart(
            spaceUnit.multiplier,
            spaceUnit.sf,
            spaceUnit.circulationType,
            false,
            0,
            this.officeDensity,
            headcount
        );
        spaceUnit.quantity = programPart.quantity;
        spaceUnit.nsf = programPart.nsf;
        spaceUnit.usf = programPart.usf;
      }

    // ************************************* Program Space Totals Calculations *************************************
    updateProgramWithSFTotals(programResult: any) {
        return this._programCalculator.calculateCategoryUSFTotals( programResult.ME, programResult.WE, programResult.AMENITY);
    }
    // *************************************

    // ************************************* Program Final Stats Calculations *************************************

    updateProgramWithFinalStats(programResult: any, headcount: number) {       

        // Calculate actualWorkSeatTotal by summing total OPEN and ENCLOSED quantities
        // actualWorkSeatTotal might differ from program.metrics.workSeats (the original, recommended number of work seats)
        // this discepency might be due to
        //   - rounding errors
        //   - user customization
        let openTotal = 0;
        let enclosedTotal = 0;
        if (programResult.ME != null) {
            programResult.ME.forEach((space: any) => {
                if (space.circulationType === CIRCULATION_TYPE.OPEN) {
                    openTotal += (space.customQuantity || space.quantity);
                } else if (space.circulationType === CIRCULATION_TYPE.ENCLOSED) {
                    enclosedTotal += (space.customQuantity || space.quantity);
                }
            });
        }
        const actualWorkSeatTotal = openTotal + enclosedTotal;
    
        // Count validCollabSeats (total seats in enclosed WE spaces with max 18 seats)
        let validCollabSeats = 0;
        if (programResult.WE != null) {

            programResult.WE.forEach((space: any) => {
                if (space.circulationType === CIRCULATION_TYPE.ENCLOSED && space.seats <= 18) {
                    validCollabSeats += (space.customQuantity || space.quantity) * space.seats;
                }
            });
        }

        const openPer = Math.round( (openTotal / actualWorkSeatTotal) * 100);
        const collabSeatRatio = validCollabSeats / actualWorkSeatTotal;
        const sharingRatio = actualWorkSeatTotal / headcount;
    
        return {
            collabSeatRatio: isNaN(collabSeatRatio) ? 0 : collabSeatRatio,
            openPercentage: isNaN(openPer) ? 0 : openPer,
            closedPercentage: isNaN(openPer) ? 100 : 100 - openPer,
            sharingRatio: isNaN(sharingRatio) ? 0 : sharingRatio,
            usfPerSeat: actualWorkSeatTotal > 0 ?  (programResult.areaTotals.totalSF/actualWorkSeatTotal ) : (programResult.areaTotals.totalSF/ headcount )
        };
    }
    // *************************************    


    // ************************************* Final Program Calculation *************************************

    calculateProgram(kitOfParts: any, headcount: number, daysPerWeekInOffice: number, officeDensity: number, officeLocation: any ) {
    
        // Define program structure
        const programResult = {
            "ME" : new Array(),
            "WE" : new Array(),
            "SUPPORT" : new Array(),
            "AMENITY" : new Array(),
            finalStats: {},
            areaTotals: {},
            metrics: {}
        };
    
    
        // Get profile-related information
        const me = kitOfParts?.partsData.filter((kop: any)=> {
            return kop.category == CATEGORY.ME
        })[0].categoryData;
        const we = kitOfParts?.partsData.filter((kop: any)=> {
            return kop.category == CATEGORY.WE
        })[0].categoryData;
        const amenity = kitOfParts?.partsData.filter((kop: any)=> {
            return kop.category == CATEGORY.AMENITY
        })[0].categoryData;
        const support = kitOfParts?.partsData.filter((kop: any)=> {
            return kop.category == CATEGORY.SUPPORT
        })[0].categoryData;
    
        // Calculate metrics
        const planningPopulation = this.calculatePlanningPopulation(daysPerWeekInOffice);
        const sharingPopulation = this.calculateSharingPopulation(daysPerWeekInOffice);
        const expectedDailyHeadcount = headcount * planningPopulation;
    
        const workshare = this.calculateWorkshare();
        const groupshare = kitOfParts.collaborationRatio; // "we seats" per member of planning population
    
        const workSeats = this.calculateWorkSeats(headcount, expectedDailyHeadcount, sharingPopulation, workshare);
        const groupSeats = this.calculateGroupSeats(expectedDailyHeadcount, groupshare);
    
        programResult.metrics = { planningPopulation, sharingPopulation, workshare, groupshare, workSeats, groupSeats };
    
        // Iterate through each cateogry in KOPWM to calculate and add program part        
        me.forEach((spaceUnit:any) => {
            this.calculateMeProgramPart(spaceUnit,workSeats);
            programResult.ME.push(spaceUnit);
        });
    
        we.forEach((spaceUnit:any) => {
            this.calculateWeProgramPart(spaceUnit, groupSeats);
            programResult.WE.push(spaceUnit);
        });
    
        // this.calculateAndSetBlendedCirculationPercentage(program, null, officeDensity);
    
        amenity.forEach((spaceUnit:any) => {
            this.calculateAmenityProgramPart(spaceUnit, headcount);
            programResult.AMENITY.push(spaceUnit);
        });
    
        support.forEach((spaceUnit:any) => {
            this.calculateSupportProgramPart(spaceUnit, headcount);
            programResult.SUPPORT.push(spaceUnit);
        });    
        
        programResult.areaTotals = this.updateProgramWithSFTotals(programResult);
    
        programResult.finalStats = this.updateProgramWithFinalStats(programResult, headcount);
    
        return programResult;
  }
  // *************************************
}