import { AppErrors } from "../models/enums";
import { ActionType } from "./action-type";

const initialState = {
  languageCode: localStorage.getItem("languageCode") || "en-US" ,
  countryCode: localStorage.getItem("countryCode") || 'US',
  spaceQuizData: {},
  industrialData: [],
  locationData: [],
  costEstimateData:[],
  designQuizData: {},
  quizResultData: {},
  quizAnswersData: {},
  profileData: {},
  quizId:'',
  loading: false,
  error: null,
  errorType: '',
  clientInfo: {}
};

const quizReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GET_SPACE_QUIZ_QUESTIONS:
      return { ...state, loading: true, error: null };

    case ActionType.GET_SPACE_QUIZ_QUESTIONS_SUCCESS:
      return { ...state, loading: false, spaceQuizData: action.payload.spaceQuizData, errorType: '' };

    case ActionType.GET_SPACE_QUIZ_QUESTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload.error, spaceQuizData: {}, errorType: AppErrors.GET_SPCAE_QUIZ_API_ERROR };
      
    case ActionType.GET_INDUSTRIAL_QUESTIONS:
      return { ...state, loading: true, error: null };

    case ActionType.GET_INDUSTRIAL_QUESTIONS_SUCCESS:
      return { ...state, loading: false, industrialData: action.payload.industrialData };

    case ActionType.GET_INDUSTRIAL_QUESTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload.error, industrialData: [] };

    case ActionType.GET_LOCATION_QUESTIONS:
      return { ...state, loading: true, error: null };
  
    case ActionType.GET_LOCATION_QUESTIONS_SUCCESS:
      return { ...state, loading: false, locationData: action.payload.locationData };
  
    case ActionType.GET_LOCATION_QUESTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload.error, locationData: [] };

    case ActionType.GET_DESIGN_QUIZ_QUESTIONS:
      return { ...state, loading: true, error: null };
  
    case ActionType.GET_DESIGN_QUIZ_QUESTIONS_SUCCESS:
      return { ...state, loading: false, designQuizData: action.payload.designQuizData , errorType: ''};
  
    case ActionType.GET_DESIGN_QUIZ_QUESTIONS_FAILURE:  
      return { ...state, loading: false, error: action.payload.error, designQuizData: {}, errorType: AppErrors.GET_DESIGN_QUIZ_API_ERROR };

    case ActionType.SAVE_SPACE_QUIZ_ANSWERS_SUCCESS:
      return { ...state };
    
    case ActionType.SAVE_SPACE_QUIZ_ANSWERS_FAILURE:  
      return { ...state , error: action.payload.error, errorType: AppErrors.SAVE_SPACE_QUIZ_API_ERROR};

    case ActionType.SAVE_QUIZ_ANSWERS_SUCCESS:
      return { ...state, quizId : action.payload.quizId, errorType: ''};

    case ActionType.SAVE_QUIZ_ANSWERS_FAILURE:
      return { ...state , quizId : '', errorType : AppErrors.SAVE_QUIZ_ANSWERS_API_ERROR };

    case ActionType.GET_QUIZ_ANSWERS_SUCCESS:
      return { ...state, quizAnswersData: action.payload.quizAnswersData };

    case ActionType.GET_QUIZ_RESULT_DATA_SUCCESS:
      return { ...state, quizResultData: action.payload.quizResultData, errorType: '' };

    case ActionType.GET_QUIZ_RESULT_DATA_FAILURE:
      return { ...state, quizResultData: {}, errorType : AppErrors.GET_QUIZ_RESULT_API_ERROR };
    
    case ActionType.GET_PROFILE_DATA_SUCCESS:
      return { ...state, profileData: action.payload.profileData };
    
    case ActionType.QUIZ_START:
      return { ...state, quizId : '', quizResultData: {}, profileData: {}, quizAnswersData: {} };

    case ActionType.SAVE_CONTACT_DESIGNER_DATA_SUCCESS:
      return { ...state };
      
    case ActionType.SAVE_CONTACT_DESIGNNER_DATA_FAILURE:  
      return { ...state };

    case ActionType.SAVE_QUIZ_RESULT_DATA_SUCCESS:  
      return { ...state };

     case ActionType.GET_COST_ESTIMATE:
      return { ...state, loading: true, error: null };

    case ActionType.GET_COST_ESTIMATE_SUCCESS:
      return { ...state, loading: false, costEstimateData: action.payload.costEstimateData };

    case ActionType.GET_COST_ESTIMATE_FAILURE:
      return { ...state, loading: false, error: action.payload.error, costEstimateData: [] };

    case ActionType.SET_COUNTRY:
      return { ...state, countryCode : action.payload.countryCode, languageCode : action.payload.languageCode };
    
    case ActionType.SET_CLIENTINFO:
      return { ...state, clientInfo : action.payload.clientInfo};
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
};

export default quizReducer;