import React from "react";
import { injectIntl } from "react-intl";
import "./question.scss";
import { QuestionType } from "../../models/question-type";
import { ImageSelect } from "../image-select/image-select";
import { Headcount } from "../common/headcount/headcount";
import { QuestionComponentProps } from "../../models/question-component-props";
import { Multiline } from "../common/multiline/multiline";
import { Singleline } from "../common/singleline/singleline";
import { DropdownQuestion } from "../common/dropdown-question/dropdown-question";
import { Autosuggest } from "../common/autosuggest/autosuggest";
import { LocationDropdown } from "../common/locationdropdown/locationdropdown";
import { WordSelect } from "../word-select/word-select";
import { DesignPalette } from "../common/design-palette/design-palette";


class QuestionComponent extends React.Component<QuestionComponentProps> {
  
  public constructor(props: QuestionComponentProps) {
    super(props);
  }

  public render() {
    switch (this.props.question?.layout?.toString().toLowerCase()) {
        case QuestionType.SingleLine:
            return <Singleline {...this.props} />
        case QuestionType.MultiLine:
            return <Multiline {...this.props} />
        case QuestionType.Dropdown:
            return <DropdownQuestion {...this.props} />
        case QuestionType.Headcount:
            return <Headcount {...this.props} />
        case QuestionType.ImageSelect:
            return <ImageSelect {...this.props} />
        case QuestionType.WordSelect:
            return <WordSelect {...this.props} />
        case QuestionType.Autosuggest:
            return <Autosuggest {...this.props} />
        case QuestionType.LocationDropdown:
            return <LocationDropdown {...this.props} />
        case QuestionType.DesignPalette:
            return <DesignPalette {...this.props} />
        default:
            return <></>
    }
  }
}

export const Question = injectIntl(QuestionComponent)
